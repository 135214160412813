import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import "./emailTemplate.scss";

import { ReactComponent as GreenTickIcon } from "../../../../assets/img/icons/tick_circle_green_icon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/img/close-btn.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/img/icons/warning_icon.svg";

export default class AddViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModalAccept: false,
      openModalDecline: false,
      item: null,
      referenceId: 0,
      applicationId: 0,
      applicationNo: '',
      studentId: 0
    }
  }

  setOpenModalAccept = (state) => {
    this.setState({
      openModalAccept: state
    })
  }

  setOpenModalDecline = (state) => {
    this.setState({
      openModalDecline: state
    })
  }

  componentDidMount() {
    this.setState({
      item: this.props.data,
      referenceId: this.props.data.request_reference_id
    }, () => {
      if (this.state.item) {
        let updates = {};
        if (this.state.item.add_viewer) {
          this.setState({
            applicationId: this.state.item.add_viewer.application_id,
            applicationNo: this.state.item.add_viewer.application.app_no,
            studentId: this.state.item.add_viewer.student_id
          })
        }
      }
    })
  }

  updateRequest = async (status) => {
    try {
      let response = await this.props.updateViewerRequest(
        this.state.referenceId, 
        status
      );
      if (status == 3) {
        this.setOpenModalAccept(true)
      } else {
        this.setOpenModalDecline(true)
      }
      console.log('satus item', response);
    } catch (err) {

    }
  }

  render() {
    let {
      item,
      referenceId,
      applicationNo
    } = this.state;

    return (
      <>
        <div className="emailContent">
          <div className="header bigFont">
            <p>
              <strong>
                {
                  item &&
                  item.email_from_details ? (
                    item.email_from_details.personal_details ? (
                      item.email_from_details.personal_details.first_name +
                      " " + 
                      item.email_from_details.personal_details.last_name +
                      " "
                    ) : null
                  ) : null
                }
              </strong> 
              {" "}has invited you
            </p>
          </div>
          <div className="contentBox mt-30">
            <p className="bigFont">
              <strong>
                {
                  item &&
                  item.email_from_details ? (
                    item.email_from_details.personal_details ? (
                      item.email_from_details.personal_details.first_name +
                      " " + 
                      item.email_from_details.personal_details.last_name +
                      " "
                    ) : null
                  ) : null
                }
              </strong> 
              has requested  to add you as a viewer  on
              <br />their application with ID {applicationNo}
            </p>
            <Button color="green" outline
              onClick={() => { 
                this.updateRequest(5)
              }}
            >
              Decline
            </Button>
            <Button color="green"
              onClick={() => {
                this.updateRequest(3)
              }}
            >
              Accept
            </Button>

            <p className="smallFonts">Unexpected email? To contact Formee Express team, send email to <strong><a href="mailto:admin@formeeexpress.com">admin@formeeexpress.com</a></strong></p>
          </div>
          <div className="footer">
            <p><strong>Need Support?</strong></p>
            <p>Feel free to email us if you have any questions, comments or suggestions.<br />
              We’ll be happy to resolve your issues.</p>
          </div>
        </div>

        {/* Accept */}
        <Modal className="primaryModal noRadius viewAdded" isOpen={this.state.openModalAccept}>
          <ModalBody>
            <CloseIcon className="closeIcon" onClick={() => { this.setOpenModalAccept(false) }} />
            <GreenTickIcon className="greenTickIcon" />
            <h2 className="title">Viewer Added</h2>
            <p>You can view <strong>Alex Smith</strong> application.</p>
            
            {
              this.state.applicationId ? (
                <p>
                  <Link to={`/view-student-application/${this.state.applicationId}`}>
                    Click here
                  </Link> 
                  {" "}to give the feedback now.
                </p>
              ) : null
            }

            {
              this.state.studentId ? (
                <Link 
                  to={`/profile-overview/${this.state.studentId}`} 
                  className="btn btn-green mt-40"
                  onClick={() => {
                    this.setOpenModalAccept(false)
                  }}
                >
                  Go to CLIENT DETAILS
                </Link>
              ) : null
            }
            
          </ModalBody>
        </Modal>

        {/* Decline */}
        <Modal className="primaryModal noRadius viewAdded" isOpen={this.state.openModalDecline}>
          <ModalBody>
            <CloseIcon className="closeIcon" onClick={() => { this.setOpenModalDecline(false) }} />
            <WarningIcon className="WarningIcon" />
            <h2 className="title">Are you sure you want to decline the hire request?</h2>
            <p>This request can not be accepted later. If you change your mind, you will need a new hire request.</p>
            <div className="mt-40">
              <Button onClick={() => { this.setOpenModalDecline(false) }} color="green w-30" outline>No</Button>
              <Button onClick={() => { this.setOpenModalDecline(false) }} color="green w-30">Yes</Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}