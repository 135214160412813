import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_NOTIFICATION_COUNT_API, GET_NOTIFICATION_LIST_API, MARK_READ_API } from "../../Api";
import { deleteError, deleteSuccess, getNotificationListError, getNotificationListSuccess, getNotificationsCountError, getNotificationsCountSuccess, handleErrorResponse, handleSuccessResponse, markReadError, markReadSuccess, showHideLoadingAction } from "../actions";
import { DELETE_NOTIFICATION, GET_NOTIFICATIONS_COUNT, GET_NOTIFICATION_LIST, MARK_READ } from "../constants";
import axios from "../../Api/axios";

function getNotificationListApi(params) {
  return axios.request({
    method: "get",
    url: GET_NOTIFICATION_LIST_API,
    params: params
  });
}

function* getNotificationList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(getNotificationListApi, payload);
    handleSuccessResponse(response.data)
    yield put(getNotificationListSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(getNotificationListError(e))
    if (reject) reject(e)
  }
}

export function* getNotificationListWatcher() {
  yield takeLatest(GET_NOTIFICATION_LIST, getNotificationList)
}

function getNotificationsCountApi(params) {
  return axios.request({
    method: "get",
    url: GET_NOTIFICATION_COUNT_API,
    params: params
  });
}

function* getNotificationsCount(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(getNotificationsCountApi, payload);
    handleSuccessResponse(response.data)
    yield put(getNotificationsCountSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(getNotificationsCountError(e))
    if (reject) reject(e)
  }
}

export function* getNotificationsCountWatcher() {
  yield takeLatest(GET_NOTIFICATIONS_COUNT, getNotificationsCount)
}

function markReadApi(params) {
  return axios.request({
    method: "post",
    url: MARK_READ_API,
    data: params
  });
}

function* markRead(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(markReadApi, payload);
    handleSuccessResponse(response.data)
    yield put(markReadSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(markReadError(e))
    if (reject) reject(e)
  }
}

export function* markReadWatcher() {
  yield takeLatest(MARK_READ, markRead)
}

function deleteNotificationApi(params) {
  return axios.request({
    method: "post",
    url: MARK_READ_API,
    data: params
  });
}

function* deleteNotification(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(deleteNotificationApi, payload);
    handleSuccessResponse(response.data)
    yield put(deleteSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(deleteError(e))
    if (reject) reject(e)
  }
}

export function* deleteNotificationWatcher() {
  yield takeLatest(DELETE_NOTIFICATION, deleteNotification)
}