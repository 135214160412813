export function  checkValidFileSize(file) {
  let fileSize = file.size / 1048576;
  
  return fileSize > 14;
}

export function checkValidImage(file) {
  let matched = file.type.match('image/jpeg') 
    || file.type.match('image/png');

  return matched;
}