import React, { useRef } from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";

// ================= TAGFIELD ==================

function TagField({
	initialValue = [],
	suggestions = [],
	placeholder,
	maxTags,
	onChange,
	editTags,
	ref,
	validate,
	name,
	value = []
}) {
	// Tagify settings object
	const baseTagifySettings = {
		blacklist: [],
		maxTags: maxTags,
		backspace: "edit",
		placeholder: placeholder,
		editTags: 0,
		dropdown: {
			enabled: 0,
		},
		callbacks: {},
	};

	const handleChange = (e) => {
		console.log(
			e.type,
			" ==> ",
			e.detail.tagify.value.map((item) => item.value)
		);
	};

	const settings = {
		...baseTagifySettings,
		whitelist: suggestions,
		callbacks: {
			add: onChange,
			remove: onChange,
			blur: onChange,
			edit: onChange,
			invalid: onChange,
			click: onChange,
			focus: onChange,
			"edit:updated": onChange,
			"edit:start": onChange,
			beforeRemove: onChange
		},
		editTags: editTags ? true : false
	};

	if (validate) {
		settings.pattern = validate;
	}

	return <Tags tagifyRef={ref} settings={settings} initialValue={initialValue} value={value} />;
}
export default TagField;
