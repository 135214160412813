import config from "../config.json";

export const baseUrl = `${config.baseurl}/`;

export const LOGIN_API = "agent/agent-login";

export const DASHBOARD_DATA = "universitydashboard";
export const COUNTRY_LIST = "get_country";
export const CURRENCY_LIST = "get_currency";
export const PROGRAM_COUNTRY_LIST = "program-country";
export const SIDE_PROFILE = "agent/profile/about";
export const UNIVERSITY_ABOUT = "agent/profile/about";
export const LOGIN_LOGS = "agent/login-logs";
export const AGREEMENT_DOCUMENTS = "agent/agreement-documents";
export const CREATE_GROUP_API = "agent/group/create";
export const GET_GROUP_LIST_API = "agent/group/list";
export const GET_ROLE_LIST_API = "agent/role/list";
export const SEND_STAFF_INVITE_API = "agent/staff/invite";
export const UPDATE_STAFF_API = "agent/staff/edit";
export const SEND_STAFF_RESEND_INVITE_API = "agent/staff/resend-invitation";
export const GET_GROUP_ROLES_LIST_API = "agent/group/rolelist";
export const ADD_ROLE_TO_GROUP_API = "agent/role/add";
export const GET_ROLE_PERMISSIONS_API = "agent/role/get-permission";
export const SET_ROLE_PERMISSIONS_API = "agent/role/set-permission";
export const GET_STAFF_LIST_API = "agent/staff/list";
export const DELETE_GROUP_API = "agent/group/delete";
export const GET_PROFILE_API = "agent/profile";
export const UPDATE_PROFILE_API = "agent/profile";
export const DELETE_ROLE_API = "agent/role/delete";
export const STAFF_INVITE_LIST_API = "agent/staff/invitelist";
export const DELETE_SATFF_API = "agent/staff/delete";
export const DELETE_STAFF_INVITE_API = "agent/staff/delete-invite";
export const SAVE_INTAKE_DATES_API = "university/intake-dates/save";
export const GET_INTAKE_DATES_API = "university/intake-dates";
export const SAVE_APPLICATION_FORM_API = "university/application-form/save";
export const GET_APPLICATION_FORM_API = "university/application-form/get-questions";

export const ACCEPT_MEETING_REQUEST_API = "agent/meeting/update-meeting-request";
export const AGENT_PUBLISH_REQUEST_API = "agent/published-request";
export const GET_STATES_LIST_API = "getstatebymultiplecountry";

export const GET_PROFILE_STATUS_API = "agent/profile/status";

export const GET_APPLICATION_LIST_API = "agent/application/list";

export const GET_DEPARTMENT_LIST_API = "department/list";

export const GET_PROGRAM_LIST_API = "program/list";

export const GET_STUDY_LEVEL_LIST_API = "study-level/list";

export const DELETE_APPLICATIONS_API = "application/delete";

export const GET_APPLICATION_STATUS_LIST_API = "application/status/list";

export const GET_APPLICATION_DETAILS_API = "agent/application";

export const GET_DEPARTMENTS_API = "program/get-department-list";
export const SAVE_DEPARTMENT_API = "program/department/add-department";
export const GET_CAMPUS_LIST_API = "university/profile/campus_list";
export const VIEW_DEPARTMENT_API = "program/department/get-department";
export const UPDATE_DEPARTMENT_API = "program/department/edit-department";
export const UPDATE_APPLICATION_STATUS_API = "application/status/update";

export const GET_APPLICATION_MESSAGE_CHAT_COUNT_API =
  "application/comment-message/count";

export const GET_APPLICATION_STATUS_API = "agent/application/status";

export const ASSIGN_APPLICATION_API = "application/status/update/assign";

export const GET_APPLICATION_ASSIGNEE_LIST_API =
  "application/status/assign/view";

export const SEND_APPLICATION_MESSAGE_API = "application/message/add";

export const GET_APPLICATION_MESSAGES_API = "agent/application/message/list";

export const GET_APPLICATION_COMMENTS_API = "application/comment/list";

export const ADD_APPLICATION_COMMENT_API = "application/comment/add";

export const DELETE_APPLICATION_COMMENT_API = "application/comment/delete";

export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION_API =
  "application/document/status/update";

export const GET_EDUCATION_LIST_API = "program/get_education_list";
export const GET_REMAIN_CAMPUS_LIST_API = "program/campus-by-program";
export const CREATE_PROGRAM_API = "program/add-program";
export const GET_EXAM_LIST_API = "program/exam-list";
export const GET_SEARCH_PROGRAM_LIST_API = "program/search";
export const DELETE_PROGRAM_API = "program/delete-program";
export const GET_PROGRAMS_API = "program/get-program-list";
export const GET_PROGRAM_DETAILS_API = "program/get-program-details";
export const UPDATE_PROGRAM_API = "program/edit-program";
export const CONFIRM_DEPARTMENT_API = "program/department/delete-department";
export const CONFIRM_PROGRAM_API = "program/department/confirm-department";
export const DELETE_ALL_PROGRAM_DEPARTMENT_API =
  "program/department/delete-all-program";
export const GET_UNIVERSITY_OVERVIEW_API = "program/department/review-counter";

export const COMPOSE_MAIL_API = "agent/inbox/mail/compose";

export const INBOX_LIST_API = "agent/inbox/mail/list";

export const UPDATE_MAIL_FAVOURITE_API = "agent/inbox/mail/star/change";

export const DELETE_INBOX_EMAIL_API = "agent/inbox/mail/delete/change";

export const GET_UNREAD_INBOX_COUNT_API = "agent/inbox/mail/unread";

export const GET_INBOX_MAIL_DETAILS_API = "agent/inbox/mail/view";

export const UPDATE_INBOX_READ_STATUS_API = "agent/inbox/mail/mark/unread";

export const DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS =
  "agent/inbox/mail/attachment/download";

export const GET_TIMEZONES_LIST_API = "timezone/list";
export const CREATE_MEETING_API = "agent/meeting/create";

export const UPDATE_APPLICATION_FAVOURITE_API = "agent/application/star-unstar";
export const UPDATE_CLIENT_FAVOURITE_API = "agent/client/star-unstar";

export const UPDATE_PASSWORD_API = "agentupdatepassword";

export const FORGOT_PASSWORD_API = "checkemail";
export const RESET_PASSWORD_API = "resetpassword";

export const CALENDER_EVENT_LIST_API = "agent/calendar/get-calendar-events";
export const GET_MONTH_EVENT_LIST_API =
  "agent/calendar/get-event-by-month-year";

export const GET_OFFICE_LIST_API = "agent/office";

export const MEETING_LIST_API = "agent/meeting/list";
export const MEETING_DETAILS_API = "agent/meeting/view";
export const UPDATE_MEETING_STATUS_API = "agent/meeting/status/update";
export const DELETE_MEETING_API = "agent/meeting/cancel";
export const GET_DASHBOARD_AGENT_API = "agent/dashboard/get-agent-detail";

export const XERO_PDF_API = "xero/invoice/pdf";
export const GET_INVOICE_API = "agent/application/invoice";

export const GET_ME_API = "agent/me";

export const GET_CONTACT_LIST_API = "agent/meeting/contact-search";

export const UPDATE_VIEWER_REQUEST_API = "agent/add-viewer";

export const UPDATE_HIRE_REQUEST_API = "agent/hire/request-response";

export const GET_HIRE_REQUESTS_API = "agent/hire/request-list";

export const HIRE_REQUEST_API = "agent/hire/request";

export const GET_CLIENT_LIST_API = "agent/client/list";

export const GET_CLIENT_LOCATION_API = "agent/client/country-list";

export const DELETE_CLIENT_API = "agent/client/delete";
export const GET_CLIENT_DETAILS_API = "agent/client";

export const GET_TERMINATE_REASONS_API = "agent/dispute-reason";
export const TERMINATE_AGREEMENT_API = "agent/terminate-agreement";
export const RAISE_DISPUTE_API = "agent/raise-dispute";

export const MATCH_CLIENT_PROFILE_API = "agent/client/match-profile-list";
export const SHARE_PROGRAM_LINK = "";

export const SCHOOL_LIST_API = "agent/client/school-list";

export const ADD_CLIENT_COMMENT_API = "agent/client/comment/add";
export const GET_CLIENT_COMMENT_API = "agent/client/comment/list";

export const GET_STUDENT_APPLICATION_STATISTICS_API =
  "agent/dashboard/get-student-application";
export const GET_AGENT_INCOME_COUNT_API = "agent/dashboard/get-total-income";
export const GET_EXPRESS_COUNTRY_LIST_API = "country-list";
export const GET_STUDENT_APPLICATION_COUNT_API =
  "agent/dashboard/get-application-statastic";
export const GET_TOP_DESTINATION_UNIVERSITY_API =
  "agent/dashboard/get-top-destination-university";
export const GET_TOP_SOURCE_APPLICATION_API = "agent/dashboard/top-country";
export const ONBOARDING_PROCESS_STORE_API = "agent/onboarding/registration";

//Payments
export const GET_PAYMENT_COMMISSION_LIST_API = "agent/commissions";
export const GET_AGENT_COUNTRY_LIST_API = "agent/country-by-agent";
export const GET_PAYMENT_COMMISSION_DETAIL_API =
  "agent/payment-commission-detail";
export const GET_PAYMENT_OVERVIEW_MONTHLY_API =
  "agent/payment-overview-monthly";
export const GET_COUNTRY_BASED_COMMISSION_API = "agent/country-wise-earning";
export const AGENT_SEND_INVOICE_API = "agent/payment-commission-request";
export const GET_AGENT_TOTAL_INCOME_API = "agent/income";
export const GET_AGENT_INCOME_CURRENCY_API = "agent/currency-list";
export const GET_AGENT_EARNING_MONTHLY_API = "agent/earning-chart";

export const DELETE_CLIENT_COMMENT_API = "agent/client/comment/delete";

export const CREATE_PAYMENT_METHOD_API = "payments/add-payment";
export const GET_PAYMENT_LIST_API = "agent/payment-list";
export const GET_PAYMENT_DETAILS_API = "agent/payment-by-id";
export const UPDATE_PAYMENT_METHOD_API = "agent/update-payment";
export const DELETE_PAYMENT_METHOD_API = "agent/delete-payment";
export const SAVE_CARD_API = "agent/add-card";

export const GET_REVIEWS_API = "agent/get-agent-reviews";
export const GET_REVIEW_COMMENTS_API = "agent/get-review-comment";
export const REPLY_TO_REVIEW_API = "agent/comment-on-review";
export const DELETE_REVIEW_COMMENT_API = "agent/comment-delete";
export const GET_REVIEW_REPORT_REASONS_API =
  "agent/get-agent-review-report-option";
export const REPORT_REVIEW_API = "agent/report-review";

export const GET_CHAT_SESSIONS_LIST_API =
  "https://livechat.formeeexpress.com/api/ticketList";
export const GET_CHAT_MESSAGES_API =
  "https://livechat.formeeexpress.com/api/chatHistory";
export const DELETE_CHAT_SESSIONS_API = "";
export const STAR_CHAT_MESSAGES_API = "";

export const GET_NOTIFICATION_LIST_API = "notification-list";
export const GET_NOTIFICATION_COUNT_API = "agent/header-count";
export const MARK_READ_API = "read-notification";

export const GET_USER_PERMISSIONS_API = "agent/permission-menu";

export const DELETE_PERM_API = "inbox/mail/delete-permanently";
export const MOVE_TO_INBOX_API = "inbox/mail/move-to-inbox";

export const GET_APPLICATION_PDF_FILE_API = "university/profile/application/form/pdf"

export const GET_APPLICATION_TABS_STATUS_API = "agent/application/tab-status"