import { 
  GET_APPLICATION_DETAILS_SUCCESS,
  GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS,
  GET_APPLICATION_STATUS_LIST_SUCCESS,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_DEPARTMENT_LIST, 
  GET_DEPARTMENT_LIST_SUCCESS, 
  GET_PROGRAM_LIST_SUCCESS,
  GET_STUDY_LEVEL_SUCCESS
} from "../constants";

import {
  generateApplicationStatusString
} from "../../common/applicationHelper";

import config from "../../config.json";

const baseurl = `${config.baseurl}`;

const initialState = {
  departmentList: null,
  programList: null,
  studyLevelList: null,
  statusList: null,
  chatCount: 0,
  messageCount: 0,
  applicationStatus: null,
  appStatusString: "",
  appStatusSequence: 0,
  studentProfilePic: null
}

export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENT_LIST:
      return { ...state };
    case GET_DEPARTMENT_LIST_SUCCESS:
      return { ...state, departmentList: action.payload }
    case GET_PROGRAM_LIST_SUCCESS:
      return { ...state, programList: action.payload }
    case GET_STUDY_LEVEL_SUCCESS:
      return { ...state, studyLevelList: action.payload }
    case GET_APPLICATION_STATUS_LIST_SUCCESS:
      return { ...state, statusList: action.payload }
    case GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS:
      return {
        ...state, 
        chatCount: action.payload.data.comment_count,
        messageCount: action.payload.data.message_count
      }
    case GET_APPLICATION_STATUS_SUCCESS:
      let application = null;
      let currentStatus = "";
      let applicationStatus = null;
      let appStatusSequence = 0;

      if (action.payload.data.application_details) {
        application = action.payload.data.application_details;
        if (application && application.app_status) {
          currentStatus = generateApplicationStatusString(
            application.app_status.student_status
          )
          applicationStatus = application.app_status;
          appStatusSequence = parseFloat(application.app_status.sequence_id)
        }
      }

      return {
        ...state,
        applicationStatus,
        appStatusString: currentStatus,
        appStatusSequence
      }
    case GET_APPLICATION_DETAILS_SUCCESS:
      let updates = {};
      console.log('action.payload.data', action.payload.data);
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data &&
        action.payload.data.application_details &&
        action.payload.data.application_details.app_status
      ) {
        let currentStatus = generateApplicationStatusString(
          action.payload.data.application_details.app_status.agent_status
        )
        updates.applicationStatus = action.payload.data.application_details.app_status;
        updates.appStatusString = currentStatus;
        updates.appStatusSequence = parseFloat(action.payload.data.application_details.app_status.sequence_id);
      }

      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.student &&
        action.payload.data.student.app_student_details
      ) {
        if (action.payload.data.student.app_student_details.filename) {
          updates.studentProfilePic = baseurl + 
            action.payload.data.
              student.
              app_student_details.filename;
        }
      }
      if (updates) {
        return {
          ...state,
          ...updates
        }
      }
    default:
      return state;
  }
}