import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLoggedIn } from "./utils/utils";
import ViewMessage from "./views/Inbox/ViewMessage/viewMessageInbox";
import ViewInvoice from "./views/ViewApplications/ViewInvoice";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const NoHeaderLayout = React.lazy(() =>
  import("./containers/DefaultLayout/NoHeaderLayout")
);
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuth = isLoggedIn();
        // const isAuth = true;
        const url = JSON.stringify(props.location.pathname);
        const urls = JSON.parse(url);
        console.log("urls", urls);
        if (isAuth) {
          if (urls === "/Universitynewapplication") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/clientlist") {
            return <DefaultLayout {...props} />;
          }
          // else if (urls === "/inbox") {
          //     return <DefaultLayout {...props} />;
          // }
          else if (urls === "/profile") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/Profile") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/dashboard") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/Staffs") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/agentdashboard") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/viewus") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/managerules") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/applications") {
            return <DefaultLayout {...props} />;
          }
          // else if (urls === "/view-student-application/:id") {
          //     return <DefaultLayout {...props} />;
          // }
          // New
          else if (urls === "/prefered-partner-profile-view") {
            return <NoHeaderLayout {...props} />;
          } else if (urls === "/prefered-partner-profile") {
            return <NoHeaderLayout {...props} />;
          } else if (urls.includes("/view-application-response")) {
            return <NoHeaderLayout {...props} />;
          } else if (urls.includes("/send-offfer-letter")) {
            return <NoHeaderLayout {...props} />;
          } else if (urls.includes("/send-coe")) {
            return <NoHeaderLayout {...props} />;
          } else if (urls.includes("/inbox-compose")) {
            return <DefaultLayout {...props} />;
          } else if (urls === "/inbox") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/sentInbox") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/draftsInbox") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/deleteInbox") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/starredInbox") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/meetings-pending-invites") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/upcoming-meetings") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/meetings-drafts") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/create-meeting") {
            return <DefaultLayout {...props} />;
          } else if (urls.includes("/view-meeting")) {
            return <DefaultLayout {...props} />;
          } else if (urls.includes("/chats")) {
            return <DefaultLayout {...props} />;
          } else if (urls.includes("/starred_chats")) {
            return <DefaultLayout {...props} />;
          } else if (urls.includes("/delete_chats")) {
            return <DefaultLayout {...props} />;
          } else if (urls.includes("/viewMessageInbox")) {
            return <DefaultLayout {...props} />;

            // } else if (urls === "/viewMessageInbox") {
            //     return <DefaultLayout {...props} />;
          } else if (urls.includes("view-invoice")) {
            return <ViewInvoice {...props} />;
          } else if (urls === "/hiring-request-client") {
            return <DefaultLayout {...props} />;
          } else if (urls === "/hiring-request-me") {
            return <DefaultLayout {...props} />;
          } else if (urls.includes("/application-add-comment")) {
            return <NoHeaderLayout {...props} />;
          } else if (urls.includes("/prefered-partner-reply-comment")) {
            return <NoHeaderLayout {...props} />;
          } else {
            return (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            );
          }
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
