import {
  GET_COUNTRY_LIST_SUCCESS,
  GET_ME_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_UNIVERSITY_ABOUT,
  GET_UNIVERSITY_ABOUT_SUCCESS,
  SAVE_SIDE_PROFILE,
  SAVE_SIDE_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS
} from "../constants";

const initialState = {
  sideProfile: null,
  profile: null,
  universityLogo: ""
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_SIDE_PROFILE:
      return { ...state };
    case SAVE_SIDE_PROFILE_SUCCESS:
      return { ...state }
    case GET_UNIVERSITY_ABOUT:
      return { ...state }
    case GET_UNIVERSITY_ABOUT_SUCCESS:
      console.log('action.payload', action.payload.data)
      return { ...state, sideProfile: action.payload.data };
    case GET_PROFILE:
      return { ...state };
    case GET_ME_SUCCESS:
      let universityLogo = "";
      if (
        action.payload.data
      ) {
        universityLogo = action.payload.data.profile_pic;
      }
      console.log('log inred', action.payload);
      return {  
        ...state, 
        profile: action.payload.data, 
        universityLogo
      };
    case UPDATE_PROFILE:
      return { ...state };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state };
    default:
      return state;
  }
}