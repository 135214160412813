import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import {
	Badge,
	Button,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Nav,
	NavItem,
	UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import logo from "../../assets/img/brand/university-logo.svg";
import PasswordShowIcon from "../../assets/img/icon_show_password.svg";
import PasswordHideIcon from "../../assets/img/icon_hide_password.svg";
import Homeicon from "../../assets/img/home-icon.svg";
import Searchicon from "../../assets/img/icons/search.svg";
import Mailicon from "../../assets/img/icons/mail.svg";
import Notificationicon from "../../assets/img/noti-icon.svg";
import sygnet from "../../assets/img/brand/sygnet.svg";
import Settingsicon from "../../assets/img/settings-icon.svg";
import Defaultusericon from "../../assets/img/icons/default-user.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.json";
import jQuery from "jquery";
import "./header.scss";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { showHideUpdatePasswordModalAction } from "../../store/actions";

var baseurl = `${config.baseurl}`;
const propTypes = {
	children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			large: false,
			layout: "normal",
			description: "",
			account_type: "",
			url: "",
			id: "",
			user_group_id: "",
			heading: "",
			supervisor_id: "",
			subordinate_id: "",
			upload_photo: [],
			fileLength: "",
			errors: {},
			acc_type: [],
			acc_type_id: [],
			user_access: [],
			acc_type_univ: "",
			current: "",
			password: "",
			cpassword: "",
			checkpass: "",
			formData: new FormData(),
			mailco: "",
			applicationcount: 0,
			par_id: null,
		};
	}

	toggleLarge = () => {
		this.props.showHideUpdatePasswordModalAction(
			!this.props.modalStatus,
			() => {
				// this.setState({
				//   large: !this.state.large,
				// });
			},
			() => { }
		);
	};

	handleChange = (event) => {
		localStorage.clear();
		sessionStorage.clear();
		window.location.reload();
		window.location = "/#/login";
	};
	handleCurrentpasswordChange = (event) => {
		this.setState({ current: event.target.value });
	};

	handlePasswordChange = (event) => {
		this.setState({ password: event.target.value });
	};

	handleCpasswordChange = (event) => {
		this.setState({ cpassword: event.target.value });
	};

	getApp = (id) => {
		const useraccess = this.state.user_access;
		if (jQuery.inArray(id, useraccess) !== -1) {
			return (
				<NavLink
					to="/Universitynewapplication"
					className="nav-link"
					onClick={this.removeApplicationCount()}
				>
					Applications
					{this.state.applicationcount === 0 ? null : (
						<Badge pill color="danger">
							.
						</Badge>
					)}
				</NavLink>
			);
		} else {
			return (
				<NavLink
					to="/Universitynewapplication"
					className="nav-link"
					onClick={this.removeApplicationCount()}
				>
					Applications
					{this.state.applicationcount === 0 ? null : (
						<Badge pill color="danger">
							.
						</Badge>
					)}
				</NavLink>
			);
		}
	};

	getPgm = (id) => {
		const useraccess = this.state.user_access;
		if (jQuery.inArray(id, useraccess) !== -1) {
			return (
				<Link to="/landingpages" className="nav-link">
					Programs
				</Link>
			);
		} else {
			return (
				<Link to="#" className="nav-link">
					Programs
				</Link>
			);
		}
	};

	getLog = (id) => {
		const useraccess = this.state.user_access;
		if (jQuery.inArray(id, useraccess) !== -1) {
			return (
				<Button className="nav-link" onClick={this.handleChange}>
					Logout
				</Button>
			);
		} else {
			return (
				<Button className="nav-link" onClick={this.handleChange}>
					Logout
				</Button>
			);
		}
	};

	getAcc = (id) => {
		const useraccess = this.state.user_access;
		if (jQuery.inArray(id, useraccess) !== -1) {
			return (
				<NavLink to="/profile" className="nav-link" id="acc_menu">
					ACCOUNT
				</NavLink>
			);
		} else if (jQuery.inArray("1", useraccess) !== -1) {
			return (
				<NavLink to="/Staffs" className="nav-link" id="acc_menu">
					ACCOUNT
				</NavLink>
			);
		} else {
			return (
				<NavLink to="/profile" className="nav-link" id="acc_menu">
					ACCOUNT
				</NavLink>
			);
		}
	};

	getNotification = (id) => {
		const useraccess = this.state.user_access;
		if (jQuery.inArray(id, useraccess) !== -1) {
			return (
				<NavLink
					to="/profilenotification"
					className="nav-link"
					onClick={this.removeMailCount()}
				>
					<img
						src={Notificationicon}
						alt="Notification-icon"
						className="uni-icon"
					/>
					{this.state.mailco === 0 ? null : (
						<Badge pill color="danger">
							.
						</Badge>
					)}
				</NavLink>
			);
		} else {
			return (
				<NavLink
					to="#"
					className="nav-link"
					onClick={this.removeMailCount()}
				>
					<img
						src={Notificationicon}
						alt="Notification-icon"
						className="uni-icon"
					/>
					{this.state.mailco === 0 ? null : (
						<Badge pill color="danger">
							.
						</Badge>
					)}
				</NavLink>
			);
		}
	};

	getInbox = (id) => {
		const useraccess = this.state.user_access;
		if (jQuery.inArray(id, useraccess) !== -1) {
			return (
				<NavLink
					to="/inbox"
					className="nav-link"
					onClick={this.removeMailCount()}
				>
					<img src={Mailicon} alt="mail-icon" className="uni-icon" />
					{this.state.mailco === 0 ? null : (
						<Badge pill color="danger">
							.
						</Badge>
					)}
				</NavLink>
			);
		} else {
			return (
				<NavLink
					to="#"
					className="nav-link"
					onClick={this.removeMailCount()}
				>
					<img src={Mailicon} alt="mail-icon" className="uni-icon" />
					{this.state.mailco === 0 ? null : (
						<Badge pill color="danger">
							.
						</Badge>
					)}
				</NavLink>
			);
		}
	};

	getPay = (id) => {
		const useraccess = this.state.user_access;
		if (jQuery.inArray(id, useraccess) !== -1) {
			return (
				<NavLink to="/Payment" className="nav-link">
					Payments
				</NavLink>
			);
		} else {
			return (
				<NavLink to="#" className="nav-link">
					Payments
				</NavLink>
			);
		}
	};

	componentDidMount() {
		const param = localStorage.getItem("universityid");
		axios
			.get(baseurl + "/get_staffpermissions/" + param)
			.then((response) => {
				this.setState({
					acc_type: response.data.acc_type,
					acc_type_univ: response.data.acc_type_univ,
					acc_type_id: response.data.acc_type_id,
				});

				if (response.data.acc_type_univ !== 4) {
					axios
						.get(baseurl + "/get_parent_universityid/" + param)
						.then((resp) => {
							localStorage.setItem(
								"userType",
								response.data.acc_type_univ
							);

							this.setState({
								user_group_id: this.state.acc_type_univ,
							});
							axios
								.get(
									baseurl +
									"/getstaffpermission/" +
									this.state.acc_type_univ +
									"/" +
									resp.data.parent_universityid
								)
								.then((response) => {
									if (response && response.data) {
										this.setState({
											heading:
												response.data.data.permission
													.heading,
											supervisor_id:
												response.data.data.permission
													.supervisor_id,
											subordinate_id:
												response.data.data.permission
													.subordinate_id,
											id: response.data.data.permission
												.id,
											user_access:
												response.data.data.permission
													.user_access,
										});
									}
								});
						});
				} else {
					localStorage.setItem(
						"userType",
						response.data.acc_type_univ
					);

					this.setState({ user_group_id: this.state.acc_type_univ });
					const univ_id = localStorage.getItem("universityid");
					axios
						.get(
							baseurl +
							"/getstaffpermission/" +
							this.state.acc_type_univ +
							"/" +
							univ_id
						)
						.then((response) => {
							if (response && response.data) {
								this.setState({
									heading: response.data.permission.heading,
									supervisor_id:
										response.data.permission.supervisor_id,
									subordinate_id:
										response.data.permission.subordinate_id,
									id: response.data.permission.id,
									user_access:
										response.data.permission.user_access,
								});
							}
						});
				}
			});

		setInterval(() => {
			axios
				.get(
					baseurl +
					"/get_inboxmail_notification/" +
					localStorage.getItem("universityid") +
					"/University"
				)
				.then((response) => {
					this.setState({
						mailco: response.data.count,
					});
				});
			axios
				.get(
					baseurl +
					"/university_app_count/" +
					localStorage.getItem("universityid")
				)
				.then((response) => {
					this.setState({
						applicationcount: response.data.newapplicationcount,
					});
				});
		}, 10000);
	}

	removeMailCount = (param) => (e) => {
		axios
			.get(
				baseurl +
				"/update_inboxmail_notification/" +
				localStorage.getItem("universityid") +
				"/University"
			)
			.then((response) => { });
	};
	removeApplicationCount = (param) => (e) => {
		axios
			.get(
				baseurl +
				"/update_university_app_count/" +
				localStorage.getItem("universityid")
			)
			.then((response) => { });
	};

	validate = () => {
		const errors = {};

		const pwd =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		//alert(this.state.checkpass.trim());
		if (this.state.current.trim() === "") {
			errors.current = "Current Password is required";
		} else if (this.state.password.trim() === "") {
			errors.password = "Password is required";
		} else if (this.state.cpassword.trim() === "") {
			errors.cpassword = "Confirm password is required";
		} else if (this.state.cpassword.trim() !== this.state.password.trim()) {
			errors.cpassword = "Password does not match";
		} else if (!this.state.password.match(pwd)) {
			errors.password =
				"Minimum 8 characters with combination of uppercase,lowercase,number,special character required";
		} else {
			this.onSubmit();
		}

		this.setState({ errors });
	};

	onSubmit = () => {
		let password = this.state.password;
		let currentpassword = this.state.current;
		let id = localStorage.getItem("universityid");
		axios
			.post(baseurl + "/updatepassword", {
				password: password,
				id: id,
				currentpassword: currentpassword,
			})

			.then(function (response) {
				if (response.data === 1) {
					toast.success("Password updated successfully");
					setTimeout(function () {
						localStorage.clear();
						sessionStorage.clear();
						window.location = "/#/login";
					}, 4000);
				} else {
					toast.error("Current password incorrect");
				}
			})
			.catch(function (error) {
				//toast.success("Error occured");
			});
	};

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { errors } = this.state;
		// eslint-disable-next-line
		const { children, ...attributes } = this.props;

		return (
			<React.Fragment>
				<Modal
					isOpen={this.props.modalStatus}
					toggle={this.toggleLarge}
					//className={"modal-md " + this.props.className}
					className="primaryModal"
					size="lg"
					style={{ maxWidth: "650px", width: "100%" }}
				>
					<ModalHeader>Change Password</ModalHeader>

					<ModalBody>
						<Form className="basicForm">
							<div className="row col-md-10 mx-auto mt-4">
								{/* <div className="col-md-12">
									<label className="card-label">
										Current password
									</label>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend" />
										<Input
											type="password"
											name="current"
											value={this.state.current}
											placeholder="Enter current password"
											onChange={
												this.handleCurrentpasswordChange
											}
										/>
									</InputGroup>
									<h6 style={{ color: "red" }}>
										{errors.current}
									</h6>
								</div> */}
								<div className="col-md-12">
									<label className="card-label">
										New Password
									</label>
									<InputGroup className="mb-3">
										{/* <InputGroupAddon addonType="prepend" /> */}
										<Input
											type="password"
											name="password"
											placeholder="Enter new password"
											value={this.state.password}
											onChange={this.handlePasswordChange}
										/>
										<InputGroupAddon addonType="append">
											<InputGroupText>
												<img
													src={PasswordShowIcon}
													alt="Show Password"
												/>
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
									<h6 style={{ color: "red" }}>
										{errors.password}
									</h6>
								</div>
								<div className="col-md-12">
									<label className="card-label">
										Confirm New Password
									</label>
									<InputGroup className="mb-3">
										{/* <InputGroupAddon addonType="prepend" /> */}
										<Input
											type="password"
											name="cpassword"
											placeholder="Enter confirm new password"
											value={this.state.cpassword}
											onChange={
												this.handleCpasswordChange
											}
										/>
										<InputGroupAddon addonType="append">
											<InputGroupText>
												<img
													src={PasswordHideIcon}
													alt="Show Password"
												/>
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
									<h6 style={{ color: "red" }}>
										{errors.cpassword}
									</h6>
								</div>
							</div>

							<ModalFooter className="pt-4 mt-3">
								<Button
									className="mx-auto w-40"
									color="secondary"
									onClick={this.validate}
									shape="round"
								>
									Cancel
								</Button>
								<Button
									className="mx-auto w-40"
									color="primary"
									onClick={this.validate}
									shape="round"
								>
									Update my password
								</Button>
							</ModalFooter>
						</Form>
					</ModalBody>
				</Modal>

				<Container className="foe-py-2">
					<AppSidebarToggler
						className="d-lg-none"
						display="md"
						mobile
					/>
					<AppNavbarBrand
						full={{
							src: logo,
							width: "100%",
							height: "auto",
							alt: "University Logo",
						}}
						tag={NavLink}
						to={"/universitydashboard"}
					/>
					<AppSidebarToggler
						className="d-md-down-none d-none"
						display="lg"
					/>

					<Nav className="d-md-down-none" navbar>
						<NavItem className="px-2">
							{this.getNotification("6")}
						</NavItem>

						<UncontrolledDropdown nav direction="down">
							<DropdownToggle nav>
								<img
									src={Defaultusericon}
									alt="default-user-icon"
									className="uni-icon"
								/>
							</DropdownToggle>
							<DropdownMenu className="logout-section" right>
								<DropdownItem
									header
									tag="div"
									className="text-center"
								>
									<strong>Account</strong>
								</DropdownItem>
								<DropdownItem onClick={this.toggleLarge}>
									{/* <i className="fa fa-key"></i>  */} Reset
									password
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
					<Nav className="ml-auto" navbar></Nav>
				</Container>
			</React.Fragment>
		);
	}
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

// function mapStateToProps(state) {
// 	return {
// 		modalStatus: state.generalReducer.showPasswordModal,
// 	};
// }

// const mapDispatchToProps = (dispatch) =>
// 	bindActionCreators(
// 		{
// 			showHideUpdatePasswordModalAction,
// 		},
// 		dispatch
// 	);

// export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);

export default DefaultHeader;
