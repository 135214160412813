import React, { Component } from "react";
import config from "../../../config.json";
import jQuery from "jquery";
import "./ViewInvoice.scss";
import Dropzone from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import { Container, Row, Col, Button, Form } from "reactstrap";
import DatePicker from "reactstrap-date-picker";
import HeaderLogoNotificationUser from "../../../containers/DefaultLayout/Header-LogoNotificationUser";

import backarrow from "../../../assets/img/university/back_arrow.svg";
import DeakinLogo from "../../../assets/img/university/deakin_logo.jpg";
import UserIcon from "../../../assets/img/icons/user-menu.svg";
// import UploadIcon from "../../../assets/img/icons/upload_white_icon.svg";
import PDFIcon from "../../../assets/img/icons/pdf_icon.png";
import RemoveFileIcon from "../../../assets/img/icons/remove_file_icon.svg";
import CalIcon from "../../../assets/img/icons/cal_gray_icon.svg";
import PlaneIcon from "../../../assets/img/icons/send_plane_white_icon.svg";
import HasAttachmentIcon from "../../../assets/img/icons/has_attachment_icon.svg";
import DownloadAttachemnet from "../../../assets/img/icons/download_attachemnet.svg";
import { ReactComponent as UploadIcon } from "../../../assets/img/icons/download_attachemnet.svg";
import { ReactComponent as AttachmentIcon } from "../../../assets/img/icons/upload_white_icon.svg";

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
	getProfileAction,
	getApplicationMessagesAction,
	sendApplicationMessageAction,
	getApplicationDetailsAction,
	getInvoiceAction
} from "../../../store/actions";
import PageLoading from "../../../common/PageLoading";
import { toast, ToastContainer } from "react-toastify";
import { MESSAGES } from "../../../constants/messages";
import { baseUrl, XERO_PDF_API } from "../../../Api";
import moment from "moment";

var baseurl = `${config.baseurl}`;

class ViewInvoice extends Component {
	constructor(props) {
		super(props);

		this.editorRef = React.createRef();

		this.state = {
			loading: false,
			files: [],
			thumbs: [],
			applicationId: 0,
			universityId: 0,
			university: null,
			applicationMessages: [],
			application: null,
			studentId: 0,
      invoice: null
		};
	}

	onDrop = (droppedFile) => {
		console.log('filess', droppedFile);
		// this.setState({ files });
		let filesList = droppedFile;
		let noOfFiles = droppedFile.length;
		let {
			files
		} = this.state;

		for (let file = 0; file < noOfFiles; file++) {
			console.log('files[file]', filesList[file])
			let size = this.checkValidFileSize(filesList[file]);
			if (!size) {

				files.push(filesList[file]);
			} else {
				toast.error(MESSAGES.VALID_IMAGE_SIZE);
				return;
			}
		}

		this.setState({
			files
		})
	};

	handleChange(value, formattedValue) {
		this.setState({
			value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
			formattedValue: formattedValue, // Formatted String, ex: "11/19/2016"
		});
		console.log("Date Picker datePickerValue", value);
		console.log("Date Picker formattedValue", formattedValue);
	}

	componentDidMount() {
		let id =
			this.props.match &&
			this.props.match.params &&
			this.props.match.params.id;

		let param = localStorage.getItem("agent_id");

		this.setState({
			applicationId: id,
			universityId: param
		}, () => {
			// this.getProfile();
			// this.getMessages();
			this.getApplication();
			this.getInvoice();
		})
	}

	componentDidUpdate() {

	}

	showLoader = () => {
		this.setState({
			// loading: true
			loading: false
		})
	}

	hideLoader = () => {
		this.setState({
			loading: false
		})
	}

	getProfile = () => {
		this.showLoader();
		new Promise((resolve, reject) => {
			this.props.getProfileAction && this.props.getProfileAction(
				{
					univ_id: this.state.universityId
				},
				(response) => {
					this.hideLoader();
					console.log('resposnes', response)
					if (response.success) {
						this.setState({
							university: response.data.about
						});
					}
					resolve();
				},
				(err) => {
					this.hideLoader();
					reject();
				}
			)
		})
	}

	getApplication = () => {
		this.showLoader();
		new Promise((resolve, reject) => {
			this.props.getApplicationDetailsAction && this.props.getApplicationDetailsAction(
				{
					agent_id: this.state.universityId,
					application_id: this.state.applicationId,
					tab: "general"
				},
				(response) => {
					this.hideLoader();
					if (response.success) {
						this.setState({
							application: response.data.application_details
						}, () => {
							let studentId = 0;
							if (this.state.application) {
								studentId = this.state.application.student_id;
							}
              if (this.state.application.university_details) {
                this.setState({
                  university: this.state.application.university_details,
                  course: this.state.application.course
                })
              }
							this.setState({
								studentId
							})
						})
					}
					resolve();
				},
				(err) => {
					this.hideLoader();
					reject();
				}
			)
		})
	}

	getInvoice = () => {
		this.props.getInvoiceAction({
			agent_id: this.state.universityId,
			application_id: this.state.applicationId
		}, (response) => {
			if (response.success) {
				this.setState({
					invoice: response.data.invoice_details
				}, () => {
					let files = [];

					if (this.state.invoice) {
						files.push({
							name: `Invoice-${this.state.invoice.invoice_number}.pdf`,
							url: baseUrl + XERO_PDF_API+"/"+this.state.invoice.invoice_id
						})

						this.setState({
							files
						})
					}
				})
			}
		}, (err) => {

		})
	}

	getMessages = () => {
		this.showLoader();

		new Promise((resolve, reject) => {
			this.props.getApplicationMessagesAction && this.props.getApplicationMessagesAction(
				{
					application_id: this.state.applicationId
				},
				(response) => {
					this.hideLoader();
					if (response.success) {
						this.setState({
							applicationMessages: response.data
						})
					}
					resolve();
				},
				(reject) => {
					this.hideLoader();
					reject();
				}
			)
		})
	}

	sendMessage = () => {
		let content = this.editorRef.current.getContent();

		if (!content) {
			toast.warning(MESSAGES.APPLICATION_MESSAGE_CONTENT_REQUIRED);
			return;
		}

		this.showLoader();

		let formData = new FormData();
		formData.append('university_id', this.state.universityId);
		formData.append('application_id', this.state.applicationId);
		formData.append('type', 1);
		formData.append('student_id', this.state.studentId);
		formData.append('message', content);
		if (this.state.files.length) {
			this.state.files.map((val, index) => {
				formData.append('required_document[]', val);
			})
		}

		new Promise((resolve, reject) => {
			this.props.sendApplicationMessageAction && this.props.sendApplicationMessageAction(
				formData,
				(response) => {
					this.hideLoader();
					if (response.success) {
						toast.success(response.message);
						this.getMessages();
						this.editorRef.current.setContent("");
						this.setState({
							files: []
						})
					}
					resolve();
				},
				(err) => {
					this.hideLoader();
					reject();
				}
			)
		})
	}

	handleInit = (evt, editor) => {
		this.setState({ length: editor.getContent({ format: "text" }).length });
	};

	attachemtList = [
		{
			id: 1,
			name: "Assignment_name.pdf",
			size: "45842",
		},
		{
			id: 2,
			name: "Assignment_name_2.pdf",
			size: "65481",
		},
		{
			id: 3,
			name: "Assignment_name_3.pdf",
			size: "54132",
		},
	];

	goBack = () => {
		this.props.history.goBack()
		// this.props.history.push(
		// 	"/view-student-application/" +
		// 	this.state.applicationId
		// )
	}

	getMessageHeader = (val) => {
		let header = null;

		if (val.type == 1) {
			header = <Row>
				<Col xs="12">
					<div className="studentDetails">
						{
							this.state.university ? (
								this.state.university.logo_image ? (
									<img
										src={baseurl + this.state.university.logo_image}
										alt="University Logo"
										style={{
											"max-width": "84px",
											height: "auto",
											"max-height": "50px"
										}}
									/>
								) : null
							) : null
						}
						<strong>
							To {
								val.student ? (
									(
										val.student.studentdetails ? (
											val.student.studentdetails.first_name +
											val.student.studentdetails.last_name
										) : ""
									)
								) : ""
							}
							<small>
								{
									val.message_date
								}
							</small>
						</strong>
					</div>
				</Col>
			</Row>;
		} else {
			header = <Row>
				<Col xs="12">
					<div className="studentDetails">
						{
							val.student ? (
								(
									val.student.studentdetails &&
									val.student.studentdetails.filename
								) ? (
									<img
										src={baseurl + val.student.studentdetails.filename}
										alt="student pic"
										style={{
											"max-width": "84px",
											height: "auto",
											"max-height": "50px"
										}}
									/>
								) : null
							) : null
						}
						<strong>
							{
								val.student ? (
									(
										val.student.studentdetails ? (
											val.student.studentdetails.first_name +
											val.student.studentdetails.last_name
										) : ""
									)
								) : ""
							}
							<small>
								{val.message_date}
							</small>
						</strong>
					</div>
				</Col>
			</Row>;
		}

		return header;
	}

	getMessageElement = (val, index) => {
		return (
			<div className="viewResponseSections pb-0  mb-20">
				<div className="header">
					{
						this.getMessageHeader(val)
					}
				</div>
				<div className="body">
					<div
						className="text"
						dangerouslySetInnerHTML={{
							__html: val.message
						}}
					>
					</div>

					<div className="attachement">
						{
							val.documents.length ? (
								<>
									<div className="detail">
										<img
											src={HasAttachmentIcon}
											alt=""
											className="mr-10"
										/>
										{val.documents.length}{" "}
										Attachment
									</div>

									<div className="fileList">
										{val.documents.map(
											(file, index) => {
												return (
													<div
														className="box"
														key={index}
													>
														<div className="fileName">
															<u>
																{file.orgfilename}
															</u>
															{/* <div className="fileSize">
																[
																{(
																	file.size /
																	1024
																).toFixed(
																	2
																)}{" "}
																KB]
															</div> */}
														</div>

														<img
															src={
																DownloadAttachemnet
															}
															alt="Download"
															className="DownloadAttachemnet"
															onClick={() => {
																window.open(baseurl + file.filename, "_blank")
															}}
														/>
													</div>
												)
											}
										)}
									</div>
								</>
							) : null
						}
					</div>
				</div>

			</div>
		)
	}

	checkValidFileSize = (file) => {
		let fileSize = file.size / 1048576;

		return fileSize > 14;
	}

	render() {

		// let {
		// 	loading
		// } = this.state;
		console.log("this.props.history", this.props.history.goBack);
		return (
			<>
				{/* <ToastContainer /> */}
				{/* <PageLoading loading={loading} /> */}
				<div style={{backgroundColor:"#fafafa"}}>
					<header className="university-header app-header navbar mb-60 simpleLayout">
						<HeaderLogoNotificationUser />
					</header>
					<Container>
						<Row>
							<Col xs="2">
								<a
									className="backarrow mt-3 cur-pointer"
									onClick={() => {
										this.goBack()
									}}
								>
									<span className="pr-1 mr-5">
										<img src={backarrow} alt="" />
									</span>
									Back
								</a>
							</Col>
							<Col xs="10">
								<div className="pageHeader">
									<h3 className="pageTitle thinTitle">
										View Invoice
									</h3>
								</div>
							</Col>

							<Col xs="12" lg={{ size: 10, offset: 2 }}>
								<div className="offerLetterSection pb-0">
									<div className="header">
										<Row>
											<Col xs="12">
												<div className="uniDetails">
													{
														this.state.university ? (
															this.state.university.logo_image ? (
																<img
																	src={baseurl + this.state.university.logo_image}
																	alt="University Logo"
																	style={{
																		"max-width": "84px",
																		height: "auto",
																		"max-height": "50px"
																	}}
																/>
															) : null
														) : null
													}
													<strong>
														{
															this.state.course ? (
																this.state.course.study_level ? (
																	this.state.course.study_level.level_name+" - "
																) : ""
															) : ""
														}
														{
															this.state.course ? (
																this.state.course.heading + " - "
															) : ""
														}
														
														{
															this.state.course ? (
																this.state.course.department ? (
																	this.state.course.department.heading
																) : ""
															) : ""
														}
													</strong>
												</div>
											</Col>
										</Row>
									</div>

									<div className="body">
										{
											this.state.files.length ? (
												<div className="attachment">
													<AttachmentIcon /> 
													{this.state.files && this.state.files.length} Attachment
												</div>
											) : (
												<div className="attachment">
													No invoices yet!
												</div>
											)
										}
										
										{
											this.state.files.length ? (
												<div className="fileList">
													{
														this.state.files.map((file, index) => {
															return (
																<div
																	className="box"
																	key={index}
																	onClick={() => {
																		window.open(file.url, "_blank")
																	}}
																>
																	<img
																		src={
																			PDFIcon
																		}
																		alt="pdf"
																		className="fileIcon"
																	/>
	
																	<div className="fileName">
																		{file.name}
																	</div>
																	<UploadIcon />
																</div>
															)
														})
													}
												</div>
											) : null
										}
										
										{
											this.state.invoice ? (
												<div className="offerDate">
													<label>Due date : 
														<strong>
															{moment(this.state.invoice.due_date).format('DD-MM-YYYY')}
														</strong>
													</label>
												</div>
											) : null
										}
										
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {

	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getProfileAction,
			getApplicationMessagesAction,
			sendApplicationMessageAction,
			getApplicationDetailsAction,
			getInvoiceAction
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice);