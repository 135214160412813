import { COMPOSE_MAIL, COMPOSE_MAIL_ERROR, COMPOSE_MAIL_SUCCESS, CREATE_MEETING, CREATE_MEETING_ERROR, CREATE_MEETING_SUCCESS, DELETE_INBOX_EMAIL, DELETE_INBOX_EMAIL_ERROR, DELETE_INBOX_EMAIL_SUCCESS, GET_INBOX, GET_INBOX_ERROR, GET_INBOX_MAIL_DETAILS, GET_INBOX_MAIL_DETAILS_ERROR, GET_INBOX_MAIL_DETAILS_SUCCESS, GET_INBOX_SUCCESS, GET_TIMEZONES_LIST, GET_TIMEZONES_LIST_ERROR, GET_TIMEZONES_LIST_SUCCESS, GET_UNREAD_INBOX_COUNT, GET_UNREAD_INBOX_COUNT_ERROR, GET_UNREAD_INBOX_COUNT_SUCCESS, UPDATE_INBOX_READ_STATUS, UPDATE_INBOX_READ_STATUS_ERROR, UPDATE_INBOX_READ_STATUS_SUCCESS, UPDATE_MAIL_FAVOURITE, UPDATE_MAIL_FAVOURITE_ERROR, UPDATE_MAIL_FAVOURITE_SUCCESS, MEETING_LIST, MEETING_LIST_ERROR, MEETING_LIST_SUCCESS, MEETING_DETAILS, MEETING_DETAILS_ERROR, MEETING_DETAILS_SUCCESS, UPDATE_MEETING_STATUS, UPDATE_MEETING_STATUS_SUCCESS, UPDATE_MEETING_STATUS_ERROR, DELETE_MEETING, DELETE_MEETING_SUCCESS, DELETE_MEETING_ERROR, GET_CONTACT_LIST, GET_CONTACT_LIST_SUCCESS, GET_CONTACT_LIST_ERROR, GET_REPLY_TO_MEETING, GET_REPLY_TO_MEETING_SUCCESS, GET_REPLY_TO_MEETING_ERROR, UPDATE_VIEWER_REQUEST_ERROR, UPDATE_VIEWER_REQUEST_SUCCESS, UPDATE_VIEWER_REQUEST, UPDATE_HIRE_REQUEST, UPDATE_HIRE_REQUEST_SUCCESS, UPDATE_HIRE_REQUEST_ERROR, GET_HIRE_REQUESTS_ERROR, GET_HIRE_REQUESTS_SUCCESS, GET_HIRE_REQUESTS, HIRE_REQUEST_ERROR, HIRE_REQUEST_SUCCESS, HIRE_REQUEST, MOVE_TO_INBOX_ERROR, MOVE_TO_INBOX_SUCCESS, MOVE_TO_INBOX, DELETE_PERM_ERROR, DELETE_PERM_SUCCESS, DELETE_PERM } from "../constants";

export function composeMailAction(session, resolve, reject) {
  return {
    type: COMPOSE_MAIL,
    payload: session,
    resolve,
    reject
  };
}

export function composeMailSuccess(data) {
  return {
    type: COMPOSE_MAIL_SUCCESS,
    payload: data
  };
}

export function composeMailError(error) {
  return {
    type: COMPOSE_MAIL_ERROR,
    payload: error
  };
}

export function getInboxAction(session, resolve, reject) {
  return {
    type: GET_INBOX,
    payload: session,
    resolve,
    reject
  };
}

export function getInboxSuccess(data) {
  return {
    type: GET_INBOX_SUCCESS,
    payload: data
  };
}

export function getInboxError(error) {
  return {
    type: GET_INBOX_ERROR,
    payload: error
  };
}

export function updateFavouriteAction(session, resolve, reject) {
  return {
    type: UPDATE_MAIL_FAVOURITE,
    payload: session,
    resolve,
    reject
  };
}

export function updateFavouriteSuccess(data) {
  return {
    type: UPDATE_MAIL_FAVOURITE_SUCCESS,
    payload: data
  };
}

export function updateFavouriteError(error) {
  return {
    type: UPDATE_MAIL_FAVOURITE_ERROR,
    payload: error
  };
}

export function deleteEmailAction(session, resolve, reject) {
  return {
    type: DELETE_INBOX_EMAIL,
    payload: session,
    resolve,
    reject
  };
}

export function deleteEmailSuccess(data) {
  return {
    type: DELETE_INBOX_EMAIL_SUCCESS,
    payload: data
  };
}

export function deleteEmailError(error) {
  return {
    type: DELETE_INBOX_EMAIL_ERROR,
    payload: error
  };
}

export function getUnreadInboxCountAction(session, resolve, reject) {
  return {
    type: GET_UNREAD_INBOX_COUNT,
    payload: session,
    resolve,
    reject
  };
}

export function getUnreadInboxCountSuccess(data) {
  return {
    type: GET_UNREAD_INBOX_COUNT_SUCCESS,
    payload: data
  };
}

export function getUnreadInboxCountError(error) {
  return {
    type: GET_UNREAD_INBOX_COUNT_ERROR,
    payload: error
  };
}

export function getInboxMailDetailsAction(session, resolve, reject) {
  return {
    type: GET_INBOX_MAIL_DETAILS,
    payload: session,
    resolve,
    reject
  };
}

export function getInboxMailDetailsSuccess(data) {
  return {
    type: GET_INBOX_MAIL_DETAILS_SUCCESS,
    payload: data
  };
}

export function getInboxMailDetailsError(error) {
  return {
    type: GET_INBOX_MAIL_DETAILS_ERROR,
    payload: error
  };
}

export function updateInboxReadStatusAction(session, resolve, reject) {
  return {
    type: UPDATE_INBOX_READ_STATUS,
    payload: session,
    resolve,
    reject
  };
}

export function updateInboxReadStatusSuccess(data) {
  return {
    type: UPDATE_INBOX_READ_STATUS_SUCCESS,
    payload: data
  };
}

export function updateInboxReadStatusError(error) {
  return {
    type: UPDATE_INBOX_READ_STATUS_ERROR,
    payload: error
  };
}

export function getTimeZonesListAction(session, resolve, reject) {
  return {
    type: GET_TIMEZONES_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getTimeZonesListSuccess(data) {
  return {
    type: GET_TIMEZONES_LIST_SUCCESS,
    payload: data
  };
}

export function getTimeZonesListError(error) {
  return {
    type: GET_TIMEZONES_LIST_ERROR,
    payload: error
  };
}

export function createMeetingAction(session, resolve, reject) {
  return {
    type: CREATE_MEETING,
    payload: session,
    resolve,
    reject
  };
}

export function createMeetingSuccess(data) {
  return {
    type: CREATE_MEETING_SUCCESS,
    payload: data
  };
}

export function createMeetingError(error) {
  return {
    type: CREATE_MEETING_ERROR,
    payload: error
  };
}

export function meetingListAction(session, resolve, reject) {
  return {
    type: MEETING_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function meetingListSuccess(data) {
  return {
    type: MEETING_LIST_SUCCESS,
    payload: data
  };
}

export function meetingListError(error) {
  return {
    type: MEETING_LIST_ERROR,
    payload: error
  };
}

export function meetingDetailsAction(session, resolve, reject) {
  return {
    type: MEETING_DETAILS,
    payload: session,
    resolve,
    reject
  };
}

export function meetingDetailsSuccess(data) {
  return {
    type: MEETING_DETAILS_SUCCESS,
    payload: data
  };
}

export function meetingDetailsError(error) {
  return {
    type: MEETING_DETAILS_ERROR,
    payload: error
  };
}

export function updateMeetingStatusAction(session, resolve, reject) {
  return {
    type: UPDATE_MEETING_STATUS,
    payload: session,
    resolve,
    reject
  };
}

export function updateMeetingStatusSuccess(data) {
  return {
    type: UPDATE_MEETING_STATUS_SUCCESS,
    payload: data
  };
}

export function updateMeetingStatusError(error) {
  return {
    type: UPDATE_MEETING_STATUS_ERROR,
    payload: error
  };
}

export function deleteMeetingAction(session, resolve, reject) {
  return {
    type: DELETE_MEETING,
    payload: session,
    resolve,
    reject
  };
}

export function deleteMeetingSuccess(data) {
  return {
    type: DELETE_MEETING_SUCCESS,
    payload: data
  };
}

export function deleteMeetingError(error) {
  return {
    type: DELETE_MEETING_ERROR,
    payload: error
  };
}

export function getContactListAction(session, resolve, reject) {
  return {
    type: GET_CONTACT_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getContactListSuccess(data) {
  return {
    type: GET_CONTACT_LIST_SUCCESS,
    payload: data
  };
}

export function getContactListError(error) {
  return {
    type: GET_CONTACT_LIST_ERROR,
    payload: error
  };
}

export function getReplyToMeeting(session, resolve, reject) {
	return {
		type: GET_REPLY_TO_MEETING,
		payload: session,
		resolve,
		reject
	};
}

export function getReplyToMeetingSuccess(data) {
	return {
		type: GET_REPLY_TO_MEETING_SUCCESS,
		payload: data
	};
}

export function getReplyToMeetingError(error) {
	return {
		type: GET_REPLY_TO_MEETING_ERROR,
		payload: error
	};
}

export function updateViewerRequestAction(session, resolve, reject) {
	return {
		type: UPDATE_VIEWER_REQUEST,
		payload: session,
		resolve,
		reject
	};
}

export function updateViewerRequestSuccess(data) {
	return {
		type: UPDATE_VIEWER_REQUEST_SUCCESS,
		payload: data
	};
}

export function updateViewerRequestError(error) {
	return {
		type: UPDATE_VIEWER_REQUEST_ERROR,
		payload: error
	};
}

export function updateHireRequestAction(session, resolve, reject) {
	return {
		type: UPDATE_HIRE_REQUEST,
		payload: session,
		resolve,
		reject
	};
}

export function updateHireRequestSuccess(data) {
	return {
		type: UPDATE_HIRE_REQUEST_SUCCESS,
		payload: data
	};
}

export function updateHireRequestError(error) {
	return {
		type: UPDATE_HIRE_REQUEST_ERROR,
		payload: error
	};
}

export function getHireRequestAction(session, resolve, reject) {
	return {
		type: GET_HIRE_REQUESTS,
		payload: session,
		resolve,
		reject
	};
}

export function getHireRequestSuccess(data) {
	return {
		type: GET_HIRE_REQUESTS_SUCCESS,
		payload: data
	};
}

export function getHireRequestError(error) {
	return {
		type: GET_HIRE_REQUESTS_ERROR,
		payload: error
	};
}

export function hireRequestAction(session, resolve, reject) {
	return {
		type: HIRE_REQUEST,
		payload: session,
		resolve,
		reject
	};
}

export function hireRequestSuccess(data) {
	return {
		type: HIRE_REQUEST_SUCCESS,
		payload: data
	};
}

export function hireRequestError(error) {
	return {
		type: HIRE_REQUEST_ERROR,
		payload: error
	};
}

export function deletePermAction(session, resolve, reject) {
	return {
		type: DELETE_PERM,
		payload: session,
		resolve,
		reject
	};
}

export function deletePermSuccess(data) {
	return {
		type: DELETE_PERM_SUCCESS,
		payload: data
	};
}

export function deletePermError(error) {
	return {
		type: DELETE_PERM_ERROR,
		payload: error
	};
}

export function moveToInboxAction(session, resolve, reject) {
	return {
		type: MOVE_TO_INBOX,
		payload: session,
		resolve,
		reject
	};
}

export function moveToInboxSuccess(data) {
	return {
		type: MOVE_TO_INBOX_SUCCESS,
		payload: data
	};
}

export function moveToInboxError(error) {
	return {
		type: MOVE_TO_INBOX_ERROR,
		payload: error
	};
}