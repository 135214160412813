import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import $ from "jquery";

import { ReactComponent as ComposeIcon } from "../../../assets/img/icons/inbox/compose_white_icon.svg";
import { ReactComponent as InboxIcon } from "../../../assets/img/icons/inbox/inbox_gray_icon.svg";
import { ReactComponent as SentIcon } from "../../../assets/img/icons/inbox/sent_gray_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons/inbox/delete_gray_icon.svg";
import { ReactComponent as StarredIcon } from "../../../assets/img/icons/inbox/starred_gray_icon.svg";
import { ReactComponent as MeetingIcon } from "../../../assets/img/icons/inbox/meetings_gray_icon.svg";

import { ReactComponent as DraftIcon } from "../../../assets/img/icons/inbox/draft_gray_icon.svg";
import { ReactComponent as ChatIcon } from "../../../assets/img/icons/inbox/chat_gray_icon.svg";
import { ReactComponent as PendingIcon } from "../../../assets/img/icons/inbox/pending_meetings_gray_icon.svg";
import { ReactComponent as UpcomingIcon } from "../../../assets/img/icons/inbox/upcoming_meetings_gray_icon.svg";
import { ReactComponent as ToggleMenuIcon } from "../../../assets/img/icons/toggle-menu.svg";
import { ReactComponent as ToggleCloseMenuIcon } from "../../../assets/img/icons/toggle-close-menu.svg";
import { ReactComponent as DropDownIcon } from "../../../assets/img/icons/chevron-right.svg";
import Scrollbars from "react-custom-scrollbars";

import "../commonListing.scss";

class LeftInboxMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      meetingsOpen: false,
      hiringRequestOpen: false,
      mobileSecondaryMenu: false
    }
  }

  componentDidMount() {
    const { pathname } = this.props.location
    let meetingsOpen = false;
    let chatsOpen = false;

    if (
      pathname == '/meetings-pending-invites' ||
      pathname == "/upcoming-meetings" ||
      pathname == "/meetings-drafts"
    ) {
      meetingsOpen = true;
    }
    this.setState({
      meetingsOpen
    })

    if (
      pathname == '/chats' ||
      pathname == "/starred_chats" ||
      pathname == "/delete_chats"
    ) {
      chatsOpen = true;
    }
    this.setState({
      chatsOpen: chatsOpen,
    });
  }

  handleCollapseMenu = (val) => {
    this.state.submenuOpen === val
      ? this.setState({ submenuOpen: "" })
      : this.setState({ submenuOpen: val });
  };

  render() {

    let getUrl = window.location.href.split("/");
    let urlLength = window.location.href.split("/").length;
    let currentUrl = getUrl[urlLength - 1];

    const mobileSecondaryMenuHideShow = () => {
      this.setState({
        mobileSecondaryMenu: !this.state.mobileSecondaryMenu
      })
    }

    return (
      <>
        <div className={`secondaryMenuWrap ${this.state.mobileSecondaryMenu ? 'open' : ''}`}>
          <span className="sidebarMenuBtn" onClick={mobileSecondaryMenuHideShow}>
            <ToggleMenuIcon className="openMenuIcon" />
            <ToggleCloseMenuIcon className="closeMenuIcon" />
          </span>
          <div className="fixedSidebar sidebarFixed inbox">
            <Scrollbars style={{ height: "100%" }}>
              <div className="innerSidebarWrap">
                <Card className="uni-left-card">
                  <CardBody>
                    <ListGroup className="left-list">
                      <ListGroupItem
                        className={
                          this.props.location.pathname === "/inbox-compose"
                            ? "composeBtn active"
                            : "composeBtn"
                        }
                      >
                        <Link to="inbox-compose">
                          <span className="iconBox">
                            <ComposeIcon className="icon" />
                          </span>
                          <span>Compose</span>
                        </Link>
                      </ListGroupItem>
                    </ListGroup>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/inbox" ||
                          this.props.location.pathname === "/Inbox"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/inbox">
                        <span className="iconBox">
                          <InboxIcon className="icon stroke" />
                        </span>
                        <span>Inbox</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/sentInbox" ||
                          this.props.location.pathname === "/Sent"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/sentInbox">
                        <span className="iconBox">
                          <SentIcon className="icon stroke" />
                        </span>
                        <span>Sent Mail</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/draftsInbox" ||
                          this.props.location.pathname === "/Draft"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/draftsInbox">
                        <span className="iconBox">
                          <DraftIcon className="icon stroke" />
                        </span>
                        <span>Drafts</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/deleteInbox" ||
                          this.props.location.pathname === "/Deleted"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/deleteInbox">
                        <span className="iconBox">
                          <DeleteIcon className="icon stroke" />
                        </span>
                        <span>Deleted</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        this.props.location.pathname === "/starredInbox" ||
                          this.props.location.pathname === "/Starred"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/starredInbox">
                        <span className="iconBox">
                          <StarredIcon className="icon stroke" />
                        </span>
                        <span>Starred</span>
                      </Link>
                    </ListGroupItem>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        currentUrl.includes("hiring-request-client") ||
                          currentUrl.includes("hiring-request-me") ||
                          this.state.submenuOpen === "hiring-request"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        this.handleCollapseMenu("hiring-request");
                      }}
                    >
                      <div>
                        {/* <span className="iconBox">
                          <ChatIcon className="icon" />
                        </span>{" "} */}
                        <span>HIRING REQUEST</span>
                      </div>
                      <DropDownIcon className="optionIcon" />
                      {(currentUrl.includes("hiring-request-client") ||
                        currentUrl.includes("hiring-request-me") ||
                        currentUrl.includes("hiring-request-declined") ||
                        this.state.submenuOpen === "hiring-request") && (
                          <ListGroup className="left-list submenu">
                            <ListGroupItem
                              className={
                                currentUrl.includes("hiring-request-client")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/hiring-request-client">
                                {/* <span className="iconBox">
                                  <StarredIcon className="icon stroke" />
                                </span> */}
                                <span>Pending from client</span>
                              </Link>
                            </ListGroupItem>
                            <ListGroupItem
                              className={
                                currentUrl.includes("hiring-request-me")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/hiring-request-me">
                                {/* <span className="iconBox">
                                  <DeleteIcon className="icon stroke" />
                                </span> */}
                                <span>Pending from me</span>
                              </Link>
                            </ListGroupItem>
                            <ListGroupItem
                              className={
                                currentUrl.includes("hiring-request-declined")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/hiring-request-declined">
                                {/* <span className="iconBox">
                                  <DeleteIcon className="icon stroke" />
                                </span> */}
                                <span>Declined</span>
                              </Link>
                            </ListGroupItem>
                          </ListGroup>
                        )}
                    </ListGroupItem>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        currentUrl.includes("meetings-pending-invites") ||
                          currentUrl.includes("upcoming-meetings") ||
                          currentUrl.includes("meetings-drafts") ||
                          this.state.submenuOpen === "meetings"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        this.handleCollapseMenu("meetings");
                      }}
                    >
                      <div>
                        <span className="iconBox">
                          <MeetingIcon className="icon stroke" />
                        </span>{" "}
                        <span>Meetings</span>
                      </div>
                      <DropDownIcon className="optionIcon" />
                      {(currentUrl.includes("meetings-pending-invites") ||
                        currentUrl.includes("upcoming-meetings") ||
                        currentUrl.includes("meetings-drafts") ||
                        this.state.submenuOpen === "meetings") && (
                          <ListGroup className="left-list submenu">
                            <ListGroupItem
                              className={
                                currentUrl.includes("meetings-pending-invites")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/meetings-pending-invites">
                                <span className="iconBox">
                                  <PendingIcon className="icon" />
                                </span>
                                <span>Pending Invites</span>
                              </Link>
                            </ListGroupItem>
                            <ListGroupItem
                              className={
                                currentUrl.includes("upcoming-meetings")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/upcoming-meetings">
                                <span className="iconBox">
                                  <UpcomingIcon className="icon" />
                                </span>
                                <span>Upcoming</span>
                              </Link>
                            </ListGroupItem>
                            <ListGroupItem
                              className={
                                currentUrl.includes("meetings-drafts")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/meetings-drafts">
                                <span className="iconBox">
                                  <DraftIcon className="icon stroke" />
                                </span>
                                <span>Drafts</span>
                              </Link>
                            </ListGroupItem>
                          </ListGroup>
                        )}
                    </ListGroupItem>

                    <ListGroupItem className="seprator">
                      <hr className="menuSep" />
                    </ListGroupItem>

                    <ListGroupItem
                      className={
                        currentUrl.includes("chats") ||
                          currentUrl.includes("starred_chats") ||
                          currentUrl.includes("delete_chats") ||
                          this.state.submenuOpen === "chats"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        this.handleCollapseMenu("chats");
                      }}
                    >
                      <div>
                        <Link to="/chats">
                          <span className="iconBox">
                            <ChatIcon className="icon" />
                          </span>{" "}
                          <span>Chats</span>
                        </Link>
                      </div>
                      <DropDownIcon className="optionIcon" />
                      {(currentUrl.includes("chats") ||
                        currentUrl.includes("starred_chats") ||
                        currentUrl.includes("delete_chats") ||
                        this.state.submenuOpen === "chats") && (
                          <ListGroup className="left-list submenu">
                            <ListGroupItem
                              className={
                                currentUrl.includes("starred_chats")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/starred_chats">
                                <span className="iconBox">
                                  <StarredIcon className="icon stroke" />
                                </span>
                                <span>Starred</span>
                              </Link>
                            </ListGroupItem>
                            <ListGroupItem
                              className={
                                currentUrl.includes("delete_chats")
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/delete_chats">
                                <span className="iconBox">
                                  <DeleteIcon className="icon stroke" />
                                </span>
                                <span>Deleted</span>
                              </Link>
                            </ListGroupItem>
                          </ListGroup>
                        )}
                    </ListGroupItem>

                  </CardBody>
                </Card>
              </div>
            </Scrollbars>
          </div>
        </div>


        {/* <Button color={this.props.location.pathname === "/inbox-compose" ? "compose active" : "compose"} onClick={() => { this.props.history.push("/inbox-compose") }}>
          <img src={ComposeIcon} alt="" /> compose
        </Button>
        <div className="menuSep"></div> */}
        {/* <ul className="inboxMenuList"> */}
        {/* <li>
            <Link
              to="/inbox"
              className={
                (this.props.location.pathname.includes('/inbox')
                  || this.props.location.pathname.includes('/Inbox'))
                  && (!this.props.location.pathname.includes('inbox-compose')) ? "active" : ""
              }
            >
              <img
                src={
                  (this.props.location.pathname.includes('/inbox')
                    || this.props.location.pathname.includes('/Inbox'))
                    && (!this.props.location.pathname.includes('inbox-compose'))
                    ? InboxActiveIcon
                    : InboxIcon
                }
                alt=""
              />
              Inbox
            </Link>
          </li> */}
        {/* <li>
            <Link
              to="/sentInbox"
              className={
                this.props.location.pathname.includes('/sentInbox')
                  || this.props.location.pathname.includes('/Sent')
                  ? "active"
                  : ""
              }
            >
              <img
                src={
                  this.props.location.pathname.includes('/sentInbox')
                    || this.props.location.pathname.includes('/Sent')
                    ? SentActiveIcon
                    : SentIcon
                }
                alt=""
              />
              Sent Mail
            </Link>
          </li> */}
        {/* <li>
            <Link
              to="/draftsInbox"
              className={
                this.props.location.pathname === "/draftsInbox"
                  ? "active"
                  : ""
              }
            >
              <DraftIcon width="20px" height="20px" className="svgIconStroke" />
              Drafts
            </Link>
          </li> */}
        {/* <li>
            <Link
              to="/deleteInbox"
              className={
                this.props.location.pathname.includes('/deleteInbox')
                  || this.props.location.pathname.includes('/Deleted')
                  ? "active"
                  : ""
              }
            >
              <img
                src={
                  this.props.location.pathname.includes('/deleteInbox')
                    || this.props.location.pathname.includes('/Deleted')
                    ? DeleteActiveIcon
                    : DeleteIcon
                }
                alt=""
              />
              Deleted
            </Link>
          </li> */}
        {/* <li>
            <Link
              to="/starredInbox"
              className={
                this.props.location.pathname === "/starredInbox"
                  ? "active"
                  : ""
              }
            >
              <img
                src={
                  this.props.location.pathname === "/starredInbox"
                    ? StarredActiveIcon
                    : StarredIcon
                }
                alt=""
              />
              Starred
            </Link>
          </li> */}
        {/* </ul> */}

        {/* <div className="menuSep"></div> */}
        {/* <Button
          color="meetings"
          id="hiringRequest"
          style={{ marginBottom: "1rem" }}
          onClick={() => {
            this.setState({
              hiringRequestOpen: !this.state.hiringRequestOpen,
              meetingsOpen: false
            })
          }}
        >
          HIRING REQUEST
          <img src={CollapseIcon} alt="" className={
            this.state.hiringRequestOpen ?
              `collapseIcon active` :
              `collapseIcon`
          } />
        </Button>
        <Collapse
          isOpen={this.state.hiringRequestOpen}
        >
          <ul className="inboxMenuList">
            <li>
              <Link
                to="/hiring-request-client"
                className={`main ${this.props.location.pathname === "/hiring-request-client"
                  ? "active"
                  : ""
                  }`}
              >
                Pending from client
              </Link>
            </li>
            <li>
              <Link
                to="/hiring-request-me"
                className={`main ${this.props.location.pathname === "/hiring-request-me"
                  ? "active"
                  : ""
                  }`}
              >
                Pending from me
              </Link>
            </li>
          </ul>
        </Collapse>

        <div className="menuSep"></div> */}
        {/* <Button
          color="meetings"
          id="Meetings"
          style={{ marginBottom: "1rem" }}
          onClick={() => {
            this.setState({
              meetingsOpen: !this.state.meetingsOpen,
              hiringRequestOpen: false
            })
          }}
        >
          <img src={MeetingIcon} alt="" />
          Meetings
          <img src={CollapseIcon} alt="" className={
            this.state.meetingsOpen ?
              `collapseIcon active` :
              `collapseIcon`
          } />
        </Button>
        <Collapse
          isOpen={this.state.meetingsOpen}
        >
          <ul className="inboxMenuList">
            <li>
              <Link
                to="/meetings-pending-invites"
                className={`main ${this.props.location.pathname === "/meetings-pending-invites"
                  ? "active"
                  : ""
                  }`}
              >
                <PendingIcon width="20px" height="20px" className="svgIcon" />
                Pending Invites
              </Link>
            </li>
            <li>
              <Link
                to="/upcoming-meetings"
                className={`main ${this.props.location.pathname === "/upcoming-meetings"
                  ? "active"
                  : ""
                  }`}
              >
                <UpcomingIcon width="20px" height="20px" className="svgIcon" />
                Upcoming
              </Link>
            </li>
            <li>
              <Link
                to="/meetings-drafts"
                className={`main ${this.props.location.pathname === "/meetings-drafts"
                  ? "active"
                  : ""
                  }`}
              >
                <DraftIcon width="20px" height="20px" className="svgIconStroke" />
                Drafts
              </Link>
            </li>
          </ul>
        </Collapse>
        <div className="menuSep"></div> */}
        {/* <ul className="inboxMenuList">
					<li>
						<Link
							to="/chats"
							className={`main ${this.props.location.pathname === "/chats" ? "active" : ""
								}`}
						>
							<ChatIcon width="20px" height="20px" className="svgIcon" />
							Chats
						</Link>
					</li>
				</ul> */}
        {/* <ul className="inboxMenuList">
          <li
            className="d-flex justify-space-between align-items-center"
            onClick={() => {
              this.setState({
                chatsOpen: !this.state.chatsOpen,
              });
            }}
          >
            <Link
              to="/chats"
              className={`main ${this.props.location.pathname === "/chats"
                ? "active"
                : ""
                }`}
              style={{ width: "80%" }}
            >
              <ChatIcon
                width="20px"
                height="20px"
                className="svgIcon mr-10"
              />
              Chats
            </Link>
            <img
              src={CollapseIcon}
              alt=""
              className={
                this.state.meetingsOpen
                  ? `collapseIcon active`
                  : `collapseIcon`
              }
            />
          </li>
        </ul>
        <Collapse isOpen={this.state.chatsOpen}>
          <ul className="inboxMenuList">
            <li>
              <Link
                to="/starred_chats"
                className={`main ${this.props.location.pathname ===
                  "/starred_chats"
                  ? "active"
                  : ""
                  }`}
              >
                <img
                  src={
                    this.props.location.pathname ===
                      "/starred_chats"
                      ? StarredActiveIcon
                      : StarredIcon
                  }
                  alt=""
                />
                Starred
              </Link>
            </li>
            <li>
              <Link
                to="/delete_chats"
                className={`main ${this.props.location.pathname ===
                  "/delete_chats"
                  ? "active"
                  : ""
                  }`}
              >
                <img
                  src={
                    this.props.location.pathname ===
                      "/delete_chats"
                      ? DeleteActiveIcon
                      : DeleteIcon
                  }
                  alt=""
                />
                Deleted
              </Link>
            </li>
          </ul>
        </Collapse> */}
      </>
    );
  }
}
export default withRouter(LeftInboxMenu);
