export const EMAIL_REGEX =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^.{9,14}$/;
export const CODE_REGEX = /^\d{2}$/;
export const POST_CODE_REGEX = /^.{4,6}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const NUMBER_ONLY_REGEX = /^[0-9\b]+$/;
export const TEXT_ONLY_REGEX = /^[A-Za-z]/;
export const NUMBER_REGEX = /^[0-9]+$/
export const PASS_MAX_REGEX = /^.{15,20}$/
export const ALN_REGEX = /^[a-zA-Z0-9]+$/
export const ALLOWED_FILE_EXTENSION_REGEX = /(\.jpg|\.pdf|\.png)$/i
export const PAS_MAX_REG = /^.{4,20}$/
export const ZIP_MAX_REGEX = /^.{3,8}$/
export const MIN_MAX_REGEX = /^.{9,14}$/
export const NAME_REGEX = /^[a-zA-Z\s]+$/
export const PHONE_NUMBER_REGEX = /^\d+$/
export const ESTIMATED_COST_REGEX = /^[0-9,]+$/
export const GRADE_CALC_REGEX = /^[0-9]+(\.[0-9]?)?$/

export const OTHERCARDS = [ /[1-9]/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
export const AMERICANEXPRESS = [ /[1-9]/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/];
export const EXPIRYDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const CCV = [/[0-9]/, /\d/, /\d/, /\d/];
