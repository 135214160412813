import {
  SAVE_DEPARTMENT,
  SAVE_DEPARTMENT_ERROR,
  SAVE_DEPARTMENT_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS,
  VIEW_DEPARTMENT,
  VIEW_DEPARTMENT_SUCCESS,
  VIEW_DEPARTMENT_ERROR,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_ERROR,
  GET_EDUCATION_LIST,
  GET_EDUCATION_LIST_SUCCESS,
  GET_EDUCATION_LIST_ERROR,
  GET_REMAIN_CAMPUS_LIST,
  GET_REMAIN_CAMPUS_LIST_SUCCESS,
  GET_REMAIN_CAMPUS_LIST_ERROR,
  CREATE_PROGRAM,
  CREATE_PROGRAM_ERROR,
  CREATE_PROGRAM_SUCCESS,
  GET_LANGUAGE_EXAM_LIST,
  GET_LANGUAGE_EXAM_LIST_SUCCESS,
  GET_LANGUAGE_EXAM_LIST_ERROR,
  GET_ADDITIONAL_EXAM_LIST,
  GET_ADDITIONAL_EXAM_LIST_SUCCESS,
  GET_ADDITIONAL_EXAM_LIST_ERROR,
  GET_SEARCH_PROGRAM_LIST,
  GET_SEARCH_PROGRAM_LIST_SUCCESS,
  GET_SEARCH_PROGRAM_LIST_ERROR,
  DELETE_PROGRAM,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_ERROR,
  GET_PROGRAMS,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_ERROR,
  GET_PROGRAM_DETAILS,
  GET_PROGRAM_DETAILS_SUCCESS,
  GET_PROGRAM_DETAILS_ERROR,
  UPDATE_PROGRAM,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_ERROR,
  CONFIRM_DEPARTMENT,
  CONFIRM_DEPARTMENT_SUCCESS,
  CONFIRM_DEPARTMENT_ERROR,
  CONFIRM_PROGRAM,
  CONFIRM_PROGRAM_SUCCESS,
  CONFIRM_PROGRAM_ERROR,
  DELETE_ALL_PROGRAM_DEPARTMENT,
  DELETE_ALL_PROGRAM_DEPARTMENT_SUCCESS,
  DELETE_ALL_PROGRAM_DEPARTMENT_ERROR,
  GET_UNIVERSITY_OVERVIEW,
  GET_UNIVERSITY_OVERVIEW_SUCCESS,
  GET_UNIVERSITY_OVERVIEW_ERROR
} from "../constants";

export function saveDepartmentAction(session, resolve, reject) {
  return {
    type: SAVE_DEPARTMENT,
    payload: session,
    resolve,
    reject
  };
}

export function saveDepartmentSuccess(data) {
  return {
    type: SAVE_DEPARTMENT_SUCCESS,
    payload: data
  };
}

export function saveDepartmentError(error) {
  return {
    type: SAVE_DEPARTMENT_ERROR,
    payload: error
  };
}

export function getDepartmentsAction(session, resolve, reject) {
  return {
    type: GET_DEPARTMENTS,
    payload: session,
    resolve,
    reject
  };
}

export function getDepartmentsSuccess(data) {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    payload: data
  };
}

export function getDepartmentsError(error) {
  return {
    type: GET_DEPARTMENTS_ERROR,
    payload: error
  };
}

export function viewDepartmentAction(session, resolve, reject) {
  return {
    type: VIEW_DEPARTMENT,
    payload: session,
    resolve,
    reject
  };
}

export function viewDepartmentSuccess(data) {
  return {
    type: VIEW_DEPARTMENT_SUCCESS,
    payload: data
  };
}

export function viewDepartmentError(error) {
  return {
    type: VIEW_DEPARTMENT_ERROR,
    payload: error
  };
}


export function updateDepartmentAction(session, resolve, reject) {
  return {
    type: UPDATE_DEPARTMENT,
    payload: session,
    resolve,
    reject
  };
}

export function updateDepartmentSuccess(data) {
  return {
    type: UPDATE_DEPARTMENT_SUCCESS,
    payload: data
  };
}

export function updateDepartmentError(error) {
  return {
    type: UPDATE_DEPARTMENT_ERROR,
    payload: error
  };
}


export function getEducationListAction(session, resolve, reject) {
  return {
    type: GET_EDUCATION_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getEducationListSuccess(data) {
  return {
    type: GET_EDUCATION_LIST_SUCCESS,
    payload: data
  };
}

export function getEducationListError(error) {
  return {
    type: GET_EDUCATION_LIST_ERROR,
    payload: error
  };
}

export function getRemainCampusListAction(session, resolve, reject) {
  return {
    type: GET_REMAIN_CAMPUS_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getRemainCampusListSuccess(data) {
  return {
    type: GET_REMAIN_CAMPUS_LIST_SUCCESS,
    payload: data
  };
}

export function getRemainCampusListError(error) {
  return {
    type: GET_REMAIN_CAMPUS_LIST_ERROR,
    payload: error
  };
}


export function createProgramAction(session, resolve, reject) {
  return {
    type: CREATE_PROGRAM,
    payload: session,
    resolve,
    reject
  };
}

export function createProgramSuccess(data) {
  return {
    type: CREATE_PROGRAM_SUCCESS,
    payload: data
  };
}

export function createProgramError(error) {
  return {
    type: CREATE_PROGRAM_ERROR,
    payload: error
  };
}

export function getLanguageExamListAction(session, resolve, reject) {
  return {
    type: GET_LANGUAGE_EXAM_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getLanguageExamListSuccess(data) {
  return {
    type: GET_LANGUAGE_EXAM_LIST_SUCCESS,
    payload: data
  };
}

export function getLanguageExamListError(error) {
  return {
    type: GET_LANGUAGE_EXAM_LIST_ERROR,
    payload: error
  };
}

export function getAdditionalExamListAction(session, resolve, reject) {
  return {
    type: GET_ADDITIONAL_EXAM_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getAdditionalExamListSuccess(data) {
  return {
    type: GET_ADDITIONAL_EXAM_LIST_SUCCESS,
    payload: data
  };
}

export function getAdditionalExamListError(error) {
  return {
    type: GET_ADDITIONAL_EXAM_LIST_ERROR,
    payload: error
  };
}

export function getSearchProgramListAction(session, resolve, reject) {
  return {
    type: GET_SEARCH_PROGRAM_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getSearchProgramListSuccess(data) {
  return {
    type: GET_SEARCH_PROGRAM_LIST_SUCCESS,
    payload: data
  };
}

export function getSearchProgramListError(error) {
  return {
    type: GET_SEARCH_PROGRAM_LIST_ERROR,
    payload: error
  };
}


export function deleteProgramAction(session, resolve, reject) {
  return {
    type: DELETE_PROGRAM,
    payload: session,
    resolve,
    reject
  };
}

export function deleteProgramSuccess(data) {
  return {
    type: DELETE_PROGRAM_SUCCESS,
    payload: data
  };
}

export function deleteProgramError(error) {
  return {
    type: DELETE_PROGRAM_ERROR,
    payload: error
  };
}

export function getProgramsAction(session, resolve, reject) {
  return {
    type: GET_PROGRAMS,
    payload: session,
    resolve,
    reject
  };
}

export function getProgramsSuccess(data) {
  return {
    type: GET_PROGRAMS_SUCCESS,
    payload: data
  };
}

export function getProgramsError(error) {
  return {
    type: GET_PROGRAMS_ERROR,
    payload: error
  };
}


export function getProgramDetailsAction(session, resolve, reject) {
  return {
    type: GET_PROGRAM_DETAILS,
    payload: session,
    resolve,
    reject
  };
}

export function getProgramDetailsSuccess(data) {
  return {
    type: GET_PROGRAM_DETAILS_SUCCESS,
    payload: data
  };
}

export function getProgramDetailsError(error) {
  return {
    type: GET_PROGRAM_DETAILS_ERROR,
    payload: error
  };
}

export function updateProgramAction(session, resolve, reject) {
  return {
    type: UPDATE_PROGRAM,
    payload: session,
    resolve,
    reject
  };
}

export function updateProgramSuccess(data) {
  return {
    type: UPDATE_PROGRAM_SUCCESS,
    payload: data
  };
}

export function updateProgramError(error) {
  return {
    type: UPDATE_PROGRAM_ERROR,
    payload: error
  };
}

export function confirmDepartmentAction(session, resolve, reject) {
  return {
    type: CONFIRM_DEPARTMENT,
    payload: session,
    resolve,
    reject
  };
}

export function confirmDepartmentSuccess(data) {
  return {
    type: CONFIRM_DEPARTMENT_SUCCESS,
    payload: data
  };
}

export function confirmDepartmentError(error) {
  return {
    type: CONFIRM_DEPARTMENT_ERROR,
    payload: error
  };
}

export function confirmProgramAction(session, resolve, reject) {
  return {
    type: CONFIRM_PROGRAM,
    payload: session,
    resolve,
    reject
  };
}

export function confirmProgramSuccess(data) {
  return {
    type: CONFIRM_PROGRAM_SUCCESS,
    payload: data
  };
}

export function confirmProgramError(error) {
  return {
    type: CONFIRM_PROGRAM_ERROR,
    payload: error
  };
}

export function deleteAllProgramDepartmentAction(session, resolve, reject) {
  return {
    type: DELETE_ALL_PROGRAM_DEPARTMENT,
    payload: session,
    resolve,
    reject
  };
}

export function deleteAllProgramDepartmentSuccess(data) {
  return {
    type: DELETE_ALL_PROGRAM_DEPARTMENT_SUCCESS,
    payload: data
  };
}

export function deleteAllProgramDepartmentError(error) {
  return {
    type: DELETE_ALL_PROGRAM_DEPARTMENT_ERROR,
    payload: error
  };
}

export function getUniversityOverviewAction(session, resolve, reject) {
  return {
    type: GET_UNIVERSITY_OVERVIEW,
    payload: session,
    resolve,
    reject
  };
}

export function getUniversityOverviewSuccess(data) {
  return {
    type: GET_UNIVERSITY_OVERVIEW_SUCCESS,
    payload: data
  };
}

export function getUniversityOverviewError(error) {
  return {
    type: GET_UNIVERSITY_OVERVIEW_ERROR,
    payload: error
  };
}
