import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledPopover, PopoverBody } from 'reactstrap';
import "./emailTemplate.scss";

import { ReactComponent as StaffAddedIcon } from "../../../../assets/img/staff_added_icon.svg";
import { ReactComponent as DownArrowIcon } from "../../../../assets/img/arrow_down_gray_icon.svg";
import { ReactComponent as GreenTickIcon } from "../../../../assets/img/icons/tick_circle_green_icon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/img/close-btn.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/img/icons/warning_icon.svg";

export default function TerminateContract(props) {
  //console.log("data", props.data);
  const [openModalAdded, setOpenModalAdded] = useState(false);
  const [openModalAccept, setOpenModalAccept] = useState(false);
  const [openModalDecline, setOpenModalDecline] = useState(false);
  const [selectedAssign, setSelectedAssign] = useState({});

  const contactList = props.data && props.data.state.contactList;

  // const handleChangeContactList = (e) => {
  //   // setSelectedAssign(e.target.value)
  //   console.log("contacts", e);
  // }

  return (
    <>
      <div className="emailContent">
        <div className="header bigFont">
          <p><strong>Alex Smith</strong> has terminated your contract</p>
        </div>
        <div className="contentBox mt-30 p-25">
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Formee Express ID</th>
                <th>Date Client Added</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Alex Smith</td>
                <td>#FX12345</td>
                <td>11/12/2020</td>
                <td><span className="terminated">Terminated</span></td>
              </tr>
            </tbody>
          </table>
        </div>

        <strong className="reasonText">Reason for Terminating Contract</strong>
        <div className="contentBox p-25 m-0 text-left">
          Task completed successfully.
        </div>

        <strong className="reasonText">Reason for Terminating Contract</strong>
        <p className="reportText"><WarningIcon /> <u onClick={() => { setOpenModalDecline(true) }}>Report to Admin</u></p>

        <div className="footer mt-50">
          <p className="mb-20">Unexpected email? To contact Formee Express team, send email to <strong><a href="mailto:admin@formeeexpress.com">admin@formeeexpress.com</a></strong></p>
          <p><strong>Need Support?</strong></p>
          <p>Feel free to email us if you have any questions, comments or suggestions.<br />
            We’ll be happy to resolve your issues.</p>
        </div>
      </div>


      {/* Decline */}
      <Modal className="primaryModal noRadius viewAdded" isOpen={openModalDecline}>
        <ModalBody>
          <CloseIcon className="closeIcon" onClick={() => { setOpenModalDecline(false) }} />
          <h2 className="title text-left mb-20">Report to Admin</h2>
          <FormGroup>
            <Label>Type your issue</Label>
            <Input type="textarea" placeholder="..." />
          </FormGroup>
          <Button className="w100"
            onClick={() => { setOpenModalDecline(false) }}
          >Send</Button>
        </ModalBody>
      </Modal>
    </>
  );
}