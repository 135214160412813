import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  SAVE_SIDE_PROFILE,
  SAVE_SIDE_PROFILE_SUCCESS,
  SAVE_SIDE_PROFILE_ERROR,
  GET_UNIVERSITY_ABOUT,
  GET_PROFILE,
  UPDATE_PROFILE,
  SAVE_INTAKE_DATES,
  GET_INTAKE_DATES,
  SAVE_APPLICATION_FORM,
  GET_APPLICATION_FORM,
  GET_PROFILE_STATUS,
  GET_REVIEWS,
  GET_REVIEW_COMMENTS,
  REPLY_TO_REVIEW,
  DELETE_REVIEW_COMMENT,
  GET_REVIEW_REPORT_REASONS,
  REPORT_REVIEW,
  SEND_PUBLISH_REQUEST,
  GET_PUBLISH_REQUEST,
  GET_AGREEMENT_DOCUMENTS,
  ACCEPT_MEETING_REQUEST,
  GET_LOGIN_LOGS
} from "../constants";
import {
  baseUrl,
  DELETE_REVIEW_COMMENT_API,
  GET_APPLICATION_FORM_API,
  GET_INTAKE_DATES_API,
  GET_PROFILE_API,
  GET_PROFILE_STATUS_API,
  ACCEPT_MEETING_REQUEST_API,
  GET_REVIEWS_API,
  GET_REVIEW_COMMENTS_API,
  GET_REVIEW_REPORT_REASONS_API,
  REPLY_TO_REVIEW_API,
  AGENT_PUBLISH_REQUEST_API,
  REPORT_REVIEW_API,
  SAVE_APPLICATION_FORM_API,
  SAVE_INTAKE_DATES_API,
  SIDE_PROFILE,
  UNIVERSITY_ABOUT,
  LOGIN_LOGS,
  AGREEMENT_DOCUMENTS,
  UPDATE_PROFILE_API
} from "../../Api";
import { toast } from "react-toastify";
// import axios from "axios";
import axios from "../../Api/axios"
import { 
  saveSideProfileSuccess,
  saveSideProfileError,
  getLoginLogsSuccess,
  getLoginLogsError,
  getUniversityAboutSuccess,
  getUniversityAboutError,
  getProfileSuccess,
  getProfileError,
  updateProfileSuccess,
  updateProfileError,
  saveIntakeDatesSuccess,
  saveIntakeDatesError,
  getIntakeDatesSuccess,
  getIntakeDatesError,
  saveApplicationFormSuccess,
  saveApplicationFormError,
  getApplicationFormSuccess,
  getApplicationFormError,
  getProfileStatusSuccess,
  getProfileStatusError,
  showHideLoadingAction,
  handleSuccessResponse,
  handleErrorResponse,
  getReviewsSuccess,
  getReviewsError,
  getReviewCommentsError,
  getReviewCommentsSuccess,
  replyToReviewSuccess,
  replyToReviewError,
  deleteReviewCommentSuccess,
  deleteReviewCommentError,
  getReviewReportReasonsSuccess,
  getReviewReportReasonsError,
  getAgreementDocumentsSuccess,
  getAgreementDocumentsError,
  sendPublishRequestSuccess,
  sendPublishRequestError,
  getPublishRequestSuccess,
  getPublishRequestError,
  reportReviewSuccess,
  reportReviewError
} from '../actions';

function saveSideProfileApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SIDE_PROFILE,
    data: params
  });
}

function* saveSideProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(saveSideProfileApi, payload);
    yield put(saveSideProfileSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data)
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response)
    yield put(saveSideProfileError(e))
    if (reject) reject(e)
  }
}

export function* saveSideProfileActionWatcher() {
  yield takeLatest(SAVE_SIDE_PROFILE, saveSideProfile)
}

function getUniversityAboutApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + UNIVERSITY_ABOUT + "/" + params.agent_id,
    data: params
  });
}

function* getUnivesityAbout(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getUniversityAboutApi, payload);
    yield put(getUniversityAboutSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    yield put(getUniversityAboutError(e))
    handleErrorResponse(e.response);
    if (reject) reject()
  }
}

export function* getUniversityAboutActionWatcher() {
  yield takeLatest(GET_UNIVERSITY_ABOUT, getUnivesityAbout)
}

function getLoginLogsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + LOGIN_LOGS + "?id=" + params.agent_id + "&date=" + params.date + "&per_page="+ params.limit+ "&page="+ params.page,
  });
}

function* getLoginLogs(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getLoginLogsApi,payload);
    yield put(getLoginLogsSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    yield put(getLoginLogsError(e))
    handleErrorResponse(e.response);
    if (reject) reject()
  }
}

export function* getLoginLogsActionWatcher() {
  yield takeLatest(GET_LOGIN_LOGS, getLoginLogs)
}

function sendPublishRequestApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + AGENT_PUBLISH_REQUEST_API
  });
}

function* sendPublishRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(sendPublishRequestApi, payload);
    yield put(sendPublishRequestSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(sendPublishRequestError(e))
    if (reject) reject(e)
  }
}

export function* sendPublishRequestWatcher() {
  yield takeLatest(SEND_PUBLISH_REQUEST, sendPublishRequest)
}

function getPublishRequestApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + AGENT_PUBLISH_REQUEST_API,
  });
}

function* getPublishRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getPublishRequestApi, payload);
    yield put(getPublishRequestSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getPublishRequestError(e))
    if (reject) reject(e)
  }
}

export function* getPublishRequestWatcher() {
  yield takeLatest(GET_PUBLISH_REQUEST, getPublishRequest)
}

function getAgreementDocumentsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + AGREEMENT_DOCUMENTS,
  });
}

function* getAgreementDocuments(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getAgreementDocumentsApi,payload);
    yield put(getAgreementDocumentsSuccess(response.data));
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(getAgreementDocumentsError(e))
    if (reject) reject()
  }
}

export function* getAgreementDocumentsActionWatcher() {
  yield takeLatest(GET_AGREEMENT_DOCUMENTS, getAgreementDocuments)
}

function getProfileApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_PROFILE_API + "/" + params.agent_id,
    data: params
  });
}

function* getProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getProfileApi, payload);
    yield put(getProfileSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(getProfileError(e))
    if (reject) reject(e)
  }
}

export function* getProfileActionWatcher() {
  yield takeLatest(GET_PROFILE, getProfile)
}



function acceptMeetingRequestApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + ACCEPT_MEETING_REQUEST_API,
    data: params
  });
}

function* acceptMeetingRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(acceptMeetingRequestApi, payload);
    if (resolve) resolve(response.data)
  } catch (e) {
    if (reject) reject(e)
  }
}

export function* acceptMeetingRequestWatcher() {
  yield takeLatest(ACCEPT_MEETING_REQUEST, acceptMeetingRequest)
}


function updateProfileApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + UPDATE_PROFILE_API,
    data: params
  });
}

function* updateProfile(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(updateProfileApi, payload);
    yield put(updateProfileSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data)
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(updateProfileError(e))
    if (reject) reject(e)
  }
}

export function* updateProfileActionWatcher() {
  yield takeLatest(UPDATE_PROFILE, updateProfile)
}

function saveIntakeDatesApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SAVE_INTAKE_DATES_API,
    data: params
  });
}

function* saveIntakeDates(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(saveIntakeDatesApi, payload);
    yield put(saveIntakeDatesSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data)
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(saveIntakeDatesError(e))
    if (reject) reject(e)
  }
}

export function* saveIntakeDatesActionWatcher() {
  yield takeLatest(SAVE_INTAKE_DATES, saveIntakeDates)
}

function getIntakeDatesApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_INTAKE_DATES_API + "/" + params.universityId
  });
}

function* getIntakeDates(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getIntakeDatesApi, payload);
    yield put(getIntakeDatesSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getIntakeDatesError(e))
    if (reject) reject(e)
  }
}

export function* getIntakeDatesActionWatcher() {
  yield takeLatest(GET_INTAKE_DATES, getIntakeDates)
}

function saveApplicationFormApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SAVE_APPLICATION_FORM_API,
    data: params
  });
}

function* saveApplicationForm(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(saveApplicationFormApi, payload);
    yield put(saveApplicationFormSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(saveApplicationFormError(e))
    if (reject) reject(e)
  }
}

export function* saveApplicationFormActionWatcher() {
  yield takeLatest(SAVE_APPLICATION_FORM, saveApplicationForm)
}

function getApplicationFormApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_APPLICATION_FORM_API + "/" + params.id
  });
}

function* getApplicationForm(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getApplicationFormApi, payload);
    yield put(getApplicationFormSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getApplicationFormError(e))
    if (reject) reject(e)
  }
}

export function* getApplicationFormActionWatcher() {
  yield takeLatest(GET_APPLICATION_FORM, getApplicationForm)
}

function getProfileStatusApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_PROFILE_STATUS_API+"/"+params.agent_id
  });
}

function* getProfileStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getProfileStatusApi, payload);
    yield put(getProfileStatusSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    yield put(getProfileStatusError(e))
    if (reject) reject(e)
  }
}

export function* getProfileStatusWatcher() {
  yield takeLatest(GET_PROFILE_STATUS, getProfileStatus)
}

function getReviewsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_REVIEWS_API,
    params: params
  });
}

function* getReviewsStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getReviewsApi, payload);
    yield put(getReviewsSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    yield put(getReviewsError(e))
    if (reject) reject(e)
  }
}

export function* getReviewsWatcher() {
  yield takeLatest(GET_REVIEWS, getReviewsStatus)
}

function getReviewCommentsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_REVIEW_COMMENTS_API,
    params: params
  });
}

function* getReviewComments(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getReviewCommentsApi, payload);
    yield put(getReviewCommentsSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    yield put(getReviewCommentsError(e))
    if (reject) reject(e)
  }
}

export function* getReviewCommentsWatcher() {
  yield takeLatest(GET_REVIEW_COMMENTS, getReviewComments)
}

function replyToReviewApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + REPLY_TO_REVIEW_API,
    data: params
  });
}

function* replyToReview(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(replyToReviewApi, payload);
    handleSuccessResponse(response.data)
    yield put(replyToReviewSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(showHideLoadingAction(false));
    yield put(replyToReviewError(e))
    if (reject) reject(e)
  }
}

export function* replyToReviewWatcher() {
  yield takeLatest(REPLY_TO_REVIEW, replyToReview)
}

function deleteReviewCommentApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl + DELETE_REVIEW_COMMENT_API,
    data: params
  });
}

function* deleteReviewComment(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(deleteReviewCommentApi, payload);
    handleSuccessResponse(response.data)
    yield put(deleteReviewCommentSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(showHideLoadingAction(false));
    yield put(deleteReviewCommentError(e))
    if (reject) reject(e)
  }
}

export function* deleteReviewCommentWatcher() {
  yield takeLatest(DELETE_REVIEW_COMMENT, deleteReviewComment)
}

function getReviewReportReasonsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_REVIEW_REPORT_REASONS_API,
    params: params
  });
}

function* getReviewReportReasons(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getReviewReportReasonsApi, payload);
    handleSuccessResponse(response.data)
    yield put(getReviewReportReasonsSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(showHideLoadingAction(false));
    yield put(getReviewReportReasonsError(e))
    if (reject) reject(e)
  }
}

export function* getReviewReportReasonsWatcher() {
  yield takeLatest(GET_REVIEW_REPORT_REASONS, getReviewReportReasons)
}

function reportReviewApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + REPORT_REVIEW_API,
    params: params
  });
}

function* reportReview(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(reportReviewApi, payload);
    handleSuccessResponse(response.data)
    yield put(reportReviewSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(showHideLoadingAction(false));
    yield put(reportReviewError(e))
    if (reject) reject(e)
  }
}

export function* reportReviewWatcher() {
  yield takeLatest(REPORT_REVIEW, reportReview)
}