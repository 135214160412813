import { DELETE_NOTIFICATION, DELETE_NOTIFICATION_ERROR, DELETE_NOTIFICATION_SUCCESS, GET_NOTIFICATIONS_COUNT, GET_NOTIFICATIONS_COUNT_ERROR, GET_NOTIFICATIONS_COUNT_SUCCESS, GET_NOTIFICATION_LIST, GET_NOTIFICATION_LIST_ERROR, GET_NOTIFICATION_LIST_SUCCESS, MARK_READ, MARK_READ_ERROR, MARK_READ_SUCCESS } from "../constants";

export function getNotificationListAction(session, resolve, reject) {
  return {
    type: GET_NOTIFICATION_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getNotificationListSuccess(data) {
  return {
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload: data
  };
}

export function getNotificationListError(error) {
  return {
    type: GET_NOTIFICATION_LIST_ERROR,
    payload: error
  };
}

export function getNotificationsCountAction(session, resolve, reject) {
  return {
    type: GET_NOTIFICATIONS_COUNT,
    payload: session,
    resolve,
    reject
  };
}

export function getNotificationsCountSuccess(data) {
  return {
    type: GET_NOTIFICATIONS_COUNT_SUCCESS,
    payload: data
  };
}

export function getNotificationsCountError(error) {
  return {
    type: GET_NOTIFICATIONS_COUNT_ERROR,
    payload: error
  };
}

export function markReadAction(session, resolve, reject) {
  return {
    type: MARK_READ,
    payload: session,
    resolve,
    reject
  };
}

export function markReadSuccess(data) {
  return {
    type: MARK_READ_SUCCESS,
    payload: data
  };
}

export function markReadError(error) {
  return {
    type: MARK_READ_ERROR,
    payload: error
  };
}

export function deleteAction(session, resolve, reject) {
  return {
    type: DELETE_NOTIFICATION,
    payload: session,
    resolve,
    reject
  };
}

export function deleteSuccess(data) {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: data
  };
}

export function deleteError(error) {
  return {
    type: DELETE_NOTIFICATION_ERROR,
    payload: error
  };
}