import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  baseUrl, 
  GET_ME_API, 
  GET_USER_PERMISSIONS_API, 
  LOGIN_API, 
  UPDATE_PASSWORD_API
} from '../../Api';
import { 
  loginError, 
  loginSuccess, 
  updatePasswordSuccess, 
  updatePasswordError, 
  getMeSuccess,
  getMeError,
  getUserPermissionSuccess,
  getUserPermissionError
} from '../actions';
import { GET_ME, GET_USER_PERMISSIONS, LOGIN, UPDATE_PASSWORD } from '../constants';
import axios from "axios";
import authentication from "../../Api/axios"
import { handleErrorResponse, handleSuccessResponse, showHideLoadingAction } from '../actions';

function loginApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+LOGIN_API,
    data: params
  });
}

function* login(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    let response = yield call(loginApi, payload);

    yield put(loginSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    console.log('errrrr', e)
    handleErrorResponse(e.response);
    yield put(loginError(e))
    if (reject) reject(e)
  }
}

export function* loginWatcher() {
  yield takeLatest(LOGIN, login)
}

function updatePasswordApi(params) {
  return authentication.request({
    method: "post",
    url: baseUrl+UPDATE_PASSWORD_API,
    data: params
  });
}

function* updatePassword(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(updatePasswordApi, payload);

    yield put(updatePasswordSuccess(response.data));
    yield put(showHideLoadingAction(false))
    handleSuccessResponse(response.data)
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(updatePasswordError(e))
    if (reject) reject(e)
  }
}

export function* updatePasswordWatcher() {
  yield takeLatest(UPDATE_PASSWORD, updatePassword)
}

function getMeApi(params) {
  let url = baseUrl+GET_ME_API
  if (params && params.id) {
    url += "/"+params.id
  }

  return authentication.request({
    method: "get",
    url,
    params: params
  });
}

function* getMe(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    console.log('log ingetme');
    yield put(showHideLoadingAction(true))
    let response = yield call(getMeApi, payload);

    yield put(getMeSuccess(response.data));
    console.log('log after action');
    yield put(showHideLoadingAction(false))
    handleSuccessResponse(response.data)
    if (resolve) resolve(response.data)
  } catch (e) {
    console.log('log ingetme err', e);
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(getMeError(e))
    if (reject) reject(e)
  }
}

export function* getMeWatcher() {
  yield takeLatest(GET_ME, getMe)
}

function getUserPermissionApi(params) {
  return authentication.request({
    method: "get",
    url: baseUrl+GET_USER_PERMISSIONS_API,
    params: params
  });
}

function* getUserPermission(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  
  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(getUserPermissionApi, payload);

    yield put(getUserPermissionSuccess(response.data));
    yield put(showHideLoadingAction(false))
    handleSuccessResponse(response.data)
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(getUserPermissionError(e))
    if (reject) reject(e)
  }
}

export function* getUserPermissionWatcher() {
  yield takeLatest(GET_USER_PERMISSIONS, getUserPermission)
}