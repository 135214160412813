import {
  GET_PROGRAM_COUNTRY_LIST,
  GET_PROGRAM_COUNTRY_LIST_SUCCESS,
  GET_PROGRAM_COUNTRY_LIST_ERROR,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  SHOW_HIDE_UPDATE_PASSWORD_MODAL,
  SHOW_HIDE_LOADING,
  GET_SECONDARY_MENU
} from "../constants";

import { ReactComponent as DashboardIcon } from "../../assets/img/square-icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/img/user-profile-icon.svg";
import { ReactComponent as StaffIcon } from "../../assets/img/user-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/img/calendar-black.svg";
import { ReactComponent as SettingIcon } from "../../assets/img/icons/settings.svg";
import { ReactComponent as LoginLogIcon } from "../../assets/img/icons/loginLogs.svg";

const initialState = {
  showPasswordModal: false,
  countryList: [],
  currencyList: [],
  showHideLoading: false,
  defaultHeader: {
    defaultTitle: "MY ACCOUNT",
    defaultSecondaryMenu: [
      {
        name: "Dashboard",
        path: "/agentdashboard",
        icon: DashboardIcon,
      },
      {
        name: "Profile",
        path: "/profile-management",
        icon: ProfileIcon,
      },
      {
        name: "Staff",
        path: "/staff",
        icon: StaffIcon,
      },
      {
        name: "Calendar",
        path: "/calendar",
        icon: CalendarIcon,
      },
      {
        name: "Account Settings",
        path: "/account-profile",
        icon: SettingIcon,
      },
      {
        name: "Login Logs",
        path: "/login-logs",
        icon: LoginLogIcon,
      }
    ]
  }
}

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRY_LIST:
      return { ...state };
    case GET_COUNTRY_LIST_SUCCESS:
      return { ...state, countryList: action.payload }
    case GET_PROGRAM_COUNTRY_LIST:
      return { ...state };
    case GET_PROGRAM_COUNTRY_LIST_SUCCESS:
      return { ...state, programCountryList: action.payload }
    case GET_CURRENCY_LIST:
      return { ...state };
    case GET_CURRENCY_LIST_SUCCESS:
      return { ...state, currencyList: action.payload }
    case SHOW_HIDE_UPDATE_PASSWORD_MODAL:
      return { ...state, showPasswordModal: action.payload }
    case SHOW_HIDE_LOADING:
      return {
        ...state,
        showHideLoading: action.payload
      }
    case GET_SECONDARY_MENU:
      return {
        ...state,
        defaultHeader: action.payload
      }
    default:
      return state;
  }
}
