import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR
} from "../constants";

const initialState = {
  dashboardData: null
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return { ...state };
    case GET_DASHBOARD_DATA_SUCCESS:
      return { ...state, dashboardData: action.payload }
    default:
      return state;
  }
}