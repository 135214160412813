import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
	SAVE_DEPARTMENT,
    GET_DEPARTMENTS,
	VIEW_DEPARTMENT,
	UPDATE_DEPARTMENT,
	GET_EDUCATION_LIST,
	GET_REMAIN_CAMPUS_LIST,
	CREATE_PROGRAM,
	GET_LANGUAGE_EXAM_LIST,
	GET_ADDITIONAL_EXAM_LIST,
	GET_SEARCH_PROGRAM_LIST,
	DELETE_PROGRAM,
    GET_PROGRAMS,
	GET_PROGRAM_DETAILS,
	UPDATE_PROGRAM,
	CONFIRM_DEPARTMENT,
	CONFIRM_PROGRAM,
	DELETE_ALL_PROGRAM_DEPARTMENT,
	GET_UNIVERSITY_OVERVIEW
} from "../constants";
import {
  baseUrl,
  GET_DEPARTMENTS_API,
  SAVE_DEPARTMENT_API,
  VIEW_DEPARTMENT_API,
  UPDATE_DEPARTMENT_API,
  GET_EDUCATION_LIST_API,
  GET_REMAIN_CAMPUS_LIST_API,
  CREATE_PROGRAM_API,
  GET_EXAM_LIST_API,
  GET_SEARCH_PROGRAM_LIST_API,
  DELETE_PROGRAM_API,
  GET_PROGRAMS_API,
  GET_PROGRAM_DETAILS_API,
  UPDATE_PROGRAM_API,
  CONFIRM_DEPARTMENT_API,
  CONFIRM_PROGRAM_API,
  DELETE_ALL_PROGRAM_DEPARTMENT_API,
  GET_UNIVERSITY_OVERVIEW_API
} from "../../Api";
import { toast } from "react-toastify";
// import axios from "axios";
import axios from "../../Api/axios"
import {
  saveDepartmentSuccess,
  saveDepartmentError,
  getDepartmentsSuccess,
  getDepartmentsError,
  viewDepartmentSuccess,
  viewDepartmentError,
  updateDepartmentSuccess,
  updateDepartmentError,
  getEducationListSuccess,
  getEducationListError,
  getRemainCampusListSuccess,
  getRemainCampusListError,
  createProgramSuccess,
  createProgramError,
  getLanguageExamListSuccess,
  getLanguageExamListError,
  getAdditionalExamListSuccess,
  getAdditionalExamListError,
  getSearchProgramListSuccess,
  getSearchProgramListError,
  deleteProgramSuccess,
  deleteProgramError,
  getProgramsSuccess,
  getProgramsError,
  getProgramDetailsSuccess,
  getProgramDetailsError,
  updateProgramSuccess,
  updateProgramError,
  confirmDepartmentSuccess,
  confirmDepartmentError,
  confirmProgramSuccess,
  confirmProgramError,
  deleteAllProgramDepartmentSuccess,
  deleteAllProgramDepartmentError,
  getUniversityOverviewSuccess,
  getUniversityOverviewError
} from '../actions';

function saveDepartmentApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SAVE_DEPARTMENT_API,
    data: params
  });
}

function* saveDepartment(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(saveDepartmentApi, payload);
    yield put(saveDepartmentSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(saveDepartmentError(e))
    if (reject) reject(e)
  }
}

export function* saveDepartmentActionWatcher() {
  yield takeLatest(SAVE_DEPARTMENT, saveDepartment)
}

function getDepartmentsActionApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_DEPARTMENTS_API,
	params: params
  });
}

function* getDepartmentsAction(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getDepartmentsActionApi, payload);
    yield put(getDepartmentsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getDepartmentsError(e))
    if (reject) reject(e)
  }
}

export function* getDepartmentsActionWatcher() {
  yield takeLatest(GET_DEPARTMENTS, getDepartmentsAction)
}

function viewDepartmentApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + VIEW_DEPARTMENT_API + "/" + params.department_id
	});
}

function* viewDepartment(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(viewDepartmentApi, payload);
	  yield put(viewDepartmentSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(viewDepartmentError(e))
	  if (reject) reject(e)
	}
  }

  export function* viewDepartmentActionWatcher() {
	yield takeLatest(VIEW_DEPARTMENT, viewDepartment)
  }

  function updateDepartmentApi(params) {
	  return axios.request({
		  method: "post",
		  url: baseUrl + UPDATE_DEPARTMENT_API+ "/" + params.id,
		  data: params
	  });
  }

  function* updateDepartment(action) {
	  let {
		payload,
		resolve,
		reject
	  } = action;
	  try {
		let response = yield call(updateDepartmentApi, payload);
		yield put(updateDepartmentSuccess(response.data));
		if (resolve) resolve(response.data)
	  } catch (e) {
		yield put(updateDepartmentError(e))
		if (reject) reject(e)
	  }
	}

	export function* updateDepartmentActionWatcher() {
	  yield takeLatest(UPDATE_DEPARTMENT, updateDepartment)
	}

	function getEducationListApi(params) {
		return axios.request({
		  method: "get",
		  url: baseUrl + GET_EDUCATION_LIST_API + "/"+ params.study_level_id
		});
	  }

	  function* getEducationList(action) {
		let {
		  payload,
		  resolve,
		  reject
		} = action;
		try {
		  let response = yield call(getEducationListApi, payload);
		  if (response.data.success) {
			yield put(getEducationListSuccess(response.data));
			if (resolve) resolve(response)
		  } else {
			yield put(getEducationListError(response.data))
			if (reject) reject(response)
		  }

		} catch (e) {
		  yield put(getEducationListError(e))
		  if (reject) reject(e)
		}
	  }

	  export function* getEducationListActionWatcher() {
		yield takeLatest(GET_EDUCATION_LIST, getEducationList)
	  }

	  function getRemainCampusListApi(params) {
		return axios.request({
		  method: "get",
		  url: baseUrl + GET_REMAIN_CAMPUS_LIST_API + "/" + params.did + "/" + params.uid
		});
	  }

	  function* getRemainCampusList(action) {
		let {
		  payload,
		  resolve,
		  reject
		} = action;
		try {
		  let response = yield call(getRemainCampusListApi, payload);
		  if (response.data.success) {
			yield put(getRemainCampusListSuccess(response.data));
			if (resolve) resolve(response)
		  } else {
			yield put(getRemainCampusListError(response.data))
			if (reject) reject(response)
		  }

		} catch (e) {
		  yield put(getRemainCampusListError(e))
		  if (reject) reject(e)
		}
	  }

	  export function* getRemainCampusListActionWatcher() {
		yield takeLatest(GET_REMAIN_CAMPUS_LIST, getRemainCampusList)
	  }



function createProgramApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + CREATE_PROGRAM_API,
	  data: params
	});
  }

  function* createProgram(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(createProgramApi, payload);
	  yield put(createProgramSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(createProgramError(e))
	  if (reject) reject(e)
	}
  }

  export function* createProgramActionWatcher() {
	yield takeLatest(CREATE_PROGRAM, createProgram)
  }

  function getLanguageExamListApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_EXAM_LIST_API + "/"+ params.exam_type
	});
  }

  function* getLanguageExamList(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(getLanguageExamListApi, payload);
	  if (response.data.success) {
		yield put(getLanguageExamListSuccess(response.data));
		if (resolve) resolve(response)
	  } else {
		yield put(getLanguageExamListError(response.data))
		if (reject) reject(response)
	  }

	} catch (e) {
	  yield put(getLanguageExamListError(e))
	  if (reject) reject(e)
	}
  }

  export function* getLanguageExamListActionWatcher() {
	yield takeLatest(GET_LANGUAGE_EXAM_LIST, getLanguageExamList)
  }


  function getAdditionalExamListApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_EXAM_LIST_API + "/"+ params.exam_type
	});
  }

  function* getAdditionalExamList(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(getAdditionalExamListApi, payload);
	  if (response.data.success) {
		yield put(getAdditionalExamListSuccess(response.data));
		if (resolve) resolve(response)
	  } else {
		yield put(getAdditionalExamListError(response.data))
		if (reject) reject(response)
	  }

	} catch (e) {
	  yield put(getAdditionalExamListError(e))
	  if (reject) reject(e)
	}
  }

  export function* getAdditionalExamListActionWatcher() {
	yield takeLatest(GET_ADDITIONAL_EXAM_LIST, getAdditionalExamList)
  }

  function getSearchProgramListApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_SEARCH_PROGRAM_LIST_API,
	  params: params
	});
  }

  function* getSearchProgramList(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(getSearchProgramListApi, payload);
	  if (response.data.success) {
		yield put(getSearchProgramListSuccess(response.data));
		if (resolve) resolve(response)
	  } else {
		yield put(getSearchProgramListError(response.data))
		if (reject) reject(response)
	  }

	} catch (e) {
	  yield put(getSearchProgramListError(e))
	  if (reject) reject(e)
	}
  }

  export function* getSearchProgramListActionWatcher() {
	yield takeLatest(GET_SEARCH_PROGRAM_LIST, getSearchProgramList)
  }


function deleteProgramApi(params) {
	return axios.request({
	  method: "delete",
	  url: baseUrl + DELETE_PROGRAM_API + "/" + params.program_id
	});
  }

  function* deleteProgram(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(deleteProgramApi, payload);
	  if (response.data.success) {
		yield put(deleteProgramSuccess(response.data));
		if (resolve) resolve(response)
	  } else {
		yield put(deleteProgramError(response.data))
		if (reject) reject(response)
	  }

	} catch (e) {
	  yield put(deleteProgramError(e))
	  if (reject) reject(e)
	}
  }

  export function* deleteProgramActionWatcher() {
	yield takeLatest(DELETE_PROGRAM, deleteProgram)
  }

function getProgramsActionApi(params) {
	return axios.request({
		method: "get",
		url: baseUrl + GET_PROGRAMS_API,
		params: params
	});
}

function* getProgramsAction(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(getProgramsActionApi, payload);
	  yield put(getProgramsSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(getProgramsError(e))
	  if (reject) reject(e)
	}
  }

  export function* getProgramsActionWatcher() {
	yield takeLatest(GET_PROGRAMS, getProgramsAction)
  }


function getProgramDetailsActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_PROGRAM_DETAILS_API + "/" + params.programId
	});
  }

  function* getProgramDetailsAction(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(getProgramDetailsActionApi, payload);
	  yield put(getProgramDetailsSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(getProgramDetailsError(e))
	  if (reject) reject(e)
	}
  }

  export function* getProgramDetailsActionWatcher() {
	yield takeLatest(GET_PROGRAM_DETAILS, getProgramDetailsAction)
  }


  function updateProgramApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + UPDATE_PROGRAM_API + "/" + params.programId,
	  data: params
	});
  }

  function* updateProgramAction(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(updateProgramApi, payload);
	  yield put(updateProgramSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(updateProgramError(e))
	  if (reject) reject(e)
	}
  }

  export function* updateProgramActionWatcher() {
	yield takeLatest(UPDATE_PROGRAM, updateProgramAction)
  }

  function confirmDepartmentApi(params) {
	return axios.request({
	  method: "delete",
	  url: baseUrl + CONFIRM_DEPARTMENT_API + "/" + params.department_deleted_id,
	  data: params
	});
  }

  function* confirmDepartmentAction(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(confirmDepartmentApi, payload);
	  yield put(confirmDepartmentSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(confirmDepartmentError(e))
	  if (reject) reject(e)
	}
  }

  export function* confirmDepartmentActionWatcher() {
	yield takeLatest(CONFIRM_DEPARTMENT, confirmDepartmentAction)
  }

  function confirmProgramApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + CONFIRM_PROGRAM_API + "/" + params.department_deleted_id,
	  data: params
	});
  }

  function* confirmProgramAction(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(confirmProgramApi, payload);
	  yield put(confirmProgramSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(confirmProgramError(e))
	  if (reject) reject(e)
	}
  }

  export function* confirmProgramActionWatcher() {
	yield takeLatest(CONFIRM_PROGRAM, confirmProgramAction)
  }

  function deleteAllProgramDepartmentApi(params) {
	return axios.request({
	  method: "delete",
	  url: baseUrl + DELETE_ALL_PROGRAM_DEPARTMENT_API + "/" + params.department_deleted_id,
	  data: params
	});
  }

  function* deleteAllProgramDepartmentAction(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(deleteAllProgramDepartmentApi, payload);
	  yield put(deleteAllProgramDepartmentSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(deleteAllProgramDepartmentError(e))
	  if (reject) reject(e)
	}
  }

  export function* deleteAllProgramDepartmentActionWatcher() {
	yield takeLatest(DELETE_ALL_PROGRAM_DEPARTMENT, deleteAllProgramDepartmentAction)
  }


  function getUniversityOverviewActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_UNIVERSITY_OVERVIEW_API + "/" + params.university_id
	});
  }

  function* getUniversityOverviewAction(action) {
	let {
	  payload,
	  resolve,
	  reject
	} = action;
	try {
	  let response = yield call(getUniversityOverviewActionApi, payload);
	  yield put(getUniversityOverviewSuccess(response.data));
	  if (resolve) resolve(response.data)
	} catch (e) {
	  yield put(getUniversityOverviewError(e))
	  if (reject) reject(e)
	}
  }

  export function* getUniversityOverviewActionWatcher() {
	yield takeLatest(GET_UNIVERSITY_OVERVIEW, getUniversityOverviewAction)
  }
