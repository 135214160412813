import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import {
  Badge, Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, Popover, PopoverBody, Row, UncontrolledDropdown, UncontrolledTooltip,
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import {
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import logo from "../../assets/img/brand/university-logo.svg";
import mobLogo from "../../assets/img/brand/university-logo-mob.svg";
import Homeicon from "../../assets/img/home-icon.svg";

import sygnet from "../../assets/img/brand/sygnet.svg";
import Settingsicon from "../../assets/img/settings-icon.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.json";
import jQuery from "jquery";
import { PASSWORD_REGEX } from "../../constants/constant";
import FormError from "../../common/FormError";
import "../../scss/style.scss";
import "./DefaultHeader.scss";
import "./header.scss";

import { ReactComponent as AccountIcon } from "../../assets/img/icons/default-user_grey.svg";
import { ReactComponent as NotificationIcon } from "../../assets/img/noti-icon.svg";
import { ReactComponent as InboxIcon } from "../../assets/img/icons/mail-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/icons/search.svg";
import { ReactComponent as DefaultUserGreyIcon } from "../../assets/img/icons/default-user_grey.svg";
import { ReactComponent as ShowPasswordIcon } from "../../assets/img/icon_show_password.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/img/icon_hide_password.svg";
import { ReactComponent as LogoutIcon1 } from "../../assets/img/icons/logoutIcon1.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showHideUpdatePasswordModalAction,
  updatePasswordAction,
  getMeAction
} from "../../store/actions";

import GetNotification from "./getNotification"

var baseurl = `${config.baseurl}`;
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
var count = 0;

class DefaultHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      large: false,
      layout: "normal",
      description: "",
      account_type: "",
      url: "",
      id: "",
      notficount: "",
      user_group_id: "",
      heading: "",
      supervisor_id: "",
      subordinate_id: "",
      upload_photo: [],
      fileLength: "",
      errors: {},
      acc_type: [],
      acc_type_id: [],
      user_access: [],
      acc_type_univ: "",
      current: "",
      password: "",
      applicationcount: 0,
      cpassword: "",
      checkpass: "",
      formData: new FormData(),
      mailCount: 0,
      notificationCount: 0,
      showPassword: false,
      showNewPassword: false,
      showReEnterPassword: false,
      userProfilePopover: false
    };
  }

  toggleLarge = () => {
    this.props.showHideUpdatePasswordModalAction(
      !this.props.modalStatus,
      () => {
        if (!this.props.modalStatus) {
          this.setState({
            password: "",
            cpassword: "",
          });
        }
      },
      () => { }
    );
  }

  handleChange = (event) => {
    localStorage.clear();
    sessionStorage.clear();
    let hostname = window.location.hostname;
    let domain = (hostname.split('.').reverse()[1] ? "." + hostname.split('.').reverse()[1] + "." : "") + hostname.split('.').reverse()[0];
    document.cookie = `userId=;path=/;domain=${domain}`;
    document.cookie = `userType=;path=/;domain=${domain}`;

    document.cookie = `fx_email=;path=/;domain=${domain}`;
    document.cookie = `fx_name=;path=/;domain=${domain}`;

    window.location = "/#/login";
  };

  handleCurrentpasswordChange = (event) => {
    this.setState({ current: event.target.value });
  };

  removeMailCount = (param) => (e) => {
    axios
      .get(
        baseurl +
        "/update_inboxmail_notification/" +
        localStorage.getItem("agent_id") +
        "/Agent"
      )
      .then((response) => {

      });
  };
  removeApplicationCount = () => (e) => {
    axios
      .get(
        baseurl +
        "/update_agent_application_count/" +
        localStorage.getItem("agent_id")
      )
      .then((response) => {

      });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleCpasswordChange = (event) => {
    this.setState({ cpassword: event.target.value });
  };

  getApp = (id) => {
    const { user_access } = this.state;
    if (jQuery.inArray(id, user_access) != -1) {
      return (
        <NavLink to="/applications" className="nav-link" onClick={this.removeApplicationCount()}>
          APPLICATIONS
          {/* {this.state.applicationcount !== 0 ? (<Badge pill color="danger">
						.
					</Badge>) : null} */}
        </NavLink>
      );
    } else {
      return (
        <NavLink to="/applications" className="nav-link" onClick={this.removeApplicationCount()}>
          APPLICATIONS
          {/* {this.state.applicationcount !== 0 ? (<Badge pill color="danger">
						.
					</Badge>) : null} */}
        </NavLink>
      );
    }
  }

  getPgm = (id) => {
    const { user_access } = this.state;
    if (jQuery.inArray(id, user_access) != -1) {
      return (
        <Link to="/client-listing" className="nav-link">
          CLIENTS
        </Link>
      );
    } else {
      return (
        <Link to="/client-listing" className="nav-link">
          CLIENTS
        </Link>
      );
    }
  }

  getAcc = (id) => {
    const { user_access } = this.state;
    if (jQuery.inArray(id, user_access) != -1) {
      return (
        <NavLink to="/profile" className="nav-link ag-bold" id="acc_menu">
          ACCOUNT
        </NavLink>
      );
    } else {
      return (
        <NavLink to="#" className="nav-link ag-bold" id="acc_menu">
          ACCOUNT
        </NavLink>
      );
    }
  }

  getInbox = (id) => {
    const { user_access } = this.state;
    if (jQuery.inArray(id, user_access) != -1) {
      return (
        <NavLink to="/inbox" className="nav-link" onClick={this.removeMailCount()}>
          <InboxIcon className="menuIcon stroke" />
          {this.state.mailCount <= 0 ? null : (<Badge pill color="danger">{this.state.mailCount}</Badge>)}
        </NavLink>
      );
    } else {
      return (
        <NavLink to="/inbox" className="nav-link" onClick={this.removeMailCount()}>
          <InboxIcon className="menuIcon stroke" />
          {this.state.mailCount <= 0 ? null : (<Badge pill color="danger">{this.state.mailCount}</Badge>)}
        </NavLink>
      );
    }
  }

  getPay = (id) => {
    const { user_access } = this.state;
    if (jQuery.inArray(id, user_access) != -1) {
      return (
        <NavLink to="/payment-statistics" className="nav-link">
          Payments
        </NavLink>
      );
    } else {
      return (
        <NavLink to="/payment-statistics" className="nav-link">
          Payments
        </NavLink>
      );
    }
  }

  getPermissions = async () => {
    const param = localStorage.getItem("agent_id");
    try {
      const agentStaffPermissionsRes = await axios.get(baseurl + "/get_agentstaffpermissions/" + param);
      let agentStaffPermissionsResData = {};
      let agentStaffPermissionResData = {};
      if (agentStaffPermissionsRes.data) {
        agentStaffPermissionsResData = {
          acc_type: agentStaffPermissionsRes.data.acc_type,
          acc_type_univ: agentStaffPermissionsRes.data.acc_type_univ,
          acc_type_id: agentStaffPermissionsRes.data.acc_type_id,
          user_group_id: agentStaffPermissionsRes.data.acc_type_univ,
        }
        const agent_id = localStorage.getItem("agent_id");
        const agentstaffpermissionRes = await axios
          .get(
            baseurl +
            "/getagentstaffpermission/" +
            agentStaffPermissionsRes.data.acc_type_univ +
            "/" +
            agent_id
          );
        if (agentstaffpermissionRes && agentstaffpermissionRes.data) {
          agentStaffPermissionResData = {
            heading: agentstaffpermissionRes.data.permission.heading || 0,
            supervisor_id: agentstaffpermissionRes.data.permission.supervisor_id || 0,
            subordinate_id: agentstaffpermissionRes.data.permission.subordinate_id || 0,
            id: agentstaffpermissionRes.data.permission.id || 0,
            user_access: agentstaffpermissionRes.data.permission.user_access || 0,
          };
        }
      };

      console.log('agentStaffPermissionsResData: ', agentStaffPermissionsResData);
      console.log('agentStaffPermissionResData: ', agentStaffPermissionResData);
      this.setState({
        ...agentStaffPermissionsResData,
        ...agentStaffPermissionResData,
      });

    } catch (error) {
      console.log('error: ', error);

    }
  }

  componentDidMount() {
    this.getPermissions();
    //this.getApplicationNotificationData();
    //this.getNotificationData();
    // setInterval(() => {
    //   this.getApplicationNotificationData();
    //   //this.getNotificationData();
    //   axios
    //     .get(
    //       baseurl +
    //       "/get_inboxmail_notification/" +
    //       localStorage.getItem("agent_id") +
    //       "/Agent"
    //     )
    //     .then((response) => {
    //       if (response && response.data) {
    //         this.setState({
    //           mailco: response.data.count,
    //         });
    //       }
    //     });
    // }, 10000);

    if (!this.props.profile) {
      this.props.getMeAction({
        id: localStorage.getItem("agent_id")
      })
    }
  }

  componentDidUpdate() {
    if (!this.props.profile) {
      this.props.getMeAction({
        id: localStorage.getItem("agent_id")
      })
    }
  }

  getApplicationNotificationData = () => {
    axios
      .get(baseurl + "/get_new_application_count/" + localStorage.getItem("agent_id"))
      .then((response) => {
        if (response.data && response.data.status_code == 200) {
          this.setState({
            applicationcount: response.data.count,
          });
        }
      });
  };

  // getNotificationData = async () => {
  // 	count = 0;
  // 	await axios
  // 		.get(baseurl + "/agentcountnotification/" + localStorage.getItem("agent_id"))
  // 		.then((response) => {
  // 			if (response.data && response.data.status_code == 200) {
  // 				count = response.data.count;
  // 			}
  // 		});

  // 	axios
  // 		.get(baseurl + "/get_shared_hired_notification/" + localStorage.getItem("agent_id") + "/Agent")
  // 		.then((response) => {
  // 			if (response && response.data) {
  // 				this.setState({
  // 					notficount: count + response.data.count,
  // 				});
  // 			}
  // 		});
  // };

  validate = () => {
    const errors = {};
    if (this.state.current.trim() === "") {
      errors.current = "Current Password is required";
    } else if (this.state.password.trim() === "") {
      errors.password = "Password is required";
    } else if (!this.state.password.match(PASSWORD_REGEX)) {
      errors.password =
        "Minimum 8 characters with combination of uppercase,lowercase,number,special character required";
    } else if (this.state.cpassword.trim() === "") {
      errors.cpassword = "Confirm password is required";
    } else if (this.state.cpassword.trim() !== this.state.password.trim()) {
      errors.cpassword = "Password does not match";
    } else {
      this.onSubmit();
    }

    this.setState({ errors });
  }

  onSubmit = () => {
    const password = this.state.password;
    const currentpassword = this.state.current;
    const id = localStorage.getItem("user_id");
    this.props.updatePasswordAction(
      {
        id,
        currentpassword,
        password
      },
      (response) => {
        if (response.success) {
          this.props.showHideUpdatePasswordModalAction(false);
          setTimeout(function () {
            localStorage.clear();
            sessionStorage.clear();
            window.location = "/#/login";
          }, 4000);
        }
      },
      () => { }
    )
  }

  render() {
    const { errors } = this.state;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modalStatus}
          toggle={this.toggleLarge}
          className={"primaryModal changePassword modal-lg " + this.props.className}
        >
          <ModalHeader>
            Change Password
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col xs="12">
                  <label className="card-label">Current password</label>
                  <InputGroup className="mb-3">
                    <Input
                      type={this.state.showPassword === false ? "password" : "text"}
                      name="current"
                      value={this.state.current}
                      placeholder="Enter current password"
                      onChange={this.handleCurrentpasswordChange}
                    />
                    <InputGroupAddon addonType="append" className="showHidePassword">
                      <ShowPasswordIcon className={this.state.showPassword === true ? "show" : "hide"}
                        onClick={() => { this.setState({ showPassword: false }) }}
                      />
                      <HidePasswordIcon className={this.state.showPassword === false ? "show" : "hide"}
                        onClick={() => { this.setState({ showPassword: true }) }}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                  <FormError error={errors.current} />
                </Col>
                <Col xs="12">
                  <label className="card-label">New Password</label>
                  <InputGroup className="mb-3">
                    <Input
                      type={this.state.showNewPassword === false ? "password" : "text"}
                      name="password"
                      placeholder="Enter new password"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                    />
                    <InputGroupAddon addonType="append" className="showHidePassword">
                      <ShowPasswordIcon className={this.state.showNewPassword === true ? "show" : "hide"}
                        onClick={() => { this.setState({ showNewPassword: false }) }}
                      />
                      <HidePasswordIcon className={this.state.showNewPassword === false ? "show" : "hide"}
                        onClick={() => { this.setState({ showNewPassword: true }) }}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                  <FormError error={errors.password} />
                </Col>
                <Col xs="12">
                  <label className="card-label">Re-enter New Password</label>
                  <InputGroup className="mb-3">
                    <Input
                      type={this.state.showReEnterPassword === false ? "password" : "text"}
                      name="cpassword"
                      placeholder="Enter confirm new password"
                      value={this.state.cpassword}
                      onChange={this.handleCpasswordChange}
                    />
                    <InputGroupAddon addonType="append" className="showHidePassword">
                      <ShowPasswordIcon className={this.state.showReEnterPassword === true ? "show" : "hide"}
                        onClick={() => { this.setState({ showReEnterPassword: false }) }}
                      />
                      <HidePasswordIcon className={this.state.showReEnterPassword === false ? "show" : "hide"}
                        onClick={() => { this.setState({ showReEnterPassword: true }) }}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                  <FormError error={errors.cpassword} />
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter className="text-center">
            <Button color="secondary" onClick={this.toggleLarge}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.validate}>
              Update my password
            </Button>
          </ModalFooter>
        </Modal>

        <Container className="headerSection">
          {/* <AppSidebarToggler className="d-lg-none" display="md" mobile /> */}
          <AppNavbarBrand
            full={{
              src: logo,
              width: "100%",
              height: "auto",
              alt: "University Logo",
            }}
            minimized={{
              src: sygnet,
              width: 30,
              height: 30,
              alt: "University Logo",
            }}
            tag={NavLink}
            to={"/agentdashboard"}
            className="DesktopLogo"
          />

          <AppNavbarBrand
            full={{
              src: mobLogo,
              width: "100%",
              height: "auto",
              alt: "University Logo",
            }}
            tag={NavLink}
            to={"/agentdashboard"}
            className="MobileLogo"
          />

          {/* <AppSidebarToggler className="d-md-down-none d-none" display="lg" /> */}
          <div className="menuArea">
            <AppSidebarToggler
              className="d-lg-none"
              display="md"
              mobile
            />
            <Nav className="d-md-down-none" navbar>
              {/* <NavItem className="px-4">
							<NavLink to="" className="nav-link">
								<SearchIcon className="menuIcon" />
							</NavLink>
						</NavItem> */}
              {/* <span className="sep"></span> */}
              <NavItem className="px-4">{this.getApp("2")}</NavItem>
              <NavItem className="px-4">{this.getPgm("3")}</NavItem>
              <NavItem className="px-4">{this.getPay("4")}</NavItem>
              <span className="sep"></span>
              <NavItem className="px-4">{this.getInbox("5")}</NavItem>
              <NavItem className="px-2 profilenotification">
                <GetNotification
                />
                {/* {getNotification.call(this)} */}
                {/* <NavLink to="/profilenotification" className="nav-link">
								<NotificationIcon className="menuIcon" />
								{this.state.notficount && <Badge className="bg-danger">{this.state.notficount}</Badge>}
							</NavLink> */}
              </NavItem>
              <NavItem className="px-4 accountLink" id="userProfile"
                onClick={() => {
                  this.setState({
                    userProfilePopover: true
                  });
                }}><AccountIcon className="uni-icon path" /></NavItem>

              <Popover placement="bottom-end" isOpen={this.state.userProfilePopover} target="userProfile"
                className="userProfile"
                trigger="legacy"
                toggle={() => {
                  this.setState({
                    userProfilePopover: false
                  });
                }}
              >
                <PopoverBody>
                  <ul>
                    {
                      this.props.profile ? (
                        <li>
                          <UncontrolledTooltip placement='left-start' target={"userDetails"}>
                            <span>
                              {
                                this.props.profile.first_name + " " + this.props.profile.last_name
                              }
                            </span><br />
                            <span>
                              {
                                this.props.profile.email
                              }
                            </span>
                          </UncontrolledTooltip>
                          <NavLink
                            to="#nolink"
                            className={`nav-link`}
                            id="userDetails"
                          >
                            <AccountIcon width="16" height="16" />
                            <span>
                              {
                                this.props.profile.first_name + " " + this.props.profile.last_name
                              }
                            </span>
                            <span>
                              {
                                this.props.profile.email
                              }
                            </span>
                          </NavLink>
                        </li>
                      ) : null
                    }

                    <li>
                      <NavLink
                        to="/agentdashboard"
                        className={`nav-link {window.location.pathname === "/agentdashboard" ? 'active' : ""}`}
                        onClick={() => {
                          // this.getNotifications()
                          this.setState({
                            userProfilePopover: false
                          });
                        }}
                      >
                        <AccountIcon />
                        My Account
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to=""
                        className="nav-link"
                        onClick={() => {
                          this.handleChange();
                        }}
                      >
                        <LogoutIcon1 />
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </PopoverBody>
              </Popover>


              {/* <UncontrolledDropdown
                nav
                direction="down"
                className="px-4"
              >
                <DropdownToggle nav className="accountLink">
                  <AccountIcon className="uni-icon path" />
                  <div className="iconName">My Account</div>
                </DropdownToggle>
                <DropdownMenu className="logout-section" right>
                  <DropdownItem
                    className="text-center"
                    onClick={() => {
                      this.props.history.push('/agentdashboard')
                    }}
                  >
                    <strong>My Account</strong>
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => {
                      this.handleChange();
                    }}
                    className="text-center"
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}

              {/* <NavItem className="px-2 myAccount">
							<NavLink to="/my-account" className="nav-link">
								<AccountIcon className="menuIcon" />
								<div className="menu-text">My Account</div>
							</NavLink>
						</NavItem> */}
            </Nav>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
    modalStatus: state.generalReducer.showPasswordModal,
    profile: state.profileReducer.profile
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showHideUpdatePasswordModalAction,
      updatePasswordAction,
      getMeAction
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader));
