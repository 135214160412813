import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_AGENT,
  GET_DASHBOARD_AGENT_SUCCESS,
  GET_DASHBOARD_AGENT_ERROR,
  GET_STUDENT_APPLICATION_STATISTICS,
  GET_STUDENT_APPLICATION_STATISTICS_SUCCESS,
  GET_STUDENT_APPLICATION_STATISTICS_ERROR,
  GET_AGENT_INCOME_COUNT,
  GET_AGENT_INCOME_COUNT_SUCCESS,
  GET_AGENT_INCOME_COUNT_ERROR,
  GET_STUDENT_APPLICATION_COUNT,
  GET_STUDENT_APPLICATION_COUNT_SUCCESS,
  GET_STUDENT_APPLICATION_COUNT_ERROR,
  GET_TOP_DESTINATION_UNIVERSITY,
  GET_TOP_DESTINATION_UNIVERSITY_ERROR,
  GET_TOP_DESTINATION_UNIVERSITY_SUCCESS,
  GET_TOP_SOURCE_APPLICATION,
  GET_TOP_SOURCE_APPLICATION_ERROR,
  GET_TOP_SOURCE_APPLICATION_SUCCESS,
  GET_EXPRESS_COUNTRY_LIST,
  GET_EXPRESS_COUNTRY_LIST_SUCCESS,
  GET_EXPRESS_COUNTRY_LIST_ERROR
} from "../constants";

export function getDashboardDataAction(session, resolve, reject){
  return {
    type: GET_DASHBOARD_DATA,
    payload: session,
    resolve,
    reject
  };
}

export function getDashboardDataSuccess(data) {
  return {
    type: GET_DASHBOARD_DATA_SUCCESS,
    payload: data
  };
}

export function getDashboardDataError(error) {
  return {
    type: GET_DASHBOARD_DATA_ERROR,
    payload: error
  };
}


export function getDashboardAgentAction(session, resolve, reject){
	return {
	  type: GET_DASHBOARD_AGENT,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getDashboardAgentSuccess(data) {
	return {
		type: GET_DASHBOARD_AGENT_SUCCESS,
		payload: data
	};
}

export function getDashboardAgentError(error) {
	return {
		type: GET_DASHBOARD_AGENT_ERROR,
	  	payload: error
	};
}

export function getStudentApplicationStatisticsAction(session, resolve, reject){
	return {
	  type: GET_STUDENT_APPLICATION_STATISTICS,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getStudentApplicationStatisticsSuccess(data) {
	return {
		type: GET_STUDENT_APPLICATION_STATISTICS_SUCCESS,
		payload: data
	};
}

export function getStudentApplicationStatisticsError(error) {
	return {
		type: GET_STUDENT_APPLICATION_STATISTICS_ERROR,
	  	payload: error
	};
}

export function getAgentIncomeCount(session, resolve, reject){
	return {
	  type: GET_AGENT_INCOME_COUNT,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getAgentIncomeCountSuccess(data) {
	return {
		type: GET_AGENT_INCOME_COUNT_SUCCESS,
		payload: data
	};
}

export function getAgentIncomeCountError(error) {
	return {
		type: GET_AGENT_INCOME_COUNT_ERROR,
	  	payload: error
	};
}

export function getExpressCountryList(session, resolve, reject){
	return {
	  type: GET_EXPRESS_COUNTRY_LIST,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getExpressCountryListSuccess(data) {
	return {
		type: GET_EXPRESS_COUNTRY_LIST_SUCCESS,
		payload: data
	};
}

export function getExpressCountryListError(error) {
	return {
		type: GET_EXPRESS_COUNTRY_LIST_ERROR,
	  	payload: error
	};
}

export function getStudentApplicationCount(session, resolve, reject){
	return {
	  type: GET_STUDENT_APPLICATION_COUNT,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getStudentApplicationCountSuccess(data) {
	return {
		type: GET_STUDENT_APPLICATION_COUNT_SUCCESS,
		payload: data
	};
}

export function getStudentApplicationCountError(error) {
	return {
		type: GET_STUDENT_APPLICATION_COUNT_ERROR,
	  	payload: error
	};
}

export function getTopDestinationUniversityAction(session, resolve, reject){
	return {
	  type: GET_TOP_DESTINATION_UNIVERSITY,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getTopDestinationUniversitySuccess(data) {
	return {
		type: GET_TOP_DESTINATION_UNIVERSITY_SUCCESS,
		payload: data
	};
}

export function getTopDestinationUniversityError(error) {
	return {
		type: GET_TOP_DESTINATION_UNIVERSITY_ERROR,
	  	payload: error
	};
}

export function getTopSourceApplicationAction(session, resolve, reject){
	return {
	  type: GET_TOP_SOURCE_APPLICATION,
	  payload: session,
	  resolve,
	  reject
	};
}

export function getTopSourceApplicationSuccess(data) {
	return {
		type: GET_TOP_SOURCE_APPLICATION_SUCCESS,
		payload: data
	};
}

export function getTopSourceApplicationError(error) {
	return {
		type: GET_TOP_SOURCE_APPLICATION_ERROR,
	  	payload: error
	};
}
