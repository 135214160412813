import React from 'react';
import { Link } from 'react-router-dom';
import "./emailTemplate.scss";

export default function ShareProfile(item) {
  console.log('item prof', item)
  return (
    <>
      <div className="emailContent">
        <div className="header">
          <p>
            <strong>
              {
                item.email_from_details ? (
                  item.email_from_details.personal_details ? (
                    item.email_from_details.personal_details.first_name +
                    " " + 
                    item.email_from_details.personal_details.last_name +
                    " "
                  ) : null
                ) : null
              }
            </strong> 
            has shared his profile with you. Please click on the link below to view profile.
          </p>
        </div>
        <div className="contentBox mt-30">
          <Link 
            to={`/profile-overview/${item.sender_id}`}
            rel="noopener noreferrer"
            className="btn btn-green"
          >
            View profile
          </Link>
          {/* <a href="www.studentprofileviewbypreferredpartner.com" target="_blank" className="linkOnly">
            www.studentprofileviewbypreferredpartner.com
          </a> */}
        </div>
        <div className="footer mt-80">
          <p className="mb-20">Unexpected email? To contact Formee Express team, send email to <strong><a href="mailto:admin@formeeexpress.com">admin@formeeexpress.com</a></strong></p>
          <p><strong>Need Support?</strong></p>
          <p>Feel free to email us if you have any questions, comments or suggestions.<br />
            We’ll be happy to resolve your issues.</p>
        </div>
      </div>
    </>
  );
}