import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_PROGRAM_COUNTRY_LIST,
  GET_PROGRAM_COUNTRY_LIST_SUCCESS,
  GET_PROGRAM_COUNTRY_LIST_ERROR,
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  GET_CURRENCY_LIST_ERROR,
  SHOW_HIDE_UPDATE_PASSWORD_MODAL,
  SHOW_HIDE_LOADING,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  ONBOARDING_PROCESS,
  ONBOARDING_PROCESS_SUCCESS,
  ONBOARDING_PROCESS_ERROR
} from "../constants";

export function getCountryListAction(session, resolve, reject) {
  return {
    type: GET_COUNTRY_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getCountryListSuccess(data) {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: data
  };
}

export function getCountryListError(error) {
  return {
    type: GET_COUNTRY_LIST_ERROR,
    payload: error
  };
}
export function getProgranCountryListAction(session, resolve, reject) {
  return {
    type: GET_PROGRAM_COUNTRY_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getProgranCountryListSuccess(data) {
  return {
    type: GET_PROGRAM_COUNTRY_LIST_SUCCESS,
    payload: data
  };
}

export function getProgranCountryListError(error) {
  return {
    type: GET_PROGRAM_COUNTRY_LIST_ERROR,
    payload: error
  };
}

export function getCurrencyListAction(session, resolve, reject) {
  return {
    type: GET_CURRENCY_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getCurrencyListSuccess(data) {
  return {
    type: GET_CURRENCY_LIST_SUCCESS,
    payload: data
  };
}

export function getCurrencyListError(error) {
  return {
    type: GET_CURRENCY_LIST_ERROR,
    payload: error
  };
}

export function showHideUpdatePasswordModalAction(session, resolve, reject) {
  return {
    type: SHOW_HIDE_UPDATE_PASSWORD_MODAL,
    payload: session,
    resolve,
    reject
  };
}

export function showHideLoadingAction(session, resolve, reject) {
  console.log('loading called', session, resolve, reject)
  return {
    type: SHOW_HIDE_LOADING,
    payload: session,
    resolve,
    reject
  };
}

export function forgotPasswordAction(session, resolve, reject) {
	return {
		type: FORGOT_PASSWORD,
		payload: session,
		resolve,
		reject
	};
}

export function forgotPasswordSuccess(data) {
	return {
		type: FORGOT_PASSWORD_SUCCESS,
		payload: data
	};
}

export function forgotPasswordError(error) {
	return {
		type: FORGOT_PASSWORD_ERROR,
		payload: error
	};
}

export function resetPasswordAction(session, resolve, reject) {
	return {
		type: RESET_PASSWORD,
		payload: session,
		resolve,
		reject
	};
}

export function resetPasswordSuccess(data) {
	return {
		type: RESET_PASSWORD_SUCCESS,
		payload: data
	};
}

export function resetPasswordError(error) {
	return {
		type: RESET_PASSWORD_ERROR,
		payload: error
	};
}

export function onBoardingProcessAction(session, resolve, reject) {
	return {
		type: ONBOARDING_PROCESS,
		payload: session,
		resolve,
		reject
	};
}

export function onBoardingProcessSuccess(data) {

	return {
		type: ONBOARDING_PROCESS_SUCCESS,
		payload: data
	};
}

export function onBoardingProcessError(error) {
	return {
		type: ONBOARDING_PROCESS_ERROR,
		payload: error
	};
}
