import { toast } from "react-toastify";

export function handleErrorResponse(error) {
  if (error && error.status && error.status == 423) {
    let data = error.data.data;

    for (let field in data) {
      if (data.hasOwnProperty(field)) {
        console.log('asdasd', field, data[field][0])
        if (data[field][0]) {
          toast.error(data[field][0]);
          return;
        }
        return
      }

    }
  } else {
    if (error && error.data && error.data.message) {
      toast.error(error.data.message);
      return;
    }
  }
}

export function handleSuccessResponse(response) {
  if (response.success) {
    if (response.message) {
      toast.success(response.message);
    }
  }

  return;
}

export * from './auth';

export * from './dashboard';

export * from './general';

export * from './profile';

export * from "./staff";

export * from "./application";

export * from "./department";

export * from "./inbox";

export * from "./calender";

export * from "./client";

export * from "./payment";

export * from "./chat";

export * from "./notification";
