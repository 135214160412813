export function generateApplicationStatusString(string) {
  let currentStatus = string;

  if (string) {
    currentStatus = currentStatus.replace(' ', "_");
    currentStatus = currentStatus.replace('-', "_");
    currentStatus = currentStatus.toLowerCase();
  } else {

  }


  return currentStatus;
}
