import {
  SAVE_SIDE_PROFILE,
  SAVE_SIDE_PROFILE_SUCCESS,
  SAVE_SIDE_PROFILE_ERROR,
  GET_UNIVERSITY_ABOUT,
  GET_UNIVERSITY_ABOUT_ERROR,
  GET_UNIVERSITY_ABOUT_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE,
  SAVE_INTAKE_DATES,
  SAVE_INTAKE_DATES_ERROR,
  SAVE_INTAKE_DATES_SUCCESS,
  GET_INTAKE_DATES_ERROR,
  GET_INTAKE_DATES_SUCCESS,
  GET_INTAKE_DATES,
  SAVE_APPLICATION_FORM_ERROR,
  SAVE_APPLICATION_FORM_SUCCESS,
  SAVE_APPLICATION_FORM,
  GET_APPLICATION_FORM_ERROR,
  GET_APPLICATION_FORM_SUCCESS,
  GET_APPLICATION_FORM,
  GET_PROFILE_STATUS_ERROR,
  GET_PROFILE_STATUS_SUCCESS,
  GET_PROFILE_STATUS,
  GET_REVIEWS_ERROR,
  GET_REVIEWS_SUCCESS,
  GET_PROFILE,
  GET_REVIEWS,
  GET_REVIEW_COMMENTS_ERROR,
  GET_REVIEW_COMMENTS_SUCCESS,
  GET_REVIEW_COMMENTS,
  REPLY_TO_REVIEW_ERROR,
  REPLY_TO_REVIEW_SUCCESS,
  REPLY_TO_REVIEW,
  DELETE_REVIEW_COMMENT_ERROR,
  DELETE_REVIEW_COMMENT_SUCCESS,
  DELETE_REVIEW_COMMENT,
  GET_REVIEW_REPORT_REASONS_ERROR,
  GET_REVIEW_REPORT_REASONS_SUCCESS,
  GET_REVIEW_REPORT_REASONS,
  REPORT_REVIEW_ERROR,
  REPORT_REVIEW_SUCCESS,
  GET_LOGIN_LOGS,
  GET_LOGIN_LOGS_SUCCESS,
  GET_LOGIN_LOGS_ERROR,
  GET_AGREEMENT_DOCUMENTS,
  GET_AGREEMENT_DOCUMENTS_SUCCESS,
  GET_AGREEMENT_DOCUMENTS_ERROR,
  REPORT_REVIEW,
  SEND_PUBLISH_REQUEST,
  SEND_PUBLISH_REQUEST_SUCCESS,
  SEND_PUBLISH_REQUEST_ERROR,
  GET_PUBLISH_REQUEST,
  GET_PUBLISH_REQUEST_SUCCESS,
  GET_PUBLISH_REQUEST_ERROR,
} from "../constants";

export function saveSideProfileAction(session, resolve, reject) {
  return {
    type: SAVE_SIDE_PROFILE,
    payload: session,
    resolve,
    reject
  };
}

export function saveSideProfileSuccess(data) {
  return {
    type: SAVE_SIDE_PROFILE_SUCCESS,
    payload: data
  };
}

export function saveSideProfileError(error) {
  return {
    type: SAVE_SIDE_PROFILE_ERROR,
    payload: error
  };
}

export function getUniversityAboutAction(session, resolve, reject) {
  return {
    type: GET_UNIVERSITY_ABOUT,
    payload: session,
    resolve,
    reject
  };
}

export function sendPublishRequestAction(session, resolve, reject) {
  return {
    type: SEND_PUBLISH_REQUEST,
    payload: session,
    resolve,
    reject
  };
}

export function sendPublishRequestSuccess(data) {
  return {
    type: SEND_PUBLISH_REQUEST_SUCCESS,
    payload: data
  };
}

export function sendPublishRequestError(error) {
  return {
    type: SEND_PUBLISH_REQUEST_ERROR,
    payload: error
  };
}

export function getPublishRequestAction(session, resolve, reject) {
  return {
    type: GET_PUBLISH_REQUEST,
    payload: session,
    resolve,
    reject
  };
}

export function getPublishRequestSuccess(data) {
  return {
    type: GET_PUBLISH_REQUEST_SUCCESS,
    payload: data
  };
}

export function getPublishRequestError(error) {
  return {
    type: GET_PUBLISH_REQUEST_ERROR,
    payload: error
  };
}

export function getUniversityAboutSuccess(data) {
  return {
    type: GET_UNIVERSITY_ABOUT_SUCCESS,
    payload: data
  };
}

export function getUniversityAboutError(error) {
  return {
    type: GET_UNIVERSITY_ABOUT_ERROR,
    payload: error
  };
}

export function getLoginLogsAction(session, resolve, reject) {
  return {
    type: GET_LOGIN_LOGS,
    payload: session,
    resolve,
    reject
  };
}

export function getLoginLogsSuccess(data) {
  return {
    type: GET_LOGIN_LOGS_SUCCESS,
    payload: data
  };
}

export function getLoginLogsError(error) {
  return {
    type: GET_LOGIN_LOGS_ERROR,
    payload: error
  };
}

export function getAgreementDocumentsAction(session, resolve, reject) {
  return {
    type: GET_AGREEMENT_DOCUMENTS,
    payload: session,
    resolve,
    reject
  };
}

export function getAgreementDocumentsSuccess(data) {
  return {
    type: GET_AGREEMENT_DOCUMENTS_SUCCESS,
    payload: data
  };
}

export function getAgreementDocumentsError(error) {
  return {
    type: GET_AGREEMENT_DOCUMENTS_ERROR,
    payload: error
  };
}

export function updateProfileAction(session, resolve, reject) {
  return {
    type: UPDATE_PROFILE,
    payload: session,
    resolve,
    reject
  };
}

export function updateProfileSuccess(data) {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data
  };
}

export function updateProfileError(error) {
  return {
    type: UPDATE_PROFILE_ERROR,
    payload: error
  };
}

export function saveIntakeDatesAction(session, resolve, reject) {
  return {
    type: SAVE_INTAKE_DATES,
    payload: session,
    resolve,
    reject
  };
}

export function saveIntakeDatesSuccess(data) {
  return {
    type: SAVE_INTAKE_DATES_SUCCESS,
    payload: data
  };
}

export function saveIntakeDatesError(error) {
  return {
    type: SAVE_INTAKE_DATES_ERROR,
    payload: error
  };
}

export function getIntakeDatesAction(session, resolve, reject) {
  return {
    type: GET_INTAKE_DATES,
    payload: session,
    resolve,
    reject
  };
}

export function getIntakeDatesSuccess(data) {
  return {
    type: GET_INTAKE_DATES_SUCCESS,
    payload: data
  };
}

export function getIntakeDatesError(error) {
  return {
    type: GET_INTAKE_DATES_ERROR,
    payload: error
  };
}

export function saveApplicationFormAction(session, resolve, reject) {
  return {
    type: SAVE_APPLICATION_FORM,
    payload: session,
    resolve,
    reject
  };
}

export function saveApplicationFormSuccess(data) {
  return {
    type: SAVE_APPLICATION_FORM_SUCCESS,
    payload: data
  };
}

export function saveApplicationFormError(error) {
  return {
    type: SAVE_APPLICATION_FORM_ERROR,
    payload: error
  };
}

export function getApplicationFormAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_FORM,
    payload: session,
    resolve,
    reject
  };
}

export function getApplicationFormSuccess(data) {
  return {
    type: GET_APPLICATION_FORM_SUCCESS,
    payload: data
  };
}

export function getApplicationFormError(error) {
  return {
    type: GET_APPLICATION_FORM_ERROR,
    payload: error
  };
}

export function getProfileStatusAction(session, resolve, reject) {
  return {
    type: GET_PROFILE_STATUS,
    payload: session,
    resolve,
    reject
  };
}

export function getProfileStatusSuccess(data) {
  return {
    type: GET_PROFILE_STATUS_SUCCESS,
    payload: data
  };
}

export function getProfileStatusError(error) {
  return {
    type: GET_PROFILE_STATUS_ERROR,
    payload: error
  };
}

export function getReviewsAction(session, resolve, reject) {
  return {
    type: GET_REVIEWS,
    payload: session,
    resolve,
    reject
  };
}

export function getReviewsSuccess(data) {
  return {
    type: GET_REVIEWS_SUCCESS,
    payload: data
  };
}

export function getReviewsError(error) {
  return {
    type: GET_REVIEWS_ERROR,
    payload: error
  };
}

export function getReviewCommentsAction(session, resolve, reject) {
  return {
    type: GET_REVIEW_COMMENTS,
    payload: session,
    resolve,
    reject
  };
}

export function getReviewCommentsSuccess(data) {
  return {
    type: GET_REVIEW_COMMENTS_SUCCESS,
    payload: data
  };
}

export function getReviewCommentsError(error) {
  return {
    type: GET_REVIEW_COMMENTS_ERROR,
    payload: error
  };
}

export function replyToReviewAction(session, resolve, reject) {
  return {
    type: REPLY_TO_REVIEW,
    payload: session,
    resolve,
    reject
  };
}

export function replyToReviewSuccess(data) {
  return {
    type: REPLY_TO_REVIEW_SUCCESS,
    payload: data
  };
}

export function replyToReviewError(error) {
  return {
    type: REPLY_TO_REVIEW_ERROR,
    payload: error
  };
}

export function deleteReviewCommentAction(session, resolve, reject) {
  return {
    type: DELETE_REVIEW_COMMENT,
    payload: session,
    resolve,
    reject
  };
}

export function deleteReviewCommentSuccess(data) {
  return {
    type: DELETE_REVIEW_COMMENT_SUCCESS,
    payload: data
  };
}

export function deleteReviewCommentError(error) {
  return {
    type: DELETE_REVIEW_COMMENT_ERROR,
    payload: error
  };
}

export function getReviewReportReasonsAction(session, resolve, reject) {
  return {
    type: GET_REVIEW_REPORT_REASONS,
    payload: session,
    resolve,
    reject
  };
}

export function getReviewReportReasonsSuccess(data) {
  return {
    type: GET_REVIEW_REPORT_REASONS_SUCCESS,
    payload: data
  };
}

export function getReviewReportReasonsError(error) {
  return {
    type: GET_REVIEW_REPORT_REASONS_ERROR,
    payload: error
  };
}

export function reportReviewAction(session, resolve, reject) {
  return {
    type: REPORT_REVIEW,
    payload: session,
    resolve,
    reject
  };
}

export function reportReviewSuccess(data) {
  return {
    type: REPORT_REVIEW_SUCCESS,
    payload: data
  };
}

export function reportReviewError(error) {
  return {
    type: REPORT_REVIEW_ERROR,
    payload: error
  };
}