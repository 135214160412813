import {
  ADD_CLIENT_COMMENT,
  ADD_CLIENT_COMMENT_ERROR,
  ADD_CLIENT_COMMENT_SUCCESS,
  DELETE_CLIENT,
  DELETE_CLIENT_COMMENT,
  DELETE_CLIENT_COMMENT_ERROR,
  DELETE_CLIENT_COMMENT_SUCCESS,
  DELETE_CLIENT_ERROR,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENT_COMMENT,
  GET_CLIENT_COMMENT_SUCCESS,
  GET_CLIENT_COUNTRY_LIST,
  GET_CLIENT_COUNTRY_LIST_ERROR,
  GET_CLIENT_COUNTRY_LIST_SUCCESS,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_ERROR,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_ERROR,
  GET_CLIENT_LIST_SUCCESS,
  GET_TERMINATE_REASONS,
  GET_TERMINATE_REASONS_ERROR,
  GET_TERMINATE_REASONS_SUCCESS,
  MATCH_CLIENT_PROFILE,
  MATCH_CLIENT_PROFILE_ERROR,
  MATCH_CLIENT_PROFILE_SUCCESS,
  RAISE_DISPUTE,
  RAISE_DISPUTE_ERROR,
  RAISE_DISPUTE_SUCCESS,
  SCHOOL_LIST,
  SCHOOL_LIST_ERROR,
  SCHOOL_LIST_SUCCESS,
  TERMINATE_AGREEMENT,
  TERMINATE_AGREEMENT_ERROR,
  TERMINATE_AGREEMENT_SUCCESS,
  UPDATE_CLIENT_FAVOURITE,
  UPDATE_CLIENT_FAVOURITE_ERROR,
  UPDATE_CLIENT_FAVOURITE_SUCCESS,
} from "../constants";

export function getClientListAction(session, resolve, reject) {
  return {
    type: GET_CLIENT_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function getClientListSuccess(data) {
  return {
    type: GET_CLIENT_LIST_SUCCESS,
    payload: data,
  };
}

export function getClientListError(error) {
  return {
    type: GET_CLIENT_LIST_ERROR,
    payload: error,
  };
}

export function getClientCountryListAction(session, resolve, reject) {
  return {
    type: GET_CLIENT_COUNTRY_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function getClientCountryListSuccess(data) {
  return {
    type: GET_CLIENT_COUNTRY_LIST_SUCCESS,
    payload: data,
  };
}

export function getClientCountryListError(error) {
  return {
    type: GET_CLIENT_COUNTRY_LIST_ERROR,
    payload: error,
  };
}

export function updateClientFavouriteAction(session, resolve, reject) {
  return {
    type: UPDATE_CLIENT_FAVOURITE,
    payload: session,
    resolve,
    reject,
  };
}

export function updateClientFavouriteSuccess(data) {
  return {
    type: UPDATE_CLIENT_FAVOURITE_SUCCESS,
    payload: data,
  };
}

export function updateClientFavouriteError(error) {
  return {
    type: UPDATE_CLIENT_FAVOURITE_ERROR,
    payload: error,
  };
}

export function deleteClientAction(session, resolve, reject) {
  return {
    type: DELETE_CLIENT,
    payload: session,
    resolve,
    reject,
  };
}

export function deleteClientSuccess(data) {
  return {
    type: DELETE_CLIENT_SUCCESS,
    payload: data,
  };
}

export function deleteClientError(error) {
  return {
    type: DELETE_CLIENT_ERROR,
    payload: error,
  };
}

export function getClientDetailsAction(session, resolve, reject) {
  return {
    type: GET_CLIENT_DETAILS,
    payload: session,
    resolve,
    reject,
  };
}

export function getClientDetailsSuccess(data) {
  return {
    type: GET_CLIENT_DETAILS_SUCCESS,
    payload: data,
  };
}

export function getClientDetailsError(error) {
  return {
    type: GET_CLIENT_DETAILS_ERROR,
    payload: error,
  };
}

export function getTerminateReasonsAction(session, resolve, reject) {
  return {
    type: GET_TERMINATE_REASONS,
    payload: session,
    resolve,
    reject,
  };
}

export function getTerminateReasonsSuccess(data) {
  return {
    type: GET_TERMINATE_REASONS_SUCCESS,
    payload: data,
  };
}

export function getTerminateReasonsError(error) {
  return {
    type: GET_TERMINATE_REASONS_ERROR,
    payload: error,
  };
}

export function terminateAgreementAction(session, resolve, reject) {
  return {
    type: TERMINATE_AGREEMENT,
    payload: session,
    resolve,
    reject,
  };
}

export function terminateAgreementSuccess(data) {
  return {
    type: TERMINATE_AGREEMENT_SUCCESS,
    payload: data,
  };
}

export function terminateAgreementError(error) {
  return {
    type: TERMINATE_AGREEMENT_ERROR,
    payload: error,
  };
}

export function raiseDisputeAction(session, resolve, reject) {
  return {
    type: RAISE_DISPUTE,
    payload: session,
    resolve,
    reject,
  };
}

export function raiseDisputeSuccess(data) {
  return {
    type: RAISE_DISPUTE_SUCCESS,
    payload: data,
  };
}

export function raiseDisputeError(error) {
  return {
    type: RAISE_DISPUTE_ERROR,
    payload: error,
  };
}

export function matchClientProfileAction(session, resolve, reject) {
  return {
    type: MATCH_CLIENT_PROFILE,
    payload: session,
    resolve,
    reject,
  };
}

export function matchClientProfileSuccess(data) {
  return {
    type: MATCH_CLIENT_PROFILE_SUCCESS,
    payload: data,
  };
}

export function matchClientProfileError(error) {
  return {
    type: MATCH_CLIENT_PROFILE_ERROR,
    payload: error,
  };
}

export function schoolListAction(session, resolve, reject) {
  return {
    type: SCHOOL_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function schoolListSuccess(data) {
  return {
    type: SCHOOL_LIST_SUCCESS,
    payload: data,
  };
}

export function schoolListError(error) {
  return {
    type: SCHOOL_LIST_ERROR,
    payload: error,
  };
}

export function addClientCommentAction(session, resolve, reject) {
  return {
    type: ADD_CLIENT_COMMENT,
    payload: session,
    resolve,
    reject,
  };
}

export function addClientCommentSuccess(data) {
  return {
    type: ADD_CLIENT_COMMENT_SUCCESS,
    payload: data,
  };
}

export function addClientCommentError(error) {
  return {
    type: ADD_CLIENT_COMMENT_ERROR,
    payload: error,
  };
}

export function getClientCommentAction(session, resolve, reject) {
  return {
    type: GET_CLIENT_COMMENT,
    payload: session,
    resolve,
    reject,
  };
}

export function getClientCommentSuccess(data) {
  return {
    type: GET_CLIENT_COMMENT_SUCCESS,
    payload: data,
  };
}

export function getClientCommentError(error) {
  return {
    type: GET_CLIENT_COMMENT_SUCCESS,
    payload: error,
  };
}

export function deleteClientCommentAction(session, resolve, reject) {
  return {
    type: DELETE_CLIENT_COMMENT,
    payload: session,
    resolve,
    reject,
  };
}

export function deleteClientCommentSuccess(data) {
  return {
    type: DELETE_CLIENT_COMMENT_SUCCESS,
    payload: data,
  };
}

export function deleteClientCommentError(error) {
  return {
    type: DELETE_CLIENT_COMMENT_ERROR,
    payload: error,
  };
}
