import React, { useState } from 'react';

function FormError({error, className}) {
    return (
        <h6 className={className} style={{color: "red"}}>{error}</h6>
    );
}


export default FormError

FormError.defaultProps = {
    className: ''
}