import React from 'react';
import ShareProfile from "../emailTemplate/ShareProfile"
import AddViewer from "../emailTemplate/AddViewer"
import Hire from "../emailTemplate/Hire"
import TerminateContract from "../emailTemplate/TerminateContract"

export default function EmailTemplate(item) {
  let data = this;
  console.log('this.state.contactList', this.state.contactList)
  function mailTemplate() {
    switch (item.request_mail_type) {
      case 3: return (
        <AddViewer 
          data={item} 
          updateViewerRequest={(id, status) => {
            return this.updateViewerRequest(id, status)
          }} 
        />
      );
      case 2: return (ShareProfile.call(this, item));
      case 1: return (
        <Hire 
          data={item}
          updateHireRequest={(data) => {
            return this.updateHireRequest(data)
          }}
          getStaffList={this.getStaffList}
          contactList={this.state.contactList}
        />
      );
      case "terminate-contract": return (<TerminateContract data={data} />);
      default: return <h1>Email type not found!</h1>
    }
  }

  return (
    <>
      {mailTemplate.call(this)}
    </>
  );
}