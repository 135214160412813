import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from ".";
// import cors from "cors"

// cors.apply()
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 20000,
  // withCredentials: true,
  // credentials: 'same-origin'
});

instance.interceptors.request.use((reqConfig) => {
  let universityToken = localStorage.getItem('agentToken');
  if (universityToken) {
    reqConfig.headers.common['Authorization'] = "Bearer "+universityToken;
  }
  return reqConfig;
}, (err) => {
  console.log('auth err', err)
})

instance.interceptors.response.use((response) => {
  return response
}, (err) => {
  // console.log('errinter', err.response);
  if (err && err.response && err.response.status == 403) {
    toast.error('Unathorized access!')
  }
  
  return Promise.reject(err)
})

export default instance;