import { GET_USER_PERMISSIONS_SUCCESS, LOGIN_SUCCESS } from "../constants"

const initialState = {
  user: null,
  permissions: null
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: 
      console.log('LOGIN_SUCCESS', action.payload)
      return {
        ...state,
        user: action.payload.data
      }
    case GET_USER_PERMISSIONS_SUCCESS: 
      return {
        ...state,
        permissions: action.payload.data
      }
    default:
      return {
        ...state
      }
  }
}