export const MESSAGES = {
  DEFAULT_ERROR_MESSAGE: "Something went wrong, Please try again",
  EMAIL_INVALID: "Invalid email",
  PASSWORD_INVALID:
    "Minimum 8 characters with combination of uppercase,lowercase,number,special character required",
  INVALID_CREDENTIALS: "Invalid credentials",
  ACCOUNT_NOT_ACTIVATED: "Please activate your account",
  PLEASE_SELECT_MESSAGE: "Please select",
  EMAIL_ALREADY_EXISTS: "Email already exists",
  EMAIL_NOT_EXIST: "Email not exists",
  AGENT_NOT_EXIST: "Agent not exists",
  PASSWORD_NOT_MATCH: "Password does not match",
  EMAIL_REQUIRED: "Email is required",
  PASSWORD_REQUIRED: "Password is required",
  FIRST_NAME_REQUIRED: "First Name is required",
  LAST_NAME_REQUIRED: "Last Name is required",
  NAME_REQUIRED: "Name is required",
  PHONE_REQUIRED: "Phone is required",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
  CURRENT_PASSWORD_REQUIRED: "Current Password is required",
  COUNTRY_REQUIRED: "Country is required",
  CITY_REQUIRED: "City is required",
  NATIONALITY_REQUIRED: "Nationality is required",
  HEADING_REQUIRED: "Heading is required",
  SUB_HEADING_REQUIRED: "Sub Heading is required",
  DESCRIPTION_REQUIRED: "Description is required",
  CATEGORY_REQUIRED: "category is required",
  FACULTY_REQUIRED: "Faculty is required",
  ALL_FIELDS_REQUIRED: "All fields are required",
  PHONE_INVALID: "Enter Valid Phone Number",
  POSITION_REQUIRED: "Position is required",
  WEBSITE_REQUIRED: "Website is required",
  INSTITUTE_NAME_REQUIRED: "Institute name is required",
  COUNTRY_NAME_REQUIRED: "Country name is required",
  URL_REQUIRED: "URL is required",
  MAX_TWO_FILES_ALLOWED: "Maximum 2 Files Only Allowed to Upload",
  COURSE_NAME_REQUIRED: "Course name is required",
  NO_OF_CAMPUSES_REQUIRED: "No of campuses is required",
  SELECT_SPORT_FACILITIES: "Select Sport Facilities",
  TOTAL_STUDENTS_REQUIRED: "Total students is required",
  SELECT_ESTIMATED_COST: "Select estimated cost",
  FUNDING_REQUIRED: "Funding is required",
  INSTITUTE_REQUIRED: "Institute is required",
  STUDENT_ID_REQUIRED: "Student id is required",
  GRADUATION_YEAR_REQUIRED: "Graduation year is required",
  REQUEST_DOCUMENT_REQUIRED: "Requested Document is required",
  DEGREE_LEVEL_REQUIRED: "Degree Level is required",
  PASSWORD_UPDATE_SUCCESSFUL: "Password updated successfully",
  CURRENT_PASSWORD_INCORRECT: "Current password incorrect",
  ZOOM_ACCOUNT_AUTHORIZED_SUCCESSFUL: "Zoom Account Authorised Successfully",
  ZOOM_CREDENTIALS_SAVED_SUCCESSFUL: "Zoom Credentials Saved Successfully",
  PLEASE_ENTER_TITLE: "Please Enter Title.",
  PLEASE_ENTER_MEETING_DATE: "Please Enter Meeting Date.",
  PLEASE_ENTER_FROM_TIME: "Please Enter From Time.",
  PLEASE_ENTER_TO_TIME: "Please Enter To Time.",
  TO_TIME_LESS_THAN_FROM_TIME: "Please Enter To Time is less then From Time.",
  PLEASE_ENTER_TEAM_MEMBER: "Please Enter Team Member.",
  PLEASE_ENTER_CLIENT_EMAIL: "Please Enter Client Email-ID.",
  DELETED_SUCCESSFULLY: "Deleted successfully",
  LOCATION_REQUIRED: "Location is required",
  NO_OF_STUDENTS_REQUIRED: "No of students is required",
  ESTIMATED_LIVING_COST_REQUIRED: "Estimated living cost is required",
  SAVED_SUCCESSFULLY: "Saved Successfully",
  PLEASE_UPLOAD_FILES: "Please upload files",
  CODE_REQUIRED: "Code is required",
  PLEASE_ENTER_VALID_CODE: "Please enter valid code",
  ENTER_VALID_PHONE_NUMBER: "Enter a valid phone number between 9 to 14 digits",
  MESSAGE_REQUIRED: "Message is required",
  FILE_TOO_BIG: "File too Big, please select a file less than 5 MB",
  NO_OF_CLASSROOMS_REQUIRED: "No.of Classrooms is required",
  SPORTS_FACILITIES_REQUIRED: "Sports Facilities is required",
  FACILITIES_DELETED_SUCCESSFULLY: "Facilities deleted successfully",
  CAMPUS_LOCATION_REQUIRED: "Campus Location is required",
  CAMPUS_ADDRESS_REQUIRED: "Campus Address is required",
  MAX_ONE_FILE_UPLOAD_ALLOWED: "Maximum 1 Files Only Allowed to Upload",
  SELECT_CAMPUS: "Select campus",
  SELECT_STATUS: "Select status",
  UPDATED_SUCCESSFULLY: "Updated successfully",
  CONTACT_DELETED_SUCCESSFUL: "Contact deleted successfully",
  INTAKE_DATE_REQUIRED: "Intake date is required",
  ADMISSION_FEE_DATE_REQUIRED: "Admission fee date is required",
  ADMISSION_END_DATE_REQUIRED: "Admission End date is required",
  APPLICATION_START_DATE_REQUIRED: "Application start date is required",
  SEMESTER_CENSUS_DATE_REQUIRED: "Semester census date is required",
  STATUS_REQUIRED: "Status is required",
  APPLICATION_START_DATE_LESS_INTAKE_DATE:
    "Application start date should less than Intake date",
  APPLICATION_START_DATE_LESS_INTAKE_DATE_AND_HIGHER_APPLICATION_START_DATE:
    "Should be less then intake date and higher then Application start date",
  APPLICATION_START_DATE_LESS_INTAKE_DATE_AND_HIGHER_APPLICATION_END_DATE:
    "Should be less then intake date and higher then Application end date",
  APPLICATION_CENSUS_DATE_GREATER_THAN_INTAKE_DATE:
    "Application census date should greater than Intake date",
  INTAKES_DELETED_SUCCESSFULLY: "Intakes deleted successfully",
  PHONE_CODE_REQUIRED: "Phone code is required",
  PLEASE_ENTER_VALID_PHONE_CODE: "Please enter valid Phone code number",
  PLEASE_PROVIDE_VALID_WEBSITE:
    "Please provide valid website with http:// or https://",
  INVALID_WEBSITE: "Invalid website please enter correct url",
  MAIL_ADDED_TO_FAVOURITE_SUCCESSFULLY: "Mail added to favourite Successfully.",
  MAIL_REMOVED_FROM_FAVOURITE: "Mail removed from favourite Successfully.",
  MAIL_DELETED_SUCCESSFULLY: "Mail Deleted Successfully.",
  DATE_REQUIRED: "Date is required",
  TIME_REQUIRED: "Time is required",
  REASON_REQUIRED: "Reason is required",
  MAIL_SENT_SUCCESSFULLY: "Mail Sent Successfully.",
  MAIL_ADDED_IN_DRAFTS_SUCCESSFULLY: "Mail added in drafts Successfully.",
  PAYMENT_SUCCEEDED: "The payment was succeeded",
  PAYMENT_CANCELLED: "Payment cancelled",
  CARD_NUMBER_REQUIRED: "Card Number is required",
  CARD_NAME_REQUIRED: "Card Name is required",
  SECURITY_REQUIRED: "Security is required",
  CARD_NUMBER_MUST_A_DIGIT: "Card Number must be a digits",
  CARD_NUMBER_MUST_16_DIGITS: "Card Number must have 16 digits",
  CARD_NAME_MUST_A_TEXT: "Card Name must be a text",
  SECURITY_MUST_BE_A_DIGIT: "Security must be a digits",
  SECURITY_MUST_HAVE_3_DIGITS: "Security must have 3 digits",
  PLEASE_LOGIN: "Please Login",
  PLEASE_ENTER_VALID_PHONE_NUMBER: "Please enter valid phone number",
  ALREADY_RESETED: "Already reseted",
  DEPARTMENT_REQUIRED: "Department is required",
  COURSE_CATEGORY_REQUIRED: "Course category is required",
  PROGRAM_COORDINATOR_REQUIRED: "Program Coordinator is required",
  TENURE_REQUIRED: "Tenure is required",
  TUTION_FEE_REQUIRED: "Tution fee is required",
  APPLICATION_FEE_REQUIRED: "Application fee is required",
  PREREQUISTE_EDUCATION_REQUIRED: "Prerequiste Education  is required",
  GRADE_MINIMUM_REQUIRED: "Grade Minimun  is required",
  GRADE_RECOMMANDED_REQUIRED: "Grade Recommanded  is required",
  LISTENING_SCORE_MUST_BE_4_to_9: "Listening score must be 4 to 9",
  READING_SCORE_MUST_BE_4_to_9: "Reading score must be 4 to 9",
  WRITING_SCORE_MUST_BE_4_to_9: "Writing score must be 4 to 9",
  SPEAKING_SCORE_MUST_BE_4_to_9: "Speaking score must be 4 to 9",
  LISTENING_SCORE_MUST_BE_0_to_30: "Listening score must be 0 to 30",
  READING_SCORE_MUST_BE_0_to_30: "Reading score must be 0 to 30",
  WRITING_SCORE_MUST_BE_0_to_30: "Writing score must be 0 to 30",
  SPEAKING_SCORE_MUST_BE_0_to_30: "Speaking score must be 0 to 30",
  MINIMUM_SCORE_MUST_BE_GREATER_THAN_260:
    "Minimum score must be greater than 260",
  MINIMUM_SCORE_MUST_BE_GREATER_THAN_200:
    "Minimum score must be greater than 200",
  MAXIMUM_SCORE_MUST_BE_LESSER_THAN_340:
    "Maximum score must be lesser than 340",
  MAXIMUM_SCORE_MUST_BE_LESSER_THAN_300:
    "Maximum score must be lesser than 300",
  ERROR_IN_SAVE_COURSE: "Error in save Course",
  ERROR_IN_SAVE_FACULTY: "Error in save Faculty",
  ERROR_IN_SAVE_DEPARTMENT: "Error in save Department",
  FACULTY_DELETED_SUCCESSFULLY: "Faculty deleted successfully",
  DEPARTMENT_DELETED_SUCCESSFULLY: "Department deleted successfully",
  ENTER_VALID_NAME: "Enter valid name",
  ENTER_VALID_NUMBER: "Enter valid number",
  ENTER_VALID_CELLPHONE_NUMBER: "Enter valid cellphone number",
  ENTER_VALID_ADDRESS: "Enter valid address",
  ATTENDED_INSTITUTE_DATE:
    "Attended institute TO date should be greater than Attended institute FROM date",
  PLEASE_ENTER_RECEPIENT_MAIL: "Please Enter Recepient Mail.",
  ABOUT_SECTION_DELETED_SUCCESSFULLY: "About section deleted successfully",
  NOTIFICATION_REMOVED_SUCCESSFULLY: "Notification Removed Successfully",
  ACCOUNT_TYPE_REQUIRED: "Account type is required",
  USER_GROUP_REQUIRED: "User group is required",
  USER_ACCESS_REQUIRED: "User Access is required",
  THIS_IS_REQUIRED: "This is required",
  VALUE_IS_REQUIRED: "Value is required",
  GROUP_NAME_REQUIRED: "Group name is required",
  GROUP_NAME_ONLY_STRING: "Please only use alphabets and no special characters",
  GROUP_ROLES_REQUIRED: "Please add atleast one role to the group",
  CANNOT_REMOVE_ROLE: "Role cannot be remove from here",
  GROUP_REQUIRED: "Please select a group",
  ROLE_REQUIRED: "Please select a role to assign",
  VALID_IMAGE: "Please select valid image",
  CAMPUS_IMFO_REQUIRED: "Please enter campus information",
  VALID_IMAGE_SIZE: "File is too large, please select image less than 4 MB",
  ENTER_CORRECT_DATA_INTAKE_DATES:
    "Please enter correct information for the intake dates",
  QUESTION_IS_REQUIRED: "Please enter question",
  QUESTION_FORMAT_REQUIRED: "Please choose a format for the question",
  QUESTION_OPTIONS_REQUIRED: "Please add options for the question",
  QUESTION_OPTIONS_VALUE_REQUIRED:
    "Please enter option value for all options added",
  CONTACT_INFO_REQUIRED: "Please enter all contact information",
  FILE_NAME_REQUIRED: "Please enter file name",
  FILE_NAME_TEXT_ONLY: "Only letters are allowed",
  APPLICATION_DATE_LESS_START_DATE:
    "Application deadline date must be greater than application start date",
  CENSUS_DATE_MORE_START_DATE: "Census date must be greater than start date",
  CENSUS_DATE_MORE_END_DATE: "Census date must be greater than end date",
  CENSUS_DATE_MORE_DEADLINE_DATE:
    "Census date must be greater than deadline date",
  DEADLINE_MORE_START_LESS_CENSUS:
    "Deadline must be more than start date and less than  census date",
  SELECT_APP_TO_DELETE: "Please select the applications you want to delete",
  DEPARTMENT_NAME_REQUIRED: "Department Name is required",
  CAMPUS_REQUIRED: "Please add atleast one campus to the department",
  DEPARTMENT_COORDINATOR_REQUIRED:
    "Please select atleast one department coordinator",
  APPLICATION_ASSIGNMENT_REQUIRED: "Please assign application",
  PROGRAM_NAME_REQUIRED: "Program Name is required",
  INTAKE_DATES_REQUIRED: "Intake Dates is required",
  PROGRAM_DESCRIPTION_REQUIRED: "Program Descritpion is required",
  PROGRAM_CODE_REQUIRED: "Program Code is required",
  PROGRAM_CODE_VALIDATE:
    "The program code may only contain letters and numbers.",
  PROGRAM_STUDY_LEVEL_REQUIRED: "Study Level is required",
  TUITION_FEE_REQUIRED: "Tuition Fee/Currency is required",
  MATERIAL_FEE_REQUIRED: "Material Fee/Currency is required",
  EDUCATION_REQUIRED: "Education is required",
  EDUCATION_TYPE_REQUIRED: "Education Type is required",
  MINIMUM_GPA_REQUIRED: "Minimum GPA is required",
  RECOMMENDED_GPA_REQUIRED: "Recommended GPA is required",
  LANGUAGE_EXAM_REQUIRED: "Atleast select one exam",
  EXAM_NAME_REQUIRED: "Exam Name is required",
  MINIMUM_TOTAL_SCORE_REQUIRED: "Minimum Total Score is required",
  RECOMMENDED_TOTAL_SCORE_REQUIRED: "Recommended Total Score is required",
  MINIMUM_TOTAL_SCORE_VALIDATE:
    "Please enter minimum total score in between spacific range",
  RECOMMENDED_TOTAL_SCORE_VALIDATE:
    "Please enter recommended total score in between spacific range",
  FILE_REQUIRED: "Please select file to send",
  APPLICATION_MESSAGE_CONTENT_REQUIRED: "Please enter a message to send",
  APPLICATION_COMMENT_REQUIRED: "Please enter comment to add",
  APPLICATION_FEE_CURRENCY_REQUIRED: "Application Fee/Currency is required",
  TO_EMAILS_REQUIRED: "Please enter emails to send",
  EMAIL_CONTENT_REQUIRED: "Please enter content for the email",
  MEETING_TIMEZONE_REQUIRED: "Please select a timezone",
  MEETING_TIME_ERROR: "Please select start time less than end time",
  MEETING_PARTICIPANTS_REQUIRED: "Please select atleast one participant",
  COMPANY_NAME_REQUIRED: "Please enter company name",
  COMPANY_LOCATION_REQUIRED: "Please select atleast one location",
  POSTAL_INVALID: "Enter Valid Postal Code",
  RECRUITE_REQUIRED: "Recruiting Students is required",
  SERVICE_REQUIRED: "Clients services is required",
  ORG_REQUIRED: "Organization Belong To is required",
  RECRUIT_FROM_REQUIRED: "Recruit From is required",
  MARKETING_REQUIRED: "Marketing Method is required",
  REFERENCENAME_REQUIRED: "Referance name is required",
  REFERENCEINST_REQUIRED: "Reference institute is required",
  REFERENCEEMAIL_REQUIRED: "Reference emailis required",
  REFERENCEPHONE_REQUIRED: "Reference phone is required",
  POSTAL_REQUIRED: "Please enter postcode",
  CELLPHONE_REQUIRED: "Please enter cell phone",
  STUDENT_REQUIRED: "Please select a source of student admissions",
  REFERED_REQUIRED: "Please enter preference",
};
export const CONFIRMATION_MESSAGES = {
  REMOVE_INTAKE_DATE_CONFIRMATION:
    "Are you sure you want to remove the Intake date?",
  PERMANENTLY_DELETE_MAIL:
    "Are you sure you want to permanently delete this email?",
  SAVE_THE_MAIL_TO_DRAFT_OR_DONT_WANT_TO_SAVE:
    'Are you sure ?. You want to save the mail to Draft "Click Save" or Dont want to save "Click Cancel"',
  REMOVE_ABOUT_SECTION_CONFIRMATION:
    "Are you sure you want to remove about section?",
  REMOVE_CONTACT_CONFIRMATION: "Are you sure you want to remove the contact?",
  REMOVE_NOTIFICATION_CONFIRMATION:
    "Are you sure ?. You need to Remove Notification",
  DO_YOU_WANT_TO_CONTINUE: "Do you want to continue?",
  ARE_YOU_SURE_YOU_WANT_TO_DO_THIS: "Are you sure to do this.",
  DELETE_APPLICATION_QUESTION: "Are you sure you want to delete this quesion?",
  DELETE_APPLICATION_FILE: "Are you sure you want to delete this file?",
  DELETE_APPLICATIONS: "Are you sure you want to delete selected applications?",
  DELETE_APPLICATION_COMMENT: "Are you sure you want to delete this comment?",
  MARK_DEFAULT_PAYMENT: "Are you sure to make this card as default payment?",
};
export const MEETING_DELAY_REASONS = [
  {
    value: "I am not feeling well.",
    label: "I am not feeling well.",
    checked: false,
  },
  {
    value: "I am behind schedule due to personal reasons.",
    label: "I am behind schedule due to personal reasons.",
    checked: false,
  },
  {
    value: "There was an error while scheduling.",
    label: "There was an error while scheduling.",
    checked: false,
  },
  {
    value: "I need more time to prepare all the necessary information.",
    label: "I need more time to prepare all the necessary information.",
    checked: false,
  },
];
// export const MAIL_ARRANGEMENT_OPTIONS = ["Recently", "2 Days before", "1 Week before"]
export const SUBJECTS = [
  { label: "Complaints ", value: 1 },
  { label: "Reports of Abuse", value: 2 },
  { label: "Functionality Error", value: 3 },
  { label: "Block Requests", value: 4 },
];
export const MONTHS = [
  "January",
  "Febrary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];

export const YEARS = [
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];
export const REQUIRED_MESSAGE = (fieldName) => fieldName + " is required";
export const MAIL_ARRANGEMENT_OPTIONS = [
  "Newest on top",
  "Oldest on top   ",
  "Unread",
  "Sent to me",
  "Mentions",
  "Attachments",
];
