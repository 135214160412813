import { ADD_STAR_CHAT, ADD_STAR_CHAT_ERROR, ADD_STAR_CHAT_SUCCESS, DELETE_CHAT, DELETE_CHAT_ERROR, DELETE_CHAT_SUCCESS, GET_CHAT_MESSAGES_LIST, GET_CHAT_MESSAGES_LIST_ERROR, GET_CHAT_MESSAGES_LIST_SUCCESS, GET_CHAT_SESSIONS_LIST, GET_CHAT_SESSIONS_LIST_ERROR, GET_CHAT_SESSIONS_LIST_SUCCESS } from "../constants";

export function getChatSessionListAction(session, resolve, reject) {
  return {
    type: GET_CHAT_SESSIONS_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getChatSessionListSuccess(data) {
  return {
    type: GET_CHAT_SESSIONS_LIST_SUCCESS,
    payload: data
  };
}

export function getChatSessionListError(error) {
  return {
    type: GET_CHAT_SESSIONS_LIST_ERROR,
    payload: error
  };
}

export function getChatMessagesListAction(session, resolve, reject) {
  return {
    type: GET_CHAT_MESSAGES_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getChatMessagesListSuccess(data) {
  return {
    type: GET_CHAT_MESSAGES_LIST_SUCCESS,
    payload: data
  };
}

export function getChatMessagesListError(error) {
  return {
    type: GET_CHAT_MESSAGES_LIST_ERROR,
    payload: error
  };
}

export function deleteChatAction(session, resolve, reject) {
  return {
    type: DELETE_CHAT,
    payload: session,
    resolve,
    reject
  };
}

export function deleteChatSuccess(data) {
  return {
    type: DELETE_CHAT_SUCCESS,
    payload: data
  };
}

export function deleteChatError(error) {
  return {
    type: DELETE_CHAT_ERROR,
    payload: error
  };
}

export function starChatAction(session, resolve, reject) {
  return {
    type: ADD_STAR_CHAT,
    payload: session,
    resolve,
    reject
  };
}

export function starChatSuccess(data) {
  return {
    type: ADD_STAR_CHAT_SUCCESS,
    payload: data
  };
}

export function starChatError(error) {
  return {
    type: ADD_STAR_CHAT_ERROR,
    payload: error
  };
}
