import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Badge, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import jQuery from "jquery";
import { ReactComponent as Notificationicon } from "../../assets/img/noti-icon.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getNotificationListAction
} from "../../store/actions";
import moment from "moment";
class GetNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
      agentId: 0,
      total: 0,
      notifications: []
    }
  }

  componentDidMount() {
    let param = localStorage.getItem("user_id");
    this.setState({
      agentId: param
    })
  }

  getNotifications = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
    this.props.getNotificationListAction({
      id: this.state.agentId,
      type: 1,
      limit: 4
    }, (response) => {
      console.log('resposne notify', response);
      if (response.success) {
        this.setState({
          notifications: response.data.data,
          total: response.data.total
        })
      }
    }, (err) => {

    })
  }

  render() {
    let id=""
    const useraccess = this.state.user_access;

    //let popoverOpen = this.state.popoverOpen;



    if (jQuery.inArray(id, useraccess) !== -1) {
      return (
        <NavLink to="/profilenotification" className="nav-link"
          onClick={() => {
            this.getNotifications()
          }}
        >
          <Notificationicon className="uni-icon path" />
          {this.state.notificationCount <= 0 ? null : (
            <Badge pill color="danger">{this.state.notificationCount}</Badge>
          )}
        </NavLink>
      );
    } else {
      return (
        <>
          <NavLink id="notification" to="#notification" className="nav-link"
            onClick={() => {
              this.getNotifications()
              this.setState({
                popoverOpen: true
              });
            }}
          >
            <Notificationicon className="uni-icon path" />
            {this.state.notificationCount ? <Badge pill color="danger">{this.state.notificationCount}</Badge> : null}
          </NavLink>
          <Popover placement="bottom" isOpen={this.state.popoverOpen} target="notification"
            className="notification"
            toggle={() => {
              this.setState({
                popoverOpen: false
              });
            }}
          >
            <PopoverBody>
              <ul>
                {
                  this.state.notifications.map((val, index) => {
                    return (
                      <li 
                        className={val.status == 0 ? "unread" : "read"}
                      >
                        {
                          val.sender_user ? (
                            <div 
                              className="avatarText" 
                              style={{ backgroundColor: val.sender_user.color_code }}
                            >
                                {
                                  val.sender_user.first_name.charAt(0)
                                }
                            </div>
                          ) : null
                        }
                        
                        <div className="details">
                          <p>
                            {
                              val.notification_text
                            }
                          </p>
                          <date>
                            {
                              moment(val.created_at).format('dddd, MMMM Do YYYY, h:mm a')
                            }
                          </date>
                        </div>
                      </li>
                    )
                  })
                }
                
              </ul>
            </PopoverBody>
            <PopoverHeader className="text-center">
              <Link 
                to="/profilenotification"
                onClick={() => {
                  this.setState({
                    popoverOpen: false
                  });
                }}
              >
                View All Notifications
              </Link>
            </PopoverHeader>
          </Popover>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
	return {
		user: state.authReducer.user
	};
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getNotificationListAction
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(GetNotification)