import { put, takeLatest, all, call } from "redux-saga/effects";
// import axios from "axios";
import axios from "../../Api/axios";
import {
  ADD_CLIENT_COMMENT,
  DELETE_CLIENT,
  DELETE_CLIENT_COMMENT,
  GET_CLIENT_COMMENT,
  GET_CLIENT_COUNTRY_LIST,
  GET_CLIENT_DETAILS,
  GET_CLIENT_LIST,
  GET_TERMINATE_REASONS,
  MATCH_CLIENT_PROFILE,
  RAISE_DISPUTE,
  SCHOOL_LIST,
  TERMINATE_AGREEMENT,
  UPDATE_CLIENT_FAVOURITE,
} from "../constants";
import {
  ADD_CLIENT_COMMENT_API,
  baseUrl,
  DELETE_CLIENT_API,
  DELETE_CLIENT_COMMENT_API,
  GET_CLIENT_COMMENT_API,
  GET_CLIENT_DETAILS_API,
  GET_CLIENT_LIST_API,
  GET_CLIENT_LOCATION_API,
  GET_TERMINATE_REASONS_API,
  MATCH_CLIENT_PROFILE_API,
  RAISE_DISPUTE_API,
  SCHOOL_LIST_API,
  TERMINATE_AGREEMENT_API,
  UPDATE_CLIENT_FAVOURITE_API,
} from "../../Api";
import {
  addClientCommentError,
  addClientCommentSuccess,
  deleteClientCommentError,
  deleteClientCommentSuccess,
  deleteClientError,
  deleteClientSuccess,
  getClientCommentError,
  getClientCommentSuccess,
  getClientCountryListError,
  getClientCountryListSuccess,
  getClientDetailsError,
  getClientDetailsSuccess,
  getClientListError,
  getClientListSuccess,
  getTerminateReasonsError,
  getTerminateReasonsSuccess,
  handleErrorResponse,
  handleSuccessResponse,
  matchClientProfileError,
  matchClientProfileSuccess,
  raiseDisputeError,
  raiseDisputeSuccess,
  schoolListError,
  schoolListSuccess,
  showHideLoadingAction,
  terminateAgreementError,
  terminateAgreementSuccess,
  updateClientFavouriteError,
  updateClientFavouriteSuccess,
} from "../actions";

function getClientListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_CLIENT_LIST_API,
    params: params,
  });
}

function updateClientFavouriteApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + UPDATE_CLIENT_FAVOURITE_API,
    data: params,
  });
}

function* updateClientFavourite(action) {
  let { payload, resolve, reject } = action;

  try {
    let response = yield call(updateClientFavouriteApi, payload);

    yield put(updateClientFavouriteSuccess(response.data));
    if (resolve) resolve(response.data);
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateClientFavouriteError(e));
    if (reject) reject(e);
  }
}

export function* updateClientFavouriteWatcher() {
  yield takeLatest(UPDATE_CLIENT_FAVOURITE, updateClientFavourite);
}

function* getClientList(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getClientListApi, payload);

    yield put(getClientListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(getClientListError(e));
    if (reject) reject(e);
  }
}

export function* getClientListActionWatcher() {
  yield takeLatest(GET_CLIENT_LIST, getClientList);
}

function getClientCountryListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_CLIENT_LOCATION_API,
    params: params,
  });
}

function* getClientCountryList(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getClientCountryListApi, payload);

    yield put(getClientCountryListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(getClientCountryListError(e));
    if (reject) reject(e);
  }
}

export function* getClientCountryListActionWatcher() {
  yield takeLatest(GET_CLIENT_COUNTRY_LIST, getClientCountryList);
}

function deleteClientApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl + DELETE_CLIENT_API,
    data: params,
  });
}

function* deleteClient(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(deleteClientApi, payload);

    yield put(deleteClientSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(deleteClientError(e));
    if (reject) reject(e);
  }
}

export function* deleteClientActionWatcher() {
  yield takeLatest(DELETE_CLIENT, deleteClient);
}

function getClientDetailsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_CLIENT_DETAILS_API + "/" + params.tab,
    params: params,
  });
}

function* getClientDetails(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getClientDetailsApi, payload);

    yield put(getClientDetailsSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(getClientDetailsError(e));
    if (reject) reject(e);
  }
}

export function* getClientDetailsActionWatcher() {
  yield takeLatest(GET_CLIENT_DETAILS, getClientDetails);
}

function getTerminateReasonsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_TERMINATE_REASONS_API,
    params: params,
  });
}

function* getTerminateReasons(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getTerminateReasonsApi, payload);

    yield put(getTerminateReasonsSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(getTerminateReasonsError(e));
    if (reject) reject(e);
  }
}

export function* getTerminateReasonsActionWatcher() {
  yield takeLatest(GET_TERMINATE_REASONS, getTerminateReasons);
}

function terminateAgreementApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + TERMINATE_AGREEMENT_API,
    data: params,
  });
}

function* terminateAgreement(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(terminateAgreementApi, payload);

    yield put(terminateAgreementSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(terminateAgreementError(e));
    if (reject) reject(e);
  }
}

export function* terminateAgreementActionWatcher() {
  yield takeLatest(TERMINATE_AGREEMENT, terminateAgreement);
}

function raiseDisputeApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + RAISE_DISPUTE_API,
    data: params,
  });
}

function* raiseDispute(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(raiseDisputeApi, payload);

    yield put(raiseDisputeSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(raiseDisputeError(e));
    if (reject) reject(e);
  }
}

export function* raiseDisputeActionWatcher() {
  yield takeLatest(RAISE_DISPUTE, raiseDispute);
}

function matchClientProfileApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + MATCH_CLIENT_PROFILE_API,
    params: params,
  });
}

function* matchClientProfile(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(matchClientProfileApi, payload);

    yield put(matchClientProfileSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(matchClientProfileError(e));
    if (reject) reject(e);
  }
}

export function* matchClientProfileActionWatcher() {
  yield takeLatest(MATCH_CLIENT_PROFILE, matchClientProfile);
}

function schoolListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + SCHOOL_LIST_API,
    params: params,
  });
}

function* schoolList(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(schoolListApi, payload);

    yield put(schoolListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(schoolListError(e));
    if (reject) reject(e);
  }
}

export function* schoolListActionWatcher() {
  yield takeLatest(SCHOOL_LIST, schoolList);
}

function addClientCommentApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + ADD_CLIENT_COMMENT_API,
    data: params,
  });
}

function* addClientComment(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(addClientCommentApi, payload);

    yield put(addClientCommentSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(addClientCommentError(e));
    if (reject) reject(e);
  }
}

export function* addClientCommentActionWatcher() {
  yield takeLatest(ADD_CLIENT_COMMENT, addClientComment);
}

function getClientCommentApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_CLIENT_COMMENT_API,
    params: params,
  });
}

function* getClientComment(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getClientCommentApi, payload);

    yield put(getClientCommentSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(getClientCommentError(e));
    if (reject) reject(e);
  }
}

export function* getClientCommentActionWatcher() {
  yield takeLatest(GET_CLIENT_COMMENT, getClientComment);
}

function deleteClientCommentApi(params) {
  return axios.request({
    method: "delete",
    url: DELETE_CLIENT_COMMENT_API,
    data: params,
  });
}

function* deleteClientComment(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(deleteClientCommentApi, payload);

    yield put(deleteClientCommentSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data);
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(deleteClientCommentError(e));
    if (reject) reject(e);
  }
}

export function* deleteClientCommentActionWatcher() {
  yield takeLatest(DELETE_CLIENT_COMMENT, deleteClientComment);
}
