import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_PAYMENT_COMMISSION_LIST,
  GET_AGENT_COUNTRY_LIST,
  GET_PAYMENT_COMMISSION_DETAIL,
  GET_PAYMENT_OVERVIEW_MONTHLY,
  GET_COUNTRY_BASED_COMMISSION,
  AGENT_SEND_INVOICE,
  GET_AGENT_TOTAL_INCOME,
  GET_AGENT_INCOME_CURRENCY,
  GET_AGENT_EARNING_MONTHLY,

  CREATE_PAYMENT_METHOD,
  GET_PAYMENT_LIST,
  GET_PAYMENT_DETAILS,
  UPDATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
	SAVE_CARD,
} from "../constants";
import {
  baseUrl,
  GET_PAYMENT_COMMISSION_LIST_API,
  GET_AGENT_COUNTRY_LIST_API,
  GET_PAYMENT_COMMISSION_DETAIL_API,
  GET_PAYMENT_OVERVIEW_MONTHLY_API,
  GET_COUNTRY_BASED_COMMISSION_API,
  AGENT_SEND_INVOICE_API,
  GET_AGENT_TOTAL_INCOME_API,
  GET_AGENT_INCOME_CURRENCY_API,
  GET_AGENT_EARNING_MONTHLY_API,

  CREATE_PAYMENT_METHOD_API,
  GET_PAYMENT_LIST_API,
  GET_PAYMENT_DETAILS_API,
  UPDATE_PAYMENT_METHOD_API,
  DELETE_PAYMENT_METHOD_API,
	SAVE_CARD_API,
} from "../../Api";
// import axios from "axios";
import axios from "../../Api/axios"
import {
  showHideLoadingAction,
  handleErrorResponse,
  handleSuccessResponse,
  getPaymentCommissionListSuccess,
  getPaymentCommissionListError,
  getAgentCountryListSuccess,
  getAgentCountryListError,
  getPaymentCommissionDetailSuccess,
  getPaymentCommissionDetailError,
  getPaymentOverviewMonthlySuccess,
  getPaymentOverviewMonthlyError,
  getCountryBasedCommissionListSuccess,
  getCountryBasedCommissionListError,
  agentSendInvoiceSuccess,
  agentSendInvoiceError,
  getAgentTotalIncomeSuccess,
  getAgentTotalIncomeError,
  getAgentIncomeCurrencySuccess,
  getAgentIncomeCurrencyError,
  getAgentEarningMonthlySuccess,
  getAgentEarningMonthlyError,

	createPaymentMethodSuccess,
	createPaymentMethodError,
	getPaymentListSuccess,
	getPaymentListError,
	getPaymentDetailsSuccess,
	getPaymentDetailsError,
	updatePaymentMethodSuccess,
	updatePaymentMethodError,
	deletePaymentMethodSuccess,
	deletePaymentMethodError,
	saveCardError,
	saveCardSuccess,
} from '../actions';

function getPaymentCommissionListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_PAYMENT_COMMISSION_LIST_API,
    params: params
  });
}

function* getPaymentCommissionList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getPaymentCommissionListApi, payload);
    yield put(getPaymentCommissionListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getPaymentCommissionListError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getPaymentCommissionListWatcher() {
  yield takeLatest(GET_PAYMENT_COMMISSION_LIST, getPaymentCommissionList)
}

function getAgentCountryListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_AGENT_COUNTRY_LIST_API + "/" + params.agent_id,
  });
}

function* getAgentCountryList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getAgentCountryListApi, payload);
    yield put(getAgentCountryListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getAgentCountryListError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getAgentCountryListWatcher() {
  yield takeLatest(GET_AGENT_COUNTRY_LIST, getAgentCountryList)
}

function getPaymentCommissionDetailApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_PAYMENT_COMMISSION_DETAIL_API,
    params: params
  });
}

function* getPaymentCommissionDetail(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getPaymentCommissionDetailApi, payload);
    yield put(getPaymentCommissionDetailSuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getPaymentCommissionDetailError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getPaymentCommissionDetailWatcher() {
  yield takeLatest(GET_PAYMENT_COMMISSION_DETAIL, getPaymentCommissionDetail)
}

function getPaymentOverviewMonthlyApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_PAYMENT_OVERVIEW_MONTHLY_API,
    params: params
  });
}

function* getPaymentOverviewMonthly(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getPaymentOverviewMonthlyApi, payload);
    yield put(getPaymentOverviewMonthlySuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getPaymentOverviewMonthlyError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getPaymentOverviewMonthlyWatcher() {
  yield takeLatest(GET_PAYMENT_OVERVIEW_MONTHLY, getPaymentOverviewMonthly)
}

function getCountryBasedCommissionListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_COUNTRY_BASED_COMMISSION_API,
    params: params
  });
}

function* getCountryBasedCommissionList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getCountryBasedCommissionListApi, payload);
    yield put(getCountryBasedCommissionListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getCountryBasedCommissionListError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getCountryBasedCommissionListWatcher() {
  yield takeLatest(GET_COUNTRY_BASED_COMMISSION, getCountryBasedCommissionList)
}

function agentSendInvoiceApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+AGENT_SEND_INVOICE_API,
    data: params
  });
}

function* agentSendInvoice(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(agentSendInvoiceApi, payload);
    yield put(agentSendInvoiceSuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(agentSendInvoiceError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* agentSendInvoiceWatcher() {
  yield takeLatest(AGENT_SEND_INVOICE, agentSendInvoice)
}

function getAgentIncomeCurrencyApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_AGENT_INCOME_CURRENCY_API,
    params: params
  });
}

function* getAgentIncomeCurrency(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getAgentIncomeCurrencyApi, payload);
    yield put(getAgentIncomeCurrencySuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getAgentIncomeCurrencyError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getAgentIncomeCurrencyWatcher() {
  yield takeLatest(GET_AGENT_INCOME_CURRENCY, getAgentIncomeCurrency)
}

function getAgentTotalIncomeApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_AGENT_TOTAL_INCOME_API,
    params: params
  });
}

function* getAgentTotalIncome(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getAgentTotalIncomeApi, payload);
    yield put(getAgentTotalIncomeSuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getAgentTotalIncomeError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getAgentTotalIncomeWatcher() {
  yield takeLatest(GET_AGENT_TOTAL_INCOME, getAgentTotalIncome)
}

function getAgentEarningMonthlyApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_AGENT_EARNING_MONTHLY_API,
    params: params
  });
}

function* getAgentEarningMonthly(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getAgentEarningMonthlyApi, payload);
    yield put(getAgentEarningMonthlySuccess(response.data));
    yield put(showHideLoadingAction(false));
    //handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getAgentEarningMonthlyError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* getAgentEarningMonthlyWatcher() {
  yield takeLatest(GET_AGENT_EARNING_MONTHLY, getAgentEarningMonthly)
}


function createPaymentMethodActionApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + CREATE_PAYMENT_METHOD_API,
	  data: params,
	  validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 423
      },
	});
}

function* createPaymentMethodAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(createPaymentMethodActionApi, payload);
		yield put(createPaymentMethodSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(createPaymentMethodError(e))
		if (reject) reject(e)
	}
}

export function* createPaymentMethodActionWatcher() {
	yield takeLatest(CREATE_PAYMENT_METHOD, createPaymentMethodAction)
}

function getPaymentListActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_PAYMENT_LIST_API,
	  params: params
	});
}

function* getPaymentListAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getPaymentListActionApi, payload);
		yield put(getPaymentListSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getPaymentListError(e))
		if (reject) reject(e)
	}
}

export function* getPaymentListActionWatcher() {
	yield takeLatest(GET_PAYMENT_LIST, getPaymentListAction)
}

function getPaymentDetailsActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_PAYMENT_DETAILS_API + "/" + params.payment_id
	});
}

function* getPaymentDetailsAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(getPaymentDetailsActionApi, payload);
		yield put(getPaymentDetailsSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(getPaymentDetailsError(e))
		if (reject) reject(e)
	}
}

export function* getPaymentDetailsActionWatcher() {
	yield takeLatest(GET_PAYMENT_DETAILS, getPaymentDetailsAction)
}

function updatePaymentMethodActionApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + UPDATE_PAYMENT_METHOD_API + "/" + params.payment_id,
	  data: params,
	  validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 423
      },
	});
}

function* updatePaymentMethodAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(updatePaymentMethodActionApi, payload);
		yield put(updatePaymentMethodSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(updatePaymentMethodError(e))
		if (reject) reject(e)
	}
}

export function* updatePaymentMethodActionWatcher() {
	yield takeLatest(UPDATE_PAYMENT_METHOD, updatePaymentMethodAction)
}

function deletePaymentMethodActionApi(params) {
	return axios.request({
	  method: "DELETE",
	  url: baseUrl + DELETE_PAYMENT_METHOD_API + "/" + params.payment_id
	});
}

function* deletePaymentMethodAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(deletePaymentMethodActionApi, payload);
		yield put(deletePaymentMethodSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		yield put(deletePaymentMethodError(e))
		if (reject) reject(e)
	}
}

export function* deletePaymentMethodActionWatcher() {
	yield takeLatest(DELETE_PAYMENT_METHOD, deletePaymentMethodAction)
}

function saveCardApi(params) {
	return axios.request({
	  method: "post",
	  url: baseUrl + SAVE_CARD_API,
	  data: params
	});
}

function* saveCardAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
		let response = yield call(saveCardApi, payload);
		yield put(saveCardSuccess(response.data));
		if (resolve) resolve(response.data)
	} catch (e) {
		handleErrorResponse(e.response)
		yield put(saveCardError(e))
		if (reject) reject(e)
	}
}

export function* saveCardWatcher() {
	yield takeLatest(SAVE_CARD, saveCardAction)
}
