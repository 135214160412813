import { put, takeLatest, call } from 'redux-saga/effects';
import {
  ADD_ROLES_TO_GROUP,
  CREATE_GROUP,
  DELETE_GROUP,
  DELETE_ROLE,
  DELETE_SAFF,
  DELETE_STAFF,
  DELETE_STAFF_INVITE,
  GET_GROUP_INVITE_LIST,
  GET_GROUP_LIST,
  GET_GROUP_ROLES_LIST,
  GET_OFFICE_LIST,
  GET_ROLE_LIST,
  GET_ROLE_PERMISSION,
  GET_STAFF_LIST,
  RESEND_STAFF_INVITE,
  SEND_STAFF_INVITE,
  SET_ROLE_PERMISSION,
  STAFF_INVITE_LIST,
  UPDATE_STAFF,
  UPDATE_STAFF_SUCCESS
} from "../constants";
import {
  ADD_ROLE_TO_GROUP_API,
  baseUrl,
  CREATE_GROUP_API,
  DELETE_GROUP_API,
  DELETE_ROLE_API,
  DELETE_SATFF_API,
  DELETE_STAFF_INVITE_API,
  GET_GROUP_LIST_API,
  GET_GROUP_ROLES_LIST_API,
  GET_OFFICE_LIST_API,
  GET_ROLE_LIST_API,
  GET_ROLE_PERMISSIONS_API,
  GET_STAFF_LIST_API,
  SEND_STAFF_INVITE_API,
  SEND_STAFF_RESEND_INVITE_API,
  SET_ROLE_PERMISSIONS_API,
  STAFF_INVITE_LIST_API,
  UPDATE_STAFF_API
} from "../../Api";
// import axios from "axios";
import axios from "../../Api/axios"
import {
  createGroupSuccess,
  createGroupError,
  getGroupListSuccess,
  getGroupListError,
  getRoleListSuccess,
  getRoleListError,
  sendStaffInviteSuccess,
  sendStaffInviteError,
  getGroupRolesListSuccess,
  getGroupRolesListError,
  addRolesToGroupSuccess,
  addRolesToGroupError,
  getRolePermissionSuccess,
  getRolePermissionError,
  setRolePermissionSuccess,
  setRolePermissionError,
  getStaffListSuccess,
  getStaffListError,
  deleteGroupSuccess,
  deleteGroupError,
  deleteRoleSuccess,
  deleteRoleError,
  getGroupInviteListSuccess,
  getGroupInviteListError,
  getStaffInviteListSuccess,
  getStaffInviteListError,
  deleteStaffSuccess,
  deleteStaffError,
  resendStaffInviteError,
  resendStaffInviteSuccess,
  handleErrorResponse,
  updateStaffSuccess,
  updateStaffError,
  showHideLoadingAction,
  getOfficeListError,
  getOfficeListSuccess
} from "../actions";

function createGroupApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + CREATE_GROUP_API,
    data: params
  });
}

function* createGroup(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(createGroupApi, payload);
    yield put(createGroupSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(createGroupError(e))
    if (reject) reject(e)
  }
}

export function* createGroupActionWatcher() {
  yield takeLatest(CREATE_GROUP, createGroup)
}

function getGroupListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_GROUP_LIST_API,
    params: params
  });
}

function* getGroupList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getGroupListApi, payload);
    yield put(getGroupListSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(getGroupListError(e))
    if (reject) reject(e)
  }
}

export function* getGroupListActionWatcher() {
  yield takeLatest(GET_GROUP_LIST, getGroupList)
}

function getRoleListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_ROLE_LIST_API,
    params: params
  });
}

function* getRoleList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getRoleListApi, payload);
    yield put(getRoleListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    yield put(getRoleListError(e))
    if (reject) reject(e)
  }
}

export function* getRoleListActionWatcher() {
  yield takeLatest(GET_ROLE_LIST, getRoleList)
}

function sendStaffInviteApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SEND_STAFF_INVITE_API,
    data: params
  });
}

function* sendStaffInvite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(sendStaffInviteApi, payload);
    if (response.data.success) {
      yield put(sendStaffInviteSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(sendStaffInviteError(response))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(sendStaffInviteError(e))
    if (reject) reject(e)
  }
}

export function* sendStaffInviteActionWatcher() {
  yield takeLatest(SEND_STAFF_INVITE, sendStaffInvite)
}

function getGroupRolesListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_GROUP_ROLES_LIST_API + "/" + params.agent_id,
    params: params
  });
}

function* getGroupRolesList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getGroupRolesListApi, payload);
    if (response.data.success) {
      yield put(getGroupRolesListSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(getGroupRolesListError(response.data))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(sendStaffInviteError(e))
    if (reject) reject(e)
  }
}

export function* getGroupRolesListActionWatcher() {
  yield takeLatest(GET_GROUP_ROLES_LIST, getGroupRolesList)
}

function addRolesToGroupApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + ADD_ROLE_TO_GROUP_API,
    data: params
  });
}

function* addRolesToGroup(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(addRolesToGroupApi, payload);
    if (response.data.success) {
      yield put(addRolesToGroupSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(addRolesToGroupError(response.data))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(addRolesToGroupError(e))
    if (reject) reject(e)
  }
}

export function* addRolesToGroupActionWatcher() {
  yield takeLatest(ADD_ROLES_TO_GROUP, addRolesToGroup)
}

function getRolePermissionApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_ROLE_PERMISSIONS_API + "/" + params.roleId,
    data: params
  });
}

function* getRolePermission(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getRolePermissionApi, payload);
    if (response.data.success) {
      yield put(getRolePermissionSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(getRolePermissionError(response.data))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(getRolePermissionError(e))
    if (reject) reject(e)
  }
}

export function* getRolePermissionActionWatcher() {
  yield takeLatest(GET_ROLE_PERMISSION, getRolePermission)
}

function setRolePermissionApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SET_ROLE_PERMISSIONS_API,
    data: params
  });
}

function* setRolePermission(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(setRolePermissionApi, payload);
    if (response.data.success) {
      yield put(setRolePermissionSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(setRolePermissionError(response.data))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(setRolePermissionError(e))
    if (reject) reject(e)
  }
}

export function* setRolePermissionActionWatcher() {
  yield takeLatest(SET_ROLE_PERMISSION, setRolePermission)
}

function getStaffListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_STAFF_LIST_API,
    params: params
  });
}

function* getStaffList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getStaffListApi, payload);
    if (response.data.success) {
      yield put(getStaffListSuccess(response.data));
      yield put(showHideLoadingAction(false));
      if (resolve) resolve(response)
    } else {
      yield put(showHideLoadingAction(false));
      yield put(getStaffListError(response.data))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(getStaffListError(e))
    if (reject) reject(e)
  }
}

export function* getStaffListActionWatcher() {
  yield takeLatest(GET_STAFF_LIST, getStaffList)
}

function deleteGroupApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl + DELETE_GROUP_API,
    data: params
  });
}

function* deleteGroup(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(deleteGroupApi, payload);
    if (response.data.success) {
      yield put(deleteGroupSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(deleteGroupError(response.data))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(deleteGroupError(e))
    if (reject) reject(e)
  }
}

export function* deleteGroupActionWatcher() {
  yield takeLatest(DELETE_GROUP, deleteGroup)
}

function deleteRoleApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl + DELETE_ROLE_API,
    data: params
  });
}

function* deleteRole(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(deleteRoleApi, payload);
    if (response.data.success) {
      yield put(deleteRoleSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      yield put(deleteRoleError(response.data))
      if (reject) reject(response.data)  
    }
    
  } catch (e) {
    yield put(deleteRoleError(e))
    if (reject) reject(e)
  }
}

export function* deleteRoleActionWatcher() {
  yield takeLatest(DELETE_ROLE, deleteRole)
}

function getGroupInviteApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_GROUP_LIST_API,
    params: params
  });
}

function* getGroupInvite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getGroupInviteApi, payload);
    if (response.data.success) {
      yield put(getGroupInviteListSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      yield put(getGroupInviteListError(response.data))
      if (reject) reject(response.data)  
    }
    
  } catch (e) {
    yield put(getGroupInviteListError(e))
    if (reject) reject(e)
  }
}

export function* getGroupInviteActionWatcher() {
  yield takeLatest(GET_GROUP_INVITE_LIST, getGroupInvite)
}

function getStaffInviteApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + STAFF_INVITE_LIST_API,
    params: params
  });
}

function* getStaffInvite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getStaffInviteApi, payload);
    if (response.data.success) {
      yield put(getStaffInviteListSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      yield put(getStaffInviteListError(response.data))
      if (reject) reject(response.data)  
    }
    
  } catch (e) {
    yield put(getStaffInviteListError(e))
    if (reject) reject(e)
  }
}

export function* getStaffInviteListActionWatcher() {
  yield takeLatest(STAFF_INVITE_LIST, getStaffInvite)
}

function deleteStaffApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl + DELETE_SATFF_API,
    data: params
  });
}

function* deleteStaff(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(deleteStaffApi, payload);
    if (response.data.success) {
      yield put(deleteStaffSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      yield put(deleteStaffError(response.data))
      if (reject) reject(response.data)  
    }
    
  } catch (e) {
    yield put(deleteStaffError(e))
    if (reject) reject(e)
  }
}

export function* deleteStaffActionWatcher() {
  yield takeLatest(DELETE_STAFF, deleteStaff)
}

function deleteStaffInviteApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl + DELETE_STAFF_INVITE_API,
    data: params
  });
}

function* deleteStaffInvite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(deleteStaffInviteApi, payload);
    if (response.data.success) {
      yield put(deleteStaffSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      yield put(deleteStaffError(response.data))
      if (reject) reject(response.data)  
    }
    
  } catch (e) {
    yield put(deleteStaffError(e))
    if (reject) reject(e)
  }
}

export function* deleteStaffInviteActionWatcher() {
  yield takeLatest(DELETE_STAFF_INVITE, deleteStaffInvite)
}

function resendStaffInviteApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + SEND_STAFF_RESEND_INVITE_API,
    data: params
  });
}

function* resendStaffInvite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(resendStaffInviteApi, payload);
    if (response.data.success) {
      yield put(resendStaffInviteSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(resendStaffInviteError(response))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(resendStaffInviteError(e))
    if (reject) reject(e)
  }
}

export function* resendStaffInviteActionWatcher() {
  yield takeLatest(RESEND_STAFF_INVITE, resendStaffInvite)
}

function updateStaffApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl + UPDATE_STAFF_API,
    data: params
  });
}

function* updateStaff(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(updateStaffApi, payload);
    if (response.data.success) {
      yield put(updateStaffSuccess(response.data));
      if (resolve) resolve(response)
    } else {
      yield put(updateStaffError(response))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateStaffError(e))
    if (reject) reject(e)
  }
}

export function* updateStaffActionWatcher() {
  yield takeLatest(UPDATE_STAFF, updateStaff)
}

function getOfficeListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + GET_OFFICE_LIST_API,
    params: params
  });
}

function* getOfficeList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getOfficeListApi, payload);
    yield put(showHideLoadingAction(false));
    if (response.data.success) {
      yield put(getOfficeListSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      yield put(getOfficeListError(response))
      if (reject) reject(response)  
    }
    
  } catch (e) {
    yield put(showHideLoadingAction(false));
    yield put(getOfficeListError(e))
    if (reject) reject(e)
  }
}

export function* getOfficeListWatcher() {
  yield takeLatest(GET_OFFICE_LIST, getOfficeList)
}