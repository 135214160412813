import {
  GET_PAYMENT_COMMISSION_LIST,
  GET_PAYMENT_COMMISSION_LIST_SUCCESS,
  GET_PAYMENT_COMMISSION_LIST_ERROR,
  GET_AGENT_COUNTRY_LIST,
  GET_AGENT_COUNTRY_LIST_SUCCESS,
  GET_AGENT_COUNTRY_LIST_ERROR,
  GET_PAYMENT_COMMISSION_DETAIL,
  GET_PAYMENT_COMMISSION_DETAIL_SUCCESS,
  GET_PAYMENT_COMMISSION_DETAIL_ERROR,
  GET_PAYMENT_OVERVIEW_MONTHLY,
  GET_PAYMENT_OVERVIEW_MONTHLY_SUCCESS,
  GET_PAYMENT_OVERVIEW_MONTHLY_ERROR,
  GET_COUNTRY_BASED_COMMISSION,
  GET_COUNTRY_BASED_COMMISSION_SUCCESS,
  GET_COUNTRY_BASED_COMMISSION_ERROR,
  AGENT_SEND_INVOICE,
  AGENT_SEND_INVOICE_SUCCESS,
  AGENT_SEND_INVOICE_ERROR,
  GET_AGENT_INCOME_CURRENCY,
  GET_AGENT_INCOME_CURRENCY_SUCCESS,
  GET_AGENT_INCOME_CURRENCY_ERROR,
  GET_AGENT_TOTAL_INCOME,
  GET_AGENT_TOTAL_INCOME_SUCCESS,
  GET_AGENT_TOTAL_INCOME_ERROR,
  GET_AGENT_EARNING_MONTHLY,
  GET_AGENT_EARNING_MONTHLY_SUCCESS,
  GET_AGENT_EARNING_MONTHLY_ERROR,

	CREATE_PAYMENT_METHOD,
	CREATE_PAYMENT_METHOD_SUCCESS,
	CREATE_PAYMENT_METHOD_ERROR,
	GET_PAYMENT_LIST,
	GET_PAYMENT_LIST_SUCCESS,
	GET_PAYMENT_LIST_ERROR,
	GET_PAYMENT_DETAILS,
	GET_PAYMENT_DETAILS_SUCCESS,
	GET_PAYMENT_DETAILS_ERROR,
	UPDATE_PAYMENT_METHOD,
	UPDATE_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHOD_ERROR,
	DELETE_PAYMENT_METHOD,
	DELETE_PAYMENT_METHOD_SUCCESS,
	DELETE_PAYMENT_METHOD_ERROR,
	SAVE_CARD_ERROR,
	SAVE_CARD_SUCCESS,
	SAVE_CARD
} from "../constants";

export function getPaymentCommissionListAction(session, resolve, reject){
  return {
    type: GET_PAYMENT_COMMISSION_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getPaymentCommissionListSuccess(data) {
  return {
    type: GET_PAYMENT_COMMISSION_LIST_SUCCESS,
    payload: data
  };
}

export function getPaymentCommissionListError(error) {
  return {
    type: GET_PAYMENT_COMMISSION_LIST_ERROR,
    payload: error
  };
}

export function getAgentCountryListAction(session, resolve, reject){
  return {
    type: GET_AGENT_COUNTRY_LIST,
    payload: session,
    resolve,
    reject
  };
}

export function getAgentCountryListSuccess(data) {
  return {
    type: GET_AGENT_COUNTRY_LIST_SUCCESS,
    payload: data
  };
}

export function getAgentCountryListError(error) {
  return {
    type: GET_AGENT_COUNTRY_LIST_ERROR,
    payload: error
  };
}

export function getPaymentCommissionDetailAction(session, resolve, reject){
  return {
    type: GET_PAYMENT_COMMISSION_DETAIL,
    payload: session,
    resolve,
    reject
  };
}

export function getPaymentCommissionDetailSuccess(data) {
  return {
    type: GET_PAYMENT_COMMISSION_DETAIL_SUCCESS,
    payload: data
  };
}

export function getPaymentCommissionDetailError(error) {
  return {
    type: GET_PAYMENT_COMMISSION_DETAIL_ERROR,
    payload: error
  };
}

export function getPaymentOverviewMonthlyAction(session, resolve, reject){
  return {
    type: GET_PAYMENT_OVERVIEW_MONTHLY,
    payload: session,
    resolve,
    reject
  };
}

export function getPaymentOverviewMonthlySuccess(data) {
  return {
    type: GET_PAYMENT_OVERVIEW_MONTHLY_SUCCESS,
    payload: data
  };
}

export function getPaymentOverviewMonthlyError(error) {
  return {
    type: GET_PAYMENT_OVERVIEW_MONTHLY_ERROR,
    payload: error
  };
}

export function getCountryBasedCommissionListAction(session, resolve, reject){
  return {
    type: GET_COUNTRY_BASED_COMMISSION,
    payload: session,
    resolve,
    reject
  };
}

export function getCountryBasedCommissionListSuccess(data) {
  return {
    type: GET_COUNTRY_BASED_COMMISSION_SUCCESS,
    payload: data
  };
}

export function getCountryBasedCommissionListError(error) {
  return {
    type: GET_COUNTRY_BASED_COMMISSION_ERROR,
    payload: error
  };
}

export function agentSendInvoiceAction(session, resolve, reject){
  return {
    type: AGENT_SEND_INVOICE,
    payload: session,
    resolve,
    reject
  };
}

export function agentSendInvoiceSuccess(data) {
  return {
    type: AGENT_SEND_INVOICE_SUCCESS,
    payload: data
  };
}

export function agentSendInvoiceError(error) {
  return {
    type: AGENT_SEND_INVOICE_ERROR,
    payload: error
  };
}

export function getAgentIncomeCurrencyAction(session, resolve, reject){
  return {
    type: GET_AGENT_INCOME_CURRENCY,
    payload: session,
    resolve,
    reject
  };
}

export function getAgentIncomeCurrencySuccess(data) {
  return {
    type: GET_AGENT_INCOME_CURRENCY_SUCCESS,
    payload: data
  };
}

export function getAgentIncomeCurrencyError(error) {
  return {
    type: GET_AGENT_INCOME_CURRENCY_ERROR,
    payload: error
  };
}

export function getAgentTotalIncomeAction(session, resolve, reject){
  return {
    type: GET_AGENT_TOTAL_INCOME,
    payload: session,
    resolve,
    reject
  };
}

export function getAgentTotalIncomeSuccess(data) {
  return {
    type: GET_AGENT_TOTAL_INCOME_SUCCESS,
    payload: data
  };
}

export function getAgentTotalIncomeError(error) {
  return {
    type: GET_AGENT_TOTAL_INCOME_ERROR,
    payload: error
  };
}

export function getAgentEarningMonthlyAction(session, resolve, reject){
  return {
    type: GET_AGENT_EARNING_MONTHLY,
    payload: session,
    resolve,
    reject
  };
}

export function getAgentEarningMonthlySuccess(data) {
  return {
    type: GET_AGENT_EARNING_MONTHLY_SUCCESS,
    payload: data
  };
}

export function getAgentEarningMonthlyError(error) {
  return {
    type: GET_AGENT_EARNING_MONTHLY_ERROR,
    payload: error
  };
}


export function createPaymentMethodAction(session, resolve, reject){
	return {
		type: CREATE_PAYMENT_METHOD,
		payload: session,
		resolve,
		reject
	};
}

export function createPaymentMethodSuccess(data) {
	return {
		type: CREATE_PAYMENT_METHOD_SUCCESS,
		payload: data
	};
}

export function createPaymentMethodError(error) {
	return {
		type: CREATE_PAYMENT_METHOD_ERROR,
		payload: error
	};
}

export function getPaymentListAction(session, resolve, reject){
	return {
		type: GET_PAYMENT_LIST,
		payload: session,
		resolve,
		reject
	};
}

export function getPaymentListSuccess(data) {
	return {
		type: GET_PAYMENT_LIST_SUCCESS,
		payload: data
	};
}

export function getPaymentListError(error) {
	return {
		type: GET_PAYMENT_LIST_ERROR,
		payload: error
	};
}

export function getPaymentDetailsAction(session, resolve, reject){
	return {
		type: GET_PAYMENT_DETAILS,
		payload: session,
		resolve,
		reject
	};
}

export function getPaymentDetailsSuccess(data) {
	return {
		type: GET_PAYMENT_DETAILS_SUCCESS,
		payload: data
	};
}

export function getPaymentDetailsError(error) {
	return {
		type: GET_PAYMENT_DETAILS_ERROR,
		payload: error
	};
}

export function updatePaymentMethodAction(session, resolve, reject){
	return {
		type: UPDATE_PAYMENT_METHOD,
		payload: session,
		resolve,
		reject
	};
}

export function updatePaymentMethodSuccess(data) {
	return {
		type: UPDATE_PAYMENT_METHOD_SUCCESS,
		payload: data
	};
}

export function updatePaymentMethodError(error) {
	return {
		type: UPDATE_PAYMENT_METHOD_ERROR,
		payload: error
	};
}

export function deletePaymentMethodAction(session, resolve, reject){
	return {
		type: DELETE_PAYMENT_METHOD,
		payload: session,
		resolve,
		reject
	};
}

export function deletePaymentMethodSuccess(data) {
	return {
		type: DELETE_PAYMENT_METHOD_SUCCESS,
		payload: data
	};
}

export function deletePaymentMethodError(error) {
	return {
		type: DELETE_PAYMENT_METHOD_ERROR,
		payload: error
	};
}

export function saveCardAction(session, resolve, reject){
	return {
		type: SAVE_CARD,
		payload: session,
		resolve,
		reject
	};
}

export function saveCardSuccess(data) {
	return {
		type: SAVE_CARD_SUCCESS,
		payload: data
	};
}

export function saveCardError(error) {
	return {
		type: SAVE_CARD_ERROR,
		payload: error
	};
}
