import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_AGENT,
  GET_STUDENT_APPLICATION_STATISTICS,
  GET_AGENT_INCOME_COUNT,
  GET_STUDENT_APPLICATION_COUNT,
  GET_TOP_DESTINATION_UNIVERSITY,
  GET_TOP_SOURCE_APPLICATION,
  GET_EXPRESS_COUNTRY_LIST
} from "../constants";
import {
  baseUrl,
  DASHBOARD_DATA,
  GET_DASHBOARD_AGENT_API,
  GET_STUDENT_APPLICATION_STATISTICS_API,
  GET_AGENT_INCOME_COUNT_API,
  GET_STUDENT_APPLICATION_COUNT_API,
  GET_TOP_DESTINATION_UNIVERSITY_API,
  GET_TOP_SOURCE_APPLICATION_API,
  GET_EXPRESS_COUNTRY_LIST_API
} from "../../Api";
import { toast } from "react-toastify";
// import axios from "axios";
import axios from "../../Api/axios"
import { showHideLoadingAction, handleErrorResponse, getDashboardDataError, getDashboardDataSuccess, getDashboardAgentSuccess, getDashboardAgentError, getStudentApplicationStatisticsSuccess, getStudentApplicationStatisticsError,getAgentIncomeCountSuccess, getAgentIncomeCountError,getExpressCountryListSuccess, getExpressCountryListError, getStudentApplicationCountSuccess, getStudentApplicationCountError, getTopDestinationUniversityError, getTopDestinationUniversitySuccess, getTopSourceApplicationSuccess, getTopSourceApplicationError } from '../actions';

function getDashboardDataApi(params) {
  console.log('action.payload', params);
  return axios.request({
    method: "get",
    url: baseUrl+DASHBOARD_DATA+"/"+params.id,
    // params: params
  });
}

function* fetchDashboardData(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getDashboardDataApi, payload);
    console.log('response dashdata', response);

    yield put(getDashboardDataSuccess(response.data));
    if (resolve) resolve()
  } catch (e) {
    // toast.error('Something went wrong, please try again.');
    console.log('err dashdata', e)
    yield put(getDashboardDataError(e))
    if (reject) reject()
  }
}

export function* getDashboardDataActionWatcher() {
  yield takeLatest(GET_DASHBOARD_DATA, fetchDashboardData)
}



function getDashboardAgentActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_DASHBOARD_AGENT_API + "/" + params.agent_id
	});
}

function* getDashboardAgentAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getDashboardAgentActionApi, payload);
		yield put(getDashboardAgentSuccess(response.data));
    yield put(showHideLoadingAction(false));
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(getDashboardAgentError(e))
		if (reject) reject(e)
	}
}

export function* getDashboardAgentActionWatcher() {
	yield takeLatest(GET_DASHBOARD_AGENT, getDashboardAgentAction)
}

function getStudentApplicationStatisticsActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_STUDENT_APPLICATION_STATISTICS_API + "/" + params.agent_id,
	  params: params
	});
}

function* getStudentApplicationStatisticsAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getStudentApplicationStatisticsActionApi, payload);
		yield put(getStudentApplicationStatisticsSuccess(response.data));
    yield put(showHideLoadingAction(false));
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(getStudentApplicationStatisticsError(e))
		if (reject) reject(e)
	}
}

export function* getStudentApplicationStatisticsActionWatcher() {
	yield takeLatest(GET_STUDENT_APPLICATION_STATISTICS, getStudentApplicationStatisticsAction)
}

function getAgentIncomeCountApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_AGENT_INCOME_COUNT_API,
	  params: params
	});
}

function* getAgentIncomeCount(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getAgentIncomeCountApi, payload);
		yield put(getAgentIncomeCountSuccess(response.data));
    yield put(showHideLoadingAction(false));
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(getAgentIncomeCountError(e))
		if (reject) reject(e)
	}
}

export function* getAgentIncomeCountWatcher() {
	yield takeLatest(GET_AGENT_INCOME_COUNT, getAgentIncomeCount)
}

function getExpressCountryListApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_EXPRESS_COUNTRY_LIST_API,
	  params: params
	});
}

function* getExpressCountryList(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getExpressCountryListApi, payload);
		yield put(getExpressCountryListSuccess(response.data));
    yield put(showHideLoadingAction(false));
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(getExpressCountryListError(e))
		if (reject) reject(e)
	}
}

export function* getExpressCountryListWatcher() {
	yield takeLatest(GET_EXPRESS_COUNTRY_LIST, getExpressCountryList)
}

function getStudentApplicationCountApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_STUDENT_APPLICATION_COUNT_API,
	  params: params
	});
}

function* getStudentApplicationCount(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getStudentApplicationCountApi, payload);
		yield put(getStudentApplicationCountSuccess(response.data));
    yield put(showHideLoadingAction(false));
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(getStudentApplicationCountError(e))
		if (reject) reject(e)
	}
}

export function* getStudentApplicationCountWatcher() {
	yield takeLatest(GET_STUDENT_APPLICATION_COUNT, getStudentApplicationCount)
}

function getTopDestinationUniversityApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_TOP_DESTINATION_UNIVERSITY_API + "/"+ params.agent_id
	});
}

function* getTopDestinationUniversityAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getTopDestinationUniversityApi, payload);
		yield put(getTopDestinationUniversitySuccess(response.data));
    yield put(showHideLoadingAction(false));
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(getTopDestinationUniversityError(e))
		if (reject) reject(e)
	}
}

export function* getTopDestinationUniversityWatcher() {
	yield takeLatest(GET_TOP_DESTINATION_UNIVERSITY, getTopDestinationUniversityAction)
}

function getTopSourceApplicationApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_TOP_SOURCE_APPLICATION_API,
	  params: params
	});
}

function* getTopSourceApplicationAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getTopSourceApplicationApi, payload);
		yield put(getTopSourceApplicationSuccess(response.data));
    yield put(showHideLoadingAction(false));
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(getTopSourceApplicationError(e))
		if (reject) reject(e)
	}
}

export function* getTopSourceApplicationWatcher() {
	yield takeLatest(GET_TOP_SOURCE_APPLICATION, getTopSourceApplicationAction)
}
