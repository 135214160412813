import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
  GET_PROGRAM_COUNTRY_LIST,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_CURRENCY_LIST,
  GET_CURRENCY_LIST_SUCCESS,
  GET_CURRENCY_LIST_ERROR,
  SHOW_HIDE_UPDATE_PASSWORD_MODAL,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ONBOARDING_PROCESS
} from "../constants";
import {
  baseUrl,
  COUNTRY_LIST,
  PROGRAM_COUNTRY_LIST,
  CURRENCY_LIST,
  FORGOT_PASSWORD_API,
  RESET_PASSWORD_API,
  ONBOARDING_PROCESS_STORE_API
} from "../../Api";
import { toast } from "react-toastify";
// import axios from "axios";
import axios from "../../Api/axios"
import {
  getProgranCountryListError,
  getProgranCountryListSuccess,
  getCountryListError,
  getCountryListSuccess,
  getCurrencyListSuccess,
  getCurencyListError,
  getCurrencyListError,
  showHideLoadingAction,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  handleErrorResponse,
  onBoardingProcessSuccess,
  onBoardingProcessError
} from '../actions';

function getCountryListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + COUNTRY_LIST,
    // params: params
  });
}

function* fetchCountryList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getCountryListApi, payload);
    yield put(getCountryListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve()
  } catch (e) {
    handleErrorResponse(e.response)
    yield put(getCountryListError(e))
    yield put(showHideLoadingAction(false));
    if (reject) reject()
  }
}

export function* getCountryListActionWatcher() {
  yield takeLatest(GET_COUNTRY_LIST, fetchCountryList)
}

function getProgranCountryListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + PROGRAM_COUNTRY_LIST,
    // params: params
  });
}

function* fetchProgranCountryList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getProgranCountryListApi, payload);
    yield put(getProgranCountryListSuccess(response.data.data));
    if (resolve) resolve()
  } catch (e) {
    yield put(getProgranCountryListError(e))
    if (reject) reject()
  }
}

export function* getProgramCountryListActionWatcher() {
  yield takeLatest(GET_PROGRAM_COUNTRY_LIST, fetchProgranCountryList)
}

function getCurrencyListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl + CURRENCY_LIST,
    // params: params
  });
}

function* fetchCurrencyList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    let response = yield call(getCurrencyListApi, payload);
    yield put(getCurrencyListSuccess(response.data));
    if (resolve) resolve()
  } catch (e) {
    yield put(getCurrencyListError(e))
    if (reject) reject()
  }
}

export function* getCurrencyListActionWatcher() {
  yield takeLatest(GET_CURRENCY_LIST, fetchCurrencyList)
}

function forgotPasswordApi(params) {
  return axios.request({
    method: "POST",
    url: baseUrl+FORGOT_PASSWORD_API,
    data: params,
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || status === 423
		},
  });
}

function* forgotPasswordAction(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(forgotPasswordApi, payload);

    yield put(forgotPasswordSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(forgotPasswordError(e))
    if (reject) reject(e)
  }
}

export function* forgotPasswordActionWatcher() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordAction)
}

function resetPasswordApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + RESET_PASSWORD_API,
		data: params,
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || status === 423
		},
  	});
}

function* resetPassword(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(resetPasswordApi, payload);
    yield put(resetPasswordSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(resetPasswordError(e))
    if (reject) reject()
  }
}

export function* resetPasswordActionWatcher() {
  yield takeLatest(RESET_PASSWORD, resetPassword)
}

function onBoardingProcessApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + ONBOARDING_PROCESS_STORE_API,
		data: params,
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || status === 423
		},
  	});
}

function onBoardingProcessFormDataApi(params) {
	return axios.request({
		method: "post",
		url: baseUrl + ONBOARDING_PROCESS_STORE_API,
		data: params.data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
		validateStatus: function (status) {
			return (status >= 200 && status < 300) || status === 423
		},
  	});
}

function* onBoardingProcess(action) {
  let {
    payload,
    resolve,
    reject
  } = action;
  try {
    yield put(showHideLoadingAction(true));
    let response;
    if(payload.step){
      response = yield call(onBoardingProcessApi, payload);
    }else{
      response = yield call(onBoardingProcessFormDataApi, payload);
    }
    yield put(onBoardingProcessSuccess(response.data));
    yield put(showHideLoadingAction(false));
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(onBoardingProcessError(e))
    if (reject) reject()
  }
}

export function* onBoardingProcessActionWatcher() {
  yield takeLatest(ONBOARDING_PROCESS, onBoardingProcess)
}
