import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledPopover, PopoverBody } from 'reactstrap';
import "./emailTemplate.scss";

import { ReactComponent as StaffAddedIcon } from "../../../../assets/img/staff_added_icon.svg";
import { ReactComponent as DownArrowIcon } from "../../../../assets/img/arrow_down_gray_icon.svg";
import { ReactComponent as GreenTickIcon } from "../../../../assets/img/icons/tick_circle_green_icon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/img/close-btn.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/img/icons/warning_icon.svg";
import { baseUrl } from '../../../../Api';
import { toast } from 'react-toastify';

export default class Hire extends Component {
  //console.log("data", props.data);
  constructor(props) {
    super(props);

    this.state = {
      openModalAccept: false,
      openModalDecline: false,
      openModalAdded: false,
      selectedAssign: {},
      item: null,
      referenceId: 0,
      applicationId: 0,
      applicationNo: '',
      studentId: 0,
      contactList: [],
      studentName: "",
      studentEmail: ""
    }
  }

  componentDidUpdate() {
    if (this.state.contactList != this.props.contactList) {
      this.setState({
        contactList: this.props.contactList
      })
    }
    console.log('contactList after update', this.props.contactList);
  }

  componentDidMount() {
    console.log('contactList', this.props.contactList);
    this.props.getStaffList()
    console.log('contactList after', this.props.contactList);
    this.setState({
      item: this.props.data,
      referenceId: this.props.data.request_reference_id
    }, () => {
      console.log('this.state.item', this.state.item)
      if (this.state.item) {
        let updates = {};
        if (this.state.item.email_from_student) {
          if (this.state.item.email_from_student.personal_details) {
            let student = this.state.item.email_from_student.personal_details;

            updates['studentName'] = student.first_name +
                " " + student.last_name;
            updates['studentEmail'] = this.state.item.email_from_student.email;
          }
          updates['studentId'] = this.state.item.email_from_student.id;

          this.setState(updates)
        }
      }
    })
  }

  cancelRequest = () => {
    let data = {
      client_id: this.state.studentId,
      status: 5
    };
    this.setOpenModalDecline(false)
    this.props.updateHireRequest(data).then(response => {
      console.log('response', response);
    }).catch(err => {
      console.log('err', err)
    })
  }

  acceptRequest = () => {
    if (!Object.keys(this.state.selectedAssign).length) {
      toast.error("Please assign a staff member!")
      return;
    }
    let data = {
      client_id: this.state.studentId,
      status: 3,
      staff_id: this.state.selectedAssign.id
    };

    this.setOpenModalAccept(false); 
    this.props.updateHireRequest(data).then(response => {
      this.setOpenModalAdded(true);
    }).catch(err => {
      console.log('err', err)
    })
  }

  setOpenModalAdded = (stat) => {
    this.setState({
      openModalAdded: stat
    })
  }

  setOpenModalAccept = (stat) => {
    this.setState({
      openModalAccept: stat
    })
  }

  setOpenModalDecline = (stat) => {
    this.setState({
      openModalDecline: stat
    })
  }

  setSelectedAssign = (stat) => {
    this.setState({
      selectedAssign: stat
    })
  }

  // const handleChangeContactList = (e) => {
  //   // setSelectedAssign(e.target.value)
  //   console.log("contacts", e);
  // }

  render() {
    let {
      selectedAssign,
      studentEmail,
      studentName
    } = this.state;

    return (
      <>
        <div className="emailContent">
          <div className="header bigFont">
            <p>
              <strong>
                {studentName}
              </strong> 
              {" "}has invited you
            </p>
          </div>
          <div className="contentBox mt-30">
            <p className="bigFont">
              <strong>
                {
                  studentEmail
                }
              </strong> 
              {" "}has requested to hire you as their 
              <br />Preferred Partner.
            </p>
            <Button color="green" outline
              onClick={() => { this.setOpenModalDecline(true) }}
            >
              Decline
            </Button>
            <Button color="green"
              onClick={() => { this.setOpenModalAccept(true) }}
            >
              Accept &<br />
              Add to Client list
            </Button>
  
            <p className="smallFonts">Unexpected email? To contact Formee Express team, send email to <strong><a href="mailto:admin@formeeexpress.com">admin@formeeexpress.com</a></strong></p>
          </div>
          <div className="footer">
            <p><strong>Need Support?</strong></p>
            <p>Feel free to email us if you have any questions, comments or suggestions.<br />
              We’ll be happy to resolve your issues.</p>
          </div>
        </div>
  
        <Modal className="primaryModal hire" isOpen={this.state.openModalAccept}>
          <ModalHeader>
            Add to Client List
          </ModalHeader>
          <ModalBody className="bgWhite">
            <div className="title">To add <strong>Alex Smith</strong> as a new client</div>
            <p>Please confirm the below details. The rest of the clients<br />details will autofill from their student profile.</p>
  
            <Row>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label for="clientName">Name</Label>
                  <Input 
                    name="clientName" 
                    id="clientName" 
                    type="text" 
                    value={studentName} 
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col xs="12" md="6">
                <FormGroup>
                  <Label for="clientEmail">Email</Label>
                  <Input 
                    name="clientEmail" 
                    id="clientEmail" 
                    type="text" 
                    value={studentEmail} 
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col xs="12" className="mt-30">
                <legend>Please select a staff member to assign the client.</legend>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <Label for="addMember">Staff Member</Label>
                  <div id="selectStaff">
                    {
                      selectedAssign && Object.keys(selectedAssign).length > 0
                        ? <>
                          <div className="staffDetail">
                            <span className="profilePic"
                              style={{ backgroundImage: `url(${selectedAssign.avatar})` }}>
                              {selectedAssign.name}
                            </span>
                            <div className="personalDetials">
                              <strong>{selectedAssign.name}</strong>
                              <span>{selectedAssign.email}</span>
                            </div>
                            <div className="groupName">{selectedAssign.group}</div>
                          </div>
                        </>
                        : <div className="pl-20">Select Staff</div>
                    }
                    <div className="icon"><DownArrowIcon /></div>
                  </div>
                  <UncontrolledPopover
                    trigger="legacy"
                    placement="bottom"
                    target="selectStaff"
                    className="emailExtraOptions"
                  >
                    <PopoverBody>
                      <ul className="staff list">
                        {this.state.contactList && this.state.contactList.map(
                          (item) => {
                            return (
                              <li
                                onClick={() => {
                                  this.setSelectedAssign(item);
                                }}
                              >
                                <Label
                                  check
                                  className={this.state.selectedAssign.id === item.id ? "selected" : ""}
                                >
                                  <Input
                                    type="radio"
                                    name="contactCheckbox"
                                    //checked={selectedAssign && selectedAssign.find(element => element === item.name)}
                                    value={item.id}
  
                                  />
                                  <span className="profilePic"
                                    style={{ 
                                      backgroundImage: `url(${
                                        item.profile_pic ? 
                                          baseUrl+item.profile_pic : 
                                          ""
                                      })` 
                                    }}
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </span>
                                  <div className="personalDetials">
                                    <strong>
                                      {item.first_name + " " + item.last_name}
                                    </strong>
                                  </div>
                                  <div className="groupName">
                                    {
                                      item.role ? (
                                        item.role.group ? (
                                          item.role.group.group_name
                                        ) : ''
                                      ) : ''
                                    }
                                  </div>
                                </Label>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </PopoverBody>
                  </UncontrolledPopover>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="text-center">
            <Button 
              onClick={() => { 
                this.setOpenModalAccept(false) 
              }} 
              color="secondary w-30"
            >
              Cancel
            </Button>

            <Button 
              onClick={() => { 
                this.acceptRequest()
              }} 
              color="primary w-30"
            >
              Save
            </Button>

          </ModalFooter>
        </Modal>
  
        {/* Accept */}
        <Modal className="primaryModal noRadius viewAdded" isOpen={this.state.openModalAdded}>
          <ModalBody>
            <CloseIcon className="closeIcon" onClick={() => { this.setOpenModalAdded(false) }} />
            <GreenTickIcon className="greenTickIcon" />
            <h2 className="title">Added to Client List</h2>
            <p>
              You can now view 
              <strong>
                {" "+studentName}
              </strong> 
              {" "}profile 
            </p>
            <p>
              <Link to={`/profile-overview/${this.state.studentId}`}>
                Click here
              </Link>
            </p>
            <div className="staffName">
              <StaffAddedIcon /> Assigned to 
              <strong>
                {
                  " " +
                  this.state.selectedAssign.first_name +
                  " " +
                  this.state.selectedAssign.last_name
                }
              </strong>
            </div>
          </ModalBody>
        </Modal>
  
  
        {/* Decline */}
        <Modal 
          className="primaryModal noRadius viewAdded" 
          isOpen={this.state.openModalDecline}
        >
          <ModalBody>
            <CloseIcon className="closeIcon" onClick={() => { this.setOpenModalDecline(false) }} />
            <WarningIcon className="WarningIcon" />
            <h2 className="title">Are you sure you want to decline the hire request?</h2>
            <p>This request can not be accepted later. If you change your mind, you will need a new hire request.</p>
            <div className="mt-40">
              <Button onClick={() => { this.setOpenModalDecline(false) }} color="green w-30" outline>No</Button>
              <Button onClick={() => { this.cancelRequest() }} color="green w-30">Yes</Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}