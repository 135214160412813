import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./protected.route";
import "./App.scss";
import io from "socket.io-client";
import { baseUrl } from "./Api";
import config from "./config.json";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserPermissionAction } from "./store/actions";
import "./containers/DefaultLayout/FixedSidebar.scss";

// const socket = io(config.notificationUrl)

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const Forgot = React.lazy(() => import("./views/Pages/Forgot"));
const Reset = React.lazy(() => import("./views/Pages/Reset"));
const ViewApplicationResponse = React.lazy(() =>
  import("./views/ViewApplications/ViewApplicationResponse")
);

const SendCOE = React.lazy(() => import("./views/ViewApplications/SendCOE"));

const SendOffferLetter = React.lazy(() =>
  import("./views/ViewApplications/SendOffferLetter")
);

const ViewInvoice = React.lazy(() =>
  import("./views/ViewApplications/ViewInvoice")
);
const ApplicationListing = React.lazy(() =>
  import("./views/ApplicationListing/ApplicationListing")
);
const ReplyComment = React.lazy(() =>
  import(
    "./views/AgentProfileManagenment/component/PreferedPartnerReview/component/ReplyComment"
  )
);

const ApplicationAddComment = React.lazy(() =>
  import("./views/ViewApplications/ApplicationAddComment")
);

const Newclients = React.lazy(() => import("./views/Newclients"));
const CreateMessage = React.lazy(() =>
  import("./views/Inbox/CreateMessage/createMessageInbox")
);
const Inbox = React.lazy(() => import("./views/Inbox/InboxMessage/inbox"));
const Sent = React.lazy(() => import("./views/Inbox/Sent/sentInbox"));
const Drafts = React.lazy(() => import("./views/Inbox/Drafts/draftsInbox"));
const Delete = React.lazy(() => import("./views/Inbox/Delete/deleteInbox"));
const Starred = React.lazy(() => import("./views/Inbox/Starred/starredInbox"));
const ViewMessage = React.lazy(() =>
  import("./views/Inbox/ViewMessage/viewMessageInbox")
);

const Profile = React.lazy(() => import("./views/Profile"));
const Staffs = React.lazy(() => import("./views/Staffs"));
const Viewus = React.lazy(() => import("./views/Viewus"));
const Managerules = React.lazy(() => import("./views/Managerules"));
const PreferedPartnerProfileView = React.lazy(() =>
  import("./views/AgentProfileManagenment/component/PreferedPartnerProfileView")
);
const PreferedPartnerProfile = React.lazy(() =>
  import("./views/AgentProfileManagenment/component/PreferedPartnerProfile")
);

// Meetings
const PendingList = React.lazy(() => import("./views/Meetings/PendingList"));
const UpcomingList = React.lazy(() => import("./views/Meetings/UpcomingList"));
const DraftList = React.lazy(() => import("./views/Meetings/DraftList"));
const CreateMeeting = React.lazy(() =>
  import("./views/Meetings/CreateMeeting")
);
const ClientHiringRequest = React.lazy(() =>
  import("./views/Inbox/HiringRequest/ClientHiringRequest")
);
const MeHiringRequest = React.lazy(() =>
  import("./views/Inbox/HiringRequest/MeHiringRequest")
);
const ViewMeeting = React.lazy(() => import("./views/Meetings/ViewMeeting"));
const PaymentCommissionDetails = React.lazy(() =>
  import("./views/Payment/PaymentCommissionDetails")
);

// Chats
const Chats = React.lazy(() => import("./views/Chat"));
const StarredChats = React.lazy(() => import("./views/Chat/StarredChats"));
const DeleteChats = React.lazy(() => import("./views/Chat/DeleteChats"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loginid: null };
  }

  componentDidMount() {
    if (!window.location.href.includes("reset")) {
      // if (!localStorage.getItem("agent_id")) {
      //   window.location.href = "#/login";
      // }
    }
    this.setState({ loginid: localStorage.getItem("agent_id") });
    const userId = localStorage.getItem('user_id');
    if (userId) {
      Notification.requestPermission();
      // socket.emit('online', {
      //   user_id: userId,
      //   type: "1"
      // })

      // socket.on('got_new', data => {
      //   // console.log('new notification', data);
      //   let notify = { body: data.message };
      //   if (data.icon) {
      //     notify['icon'] = data.icon
      //   }
      //   var notification = new Notification(data.title, notify);
      //   if (data.link) {
      //     notification.onclick = function() {
      //       window.open(data.link)
      //     }
      //   }
      // })
      this.props.getUserPermissionAction()
    }

  }

  componentDidUpdate(props) {
    if (
      !this.props.permissions
      && (this.props.permissions != props.permissions)
    ) {
      this.props.getUserPermissionAction()
    }
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <ProtectedRoute
              path="/applications"
              name="Application Listing"
              Component={ApplicationListing}
            />
            {/* <ProtectedRoute path="/view-student-application/:id" name="Application Overview" Component={ApplicationOverview} /> */}
            <ProtectedRoute
              path="/clientlist"
              name="Newclients"
              Component={Newclients}
            />
            <ProtectedRoute
              path="/inbox-compose"
              name="CreateMessage"
              Component={CreateMessage}
            />
            <ProtectedRoute path="/inbox" name="Inbox" Component={Inbox} />
            <ProtectedRoute path="/sentInbox" name="Sent" Component={Sent} />
            <ProtectedRoute
              path="/draftsInbox"
              name="Drafts"
              Component={Drafts}
            />
            <ProtectedRoute
              path="/deleteInbox"
              name="Delete"
              Component={Delete}
            />
            <ProtectedRoute
              path="/starredInbox"
              name="Starred"
              Component={Starred}
            />
            <ProtectedRoute
              path="/viewMessageInbox/:id?/:type?"
              name="ViewMessage"
              Component={ViewMessage}
            />
            {/* Meetings */}
            <ProtectedRoute
              path="/meetings-pending-invites"
              name="Pending Invites"
              component={PendingList}
            />
            <ProtectedRoute
              path="/upcoming-meetings"
              name="Upcoming"
              component={UpcomingList}
            />
            <ProtectedRoute
              path="/meetings-drafts"
              name="Drafts"
              component={DraftList}
            />
            <Route
              path="/create-meeting/:id?"
              name="Drafts"
              component={CreateMeeting}
            />
            <ProtectedRoute
              path="/hiring-request-client"
              name="Drafts"
              component={ClientHiringRequest}
            />
            <ProtectedRoute
              path="/hiring-request-me"
              name="Drafts"
              component={MeHiringRequest}
            />

            {/* Chats */}
            <ProtectedRoute path="/chats" name="Chats" component={Chats} />
            <ProtectedRoute
              path="/starred_chats"
              name="Starred Chats"
              Component={StarredChats}
            />
            <ProtectedRoute
              path="/delete_chats"
              name="Delete Chats"
              Component={DeleteChats}
            />

            <ProtectedRoute
              path="/view-meeting"
              name="View"
              component={ViewMeeting}
            />
            <ProtectedRoute
              path="/payment-commission-details"
              name="View"
              component={PaymentCommissionDetails}
            />

            <ProtectedRoute
              path="/profile"
              name="Profile"
              Component={Profile}
            />
            <ProtectedRoute path="/Staffs" name="Staffs" Component={Staffs} />
            <ProtectedRoute path="/viewus" name="Viewus" Component={Viewus} />
            <ProtectedRoute
              path="/managerules"
              name="Managerules"
              Component={Managerules}
            />
            <ProtectedRoute
              path="/prefered-partner-profile-view"
              name="Prefered Partner Profile View"
              Component={PreferedPartnerProfileView}
            />
            <ProtectedRoute
              path="/prefered-partner-profile"
              name="Prefered Partner Profile"
              Component={PreferedPartnerProfile}
            />
            <ProtectedRoute
              path="/view-application-response/:id"
              name="View Application Response"
              Component={ViewApplicationResponse}
            />
            <ProtectedRoute
              path="/view-invoice/:id"
              name="View Invoice"
              Component={ViewInvoice}
            />

            <ProtectedRoute
              path="/send-offfer-letter/:id"
              name="Send offer"
              Component={SendOffferLetter}
            />

            <ProtectedRoute
              path="/send-coe/:id"
              name="Send COE"
              Component={SendCOE}
            />
            <ProtectedRoute
              path="/application-add-comment/:id"
              name="Application Add Comment"
              Component={ApplicationAddComment}
            />
            <ProtectedRoute
              path="/prefered-partner-reply-comment/:id"
              name="prefered-partner-reply-comment"
              Component={ReplyComment}
            />

            {/* <Route
              exact
              path="/auto-login/:id"
              name="AutoLoginPage"
              render={(props) => <Login {...props} />}
            /> */}
            <Route
              exact
              path="/login/:id?"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />


            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/reset"
              name="Reset Page"
              render={(props) => <Reset {...props} />}
            />
            <Route
              exact
              path="/forgot"
              name="Forgot Page"
              render={(props) => <Forgot {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    permissions: state.authReducer.permissions
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserPermissionAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
