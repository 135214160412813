// login
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// get dashboard data
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_ERROR = "GET_DASHBOARD_DATA_ERROR";

// get country list
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_ERROR = "GET_COUNTRY_LIST_ERROR";
// get country list
export const GET_PROGRAM_COUNTRY_LIST = "GET_PROGRAM_COUNTRY_LIST";
export const GET_PROGRAM_COUNTRY_LIST_SUCCESS = "GET_PROGRAM_COUNTRY_LIST_SUCCESS";
export const GET_PROGRAM_COUNTRY_LIST_ERROR = "GET_PROGRAM_COUNTRY_LIST_ERROR";

// get currency list
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";
export const GET_CURRENCY_LIST_SUCCESS = "GET_CURRENCY_LIST_SUCCESS";
export const GET_CURRENCY_LIST_ERROR = "GET_CURRENCY_LIST_ERROR";

// show hide update password modal
export const SHOW_HIDE_UPDATE_PASSWORD_MODAL =
  "SHOW_HIDE_UPDATE_PASSWORD_MODAL";
// ACCEPT MEETING REQUEST
export const ACCEPT_MEETING_REQUEST = "ACCEPT_MEETING_REQUEST";

// save side profile
export const SAVE_SIDE_PROFILE = "SAVE_SIDE_PROFILE";
export const SAVE_SIDE_PROFILE_SUCCESS = "SAVE_SIDE_PROFILE_SUCCESS";
export const SAVE_SIDE_PROFILE_ERROR = "SAVE_SIDE_PROFILE_ERROR";

// get university about
export const GET_UNIVERSITY_ABOUT = "GET_UNIVERSITY_ABOUT";
export const GET_UNIVERSITY_ABOUT_SUCCESS = "GET_UNIVERSITY_ABOUT_SUCCESS";
export const GET_UNIVERSITY_ABOUT_ERROR = "GET_UNIVERSITY_ABOUT_ERROR";

// get LOGIN_LOGS
export const GET_LOGIN_LOGS = "GET_LOGIN_LOGS";
export const GET_LOGIN_LOGS_SUCCESS = "GET_LOGIN_LOGS_SUCCESS";
export const GET_LOGIN_LOGS_ERROR = "GET_LOGIN_LOGS_ERROR";

// get AGREEMENT_DOCUMENTS
export const GET_AGREEMENT_DOCUMENTS = "GET_AGREEMENT_DOCUMENTS";
export const GET_AGREEMENT_DOCUMENTS_SUCCESS = "GET_AGREEMENT_DOCUMENTS_SUCCESS";
export const GET_AGREEMENT_DOCUMENTS_ERROR = "GET_AGREEMENT_DOCUMENTS_ERROR";

// create new group
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

// get group list
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const GET_GROUP_LIST_SUCCESS = "GET_GROUP_LIST_SUCCESS";
export const GET_GROUP_LIST_ERROR = "GET_GROUP_LIST_ERROR";

// get role list
export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS";
export const GET_ROLE_LIST_ERROR = "GET_ROLE_LIST_ERROR";

// send staff invite
export const SEND_STAFF_INVITE = "SEND_STAFF_INVITE";
export const SEND_STAFF_INVITE_SUCCESS = "SEND_STAFF_INVITE_SUCCESS";
export const SEND_STAFF_INVITE_ERROR = "SEND_STAFF_INVITE_ERROR";

// get group roles list
export const GET_GROUP_ROLES_LIST = "GET_GROUP_ROLES_LIST";
export const GET_GROUP_ROLES_LIST_SUCCESS = "GET_GROUP_ROLES_LIST_SUCCESS";
export const GET_GROUP_ROLES_LIST_ERROR = "GET_GROUP_ROLES_LIST_ERROR";

// add new roles to group
export const ADD_ROLES_TO_GROUP = "ADD_ROLES_TO_GROUP";
export const ADD_ROLES_TO_GROUP_SUCCESS = "ADD_ROLES_TO_GROUP_SUCCESS";
export const ADD_ROLES_TO_GROUP_ERROR = "ADD_ROLES_TO_GROUP_ERROR";

// get role permissions
export const GET_ROLE_PERMISSION = "GET_ROLE_PERMISSION";
export const GET_ROLE_PERMISSION_SUCCESS = "GET_ROLE_PERMISSION_SUCCESS";
export const GET_ROLE_PERMISSION_ERROR = "GET_ROLE_PERMISSION_ERROR";

// set role permissions
export const SET_ROLE_PERMISSION = "SET_ROLE_PERMISSION";
export const SET_ROLE_PERMISSION_SUCCESS = "SET_ROLE_PERMISSION_SUCCESS";
export const SET_ROLE_PERMISSION_ERROR = "SET_ROLE_PERMISSION_ERROR";

// get staff list
export const GET_STAFF_LIST = "GET_STAFF_LIST";
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export const GET_STAFF_LIST_ERROR = "GET_STAFF_LIST_ERROR";

// delete group
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";

// get profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

// update profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

// delete role
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

// staff invite gruop list
export const GET_GROUP_INVITE_LIST = "GET_GROUP_INVITE_LIST";
export const GET_GROUP_INVITE_LIST_SUCCESS = "GET_GROUP_INVITE_LIST_SUCCESS";
export const GET_GROUP_INVITE_LIST_ERROR = "GET_GROUP_INVITE_LIST_ERROR";

// staff invite list
export const STAFF_INVITE_LIST = "STAFF_INVITE_LIST";
export const STAFF_INVITE_LIST_SUCCESS = "STAFF_INVITE_LIST_SUCCESS";
export const STAFF_INVITE_LIST_ERROR = "STAFF_INVITE_LIST_ERROR";

// delete staff
export const DELETE_STAFF = "DELETE_SAFF";
export const DELETE_SAFF_SUCCESS = "DELETE_SAFF_SUCCESS";
export const DELETE_SAFF_ERROR = "DELETE_SAFF_ERROR";

// delete staff invite
export const DELETE_STAFF_INVITE = "DELETE_STAFF_INVITE_INVITE";
export const DELETE_STAFF_INVITE_SUCCESS = "DELETE_STAFF_INVITE_SUCCESS";
export const DELETE_STAFF_INVITE_ERROR = "DELETE_STAFF_INVITE_ERROR";

// save intake dates
export const SAVE_INTAKE_DATES = "SAVE_INTAKE_DATES";
export const SAVE_INTAKE_DATES_SUCCESS = "SAVE_INTAKE_DATES_SUCCESS";
export const SAVE_INTAKE_DATES_ERROR = "SAVE_INTAKE_DATES_ERROR";

// get intake dates
export const GET_INTAKE_DATES = "GET_INTAKE_DATES";
export const GET_INTAKE_DATES_SUCCESS = "GET_INTAKE_DATES_SUCCESS";
export const GET_INTAKE_DATES_ERROR = "GET_INTAKE_DATES_ERROR";

// save application form
export const SAVE_APPLICATION_FORM = "SAVE_APPLICATION_FORM";
export const SAVE_APPLICATION_FORM_SUCCESS = "SAVE_APPLICATION_FORM_SUCCESS";
export const SAVE_APPLICATION_FORM_ERROR = "SAVE_APPLICATION_FORM_ERROR";

// get application form
export const GET_APPLICATION_FORM = "GET_APPLICATION_FORM";
export const GET_APPLICATION_FORM_SUCCESS = "GET_APPLICATION_FORM_SUCCESS";
export const GET_APPLICATION_FORM_ERROR = "GET_APPLICATION_FORM_ERROR";

// get profile status
export const GET_PROFILE_STATUS = "GET_PROFILE_STATUS";
export const GET_PROFILE_STATUS_SUCCESS = "GET_PROFILE_STATUS_SUCCESS";
export const GET_PROFILE_STATUS_ERROR = "GET_PROFILE_STATUS_ERROR";

// get application list
export const GET_APPLICATION_LIST = "GET_APPLICATION_LIST";
export const GET_APPLICATION_LIST_SUCCESS = "GET_APPLICATION_LIST_SUCCESS";
export const GET_APPLICATION_LIST_ERROR = "GET_APPLICATION_LIST_ERROR";

// get department list
export const GET_DEPARTMENT_LIST = "GET_DEPARTMENT_LIST";
export const GET_DEPARTMENT_LIST_SUCCESS = "GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_ERROR = "GET_DEPARTMENT_LIST_ERROR";

// get program list
export const GET_PROGRAM_LIST = "GET_PROGRAM_LIST";
export const GET_PROGRAM_LIST_SUCCESS = "GET_PROGRAM_LIST_SUCCESS";
export const GET_PROGRAM_LIST_ERROR = "GET_PROGRAM_LIST_ERROR";

// get study level list
export const GET_STUDY_LEVEL_LIST = "GET_STUDY_LEVEL_LIST";
export const GET_STUDY_LEVEL_SUCCESS = "GET_STUDY_LEVEL_SUCCESS";
export const GET_STUDY_LEVEL_ERROR = "GET_STUDY_LEVEL_ERROR";

// delete applications
export const DELETE_APPLICATIONS = "DELETE_APPLICATIONS";
export const DELETE_APPLICATIONS_SUCCESS = "DELETE_APPLICATIONS_SUCCESS";
export const DELETE_APPLICATIONS_ERROR = "DELETE_APPLICATIONS_ERROR";

// get application status
export const GET_APPLICATION_STATUS_LIST = "GET_APPLICATION_STATUS_LIST";
export const GET_APPLICATION_STATUS_LIST_SUCCESS =
  "GET_APPLICATION_STATUS_LIST_SUCCESS";
export const GET_APPLICATION_STATUS_LIST_ERROR =
  "GET_APPLICATION_STATUS_LIST_ERROR";

// get application details
export const GET_APPLICATION_DETAILS = "GET_APPLICATION_DETAILS";
export const GET_APPLICATION_DETAILS_SUCCESS =
  "GET_APPLICATION_DETAILS_SUCCESS";
export const GET_APPLICATION_DETAILS_ERROR = "GET_APPLICATION_DETAILS_ERROR";

// save department dates
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
export const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS";
export const SAVE_DEPARTMENT_ERROR = "SAVE_DEPARTMENT_ERROR";

// get department dates
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_ERROR = "GET_DEPARTMENTS_ERROR";

//Get single department
export const VIEW_DEPARTMENT = "VIEW_DEPARTMENT";
export const VIEW_DEPARTMENT_SUCCESS = "VIEW_DEPARTMENT_SUCCESS";
export const VIEW_DEPARTMENT_ERROR = "VIEW_DEPARTMENT_ERROR";

//update single department
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";
// update status
export const UPDATE_APPLICATION_STATUS = "UPDATE_APPLICATION_STATUS";
export const UPDATE_APPLICATION_STATUS_SUCCESS =
  "UPDATE_APPLICATION_STATUS_SUCCESS";
export const UPDATE_APPLICATION_STATUS_ERROR =
  "UPDATE_APPLICATION_STATUS_ERROR";

// get application chat and messages count
export const GET_APPLICATION_MESSAGE_CHAT_COUNT =
  "GET_APPLICATION_MESSAGE_CHAT_COUNT";
export const GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS =
  "GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS";
export const GET_APPLICATION_MESSAGE_CHAT_COUNT_ERROR =
  "GET_APPLICATION_MESSAGE_CHAT_COUNT_ERROR";

// get current application status
export const GET_APPLICATION_STATUS = "GET_APPLICATION_STATUS";
export const GET_APPLICATION_STATUS_SUCCESS = "GET_APPLICATION_STATUS_SUCCESS";
export const GET_APPLICATION_STATUS_ERROR = "GET_APPLICATION_STATUS_ERROR";

// assign application to staff
export const ASSIGN_APPLICATION = "ASSIGN_APPLICATION_STATUS";
export const ASSIGN_APPLICATION_SUCCESS = "ASSIGN_APPLICATION_SUCCESS";
export const ASSIGN_APPLICATION_ERROR = "ASSIGN_APPLICATION_ERROR";

// get application assigned list
export const GET_APPLICATION_ASSIGNEES = "GET_APPLICATION_ASSIGNEES";
export const GET_APPLICATION_ASSIGNEES_SUCCESS =
  "GET_APPLICATION_ASSIGNEES_SUCCESS";
export const GET_APPLICATION_ASSIGNEES_ERROR =
  "GET_APPLICATION_ASSIGNEES_ERROR";

export const SEND_APPLICATION_MESSAGE = "SEND_APPLICATION_MESSAGE";
export const SEND_APPLICATION_MESSAGE_SUCCESS =
  "SEND_APPLICATION_MESSAGE_SUCCESS";
export const SEND_APPLICATION_MESSAGE_ERROR = "SEND_APPLICATION_MESSAGE_ERROR";

export const GET_APPLICATION_MESSAGES = "GET_APPLICATION_MESSAGES";
export const GET_APPLICATION_MESSAGES_SUCCESS =
  "GET_APPLICATION_MESSAGES_SUCCESS";
export const GET_APPLICATION_MESSAGES_ERROR = "GET_APPLICATION_MESSAGES_ERROR";

export const GET_APPLICATION_COMMENTS = "GET_APPLICATION_COMMENTS";
export const GET_APPLICATION_COMMENTS_SUCCESS =
  "GET_APPLICATION_COMMENTS_SUCCESS";
export const GET_APPLICATION_COMMENTS_ERROR = "GET_APPLICATION_COMMENTS_ERROR";

export const ADD_APPLICATION_COMMENT = "ADD_APPLICATION_COMMENT";
export const ADD_APPLICATION_COMMENT_SUCCESS =
  "ADD_APPLICATION_COMMENT_SUCCESS";
export const ADD_APPLICATION_COMMENT_ERROR = "ADD_APPLICATION_COMMENT_ERROR";

export const DELETE_APPLICATION_COMMENT = "DELETE_APPLICATION_COMMENT";
export const DELETE_APPLICATION_COMMENT_SUCCESS =
  "DELETE_APPLICATION_COMMENT_SUCCESS";
export const DELETE_APPLICATION_COMMENT_ERROR =
  "DELETE_APPLICATION_COMMENT_ERROR";

// send document verification to admin
export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION =
  "REQUEST_APPLICATION_DOCUMENT_VERIFICATION";
export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION_SUCCESS =
  "REQUEST_APPLICATION_DOCUMENT_VERIFICATION_SUCCESS";
export const REQUEST_APPLICATION_DOCUMENT_VERIFICATION_ERROR =
  "REQUEST_APPLICATION_DOCUMENT_VERIFICATION_ERROR";

export const GET_EDUCATION_LIST = "GET_EDUCATION_LIST";
export const GET_EDUCATION_LIST_SUCCESS = "GET_EDUCATION_LIST_SUCCESS";
export const GET_EDUCATION_LIST_ERROR = "GET_EDUCATION_LIST_ERROR";

export const GET_REMAIN_CAMPUS_LIST = "GET_REMAIN_CAMPUS_LIST";
export const GET_REMAIN_CAMPUS_LIST_SUCCESS = "GET_REMAIN_CAMPUS_LIST_SUCCESS";
export const GET_REMAIN_CAMPUS_LIST_ERROR = "GET_REMAIN_CAMPUS_LIST_ERROR";

// create new Program
export const CREATE_PROGRAM = "CREATE_PROGRAM";
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS";
export const CREATE_PROGRAM_ERROR = "CREATE_PROGRAM_ERROR";

// get Language Exam
export const GET_LANGUAGE_EXAM_LIST = "GET_LANGUAGE_EXAM_LIST";
export const GET_LANGUAGE_EXAM_LIST_SUCCESS = "GET_LANGUAGE_EXAM_LIST_SUCCESS";
export const GET_LANGUAGE_EXAM_LIST_ERROR = "GET_LANGUAGE_EXAM_LIST_ERROR";

// get Additional Exam
export const GET_ADDITIONAL_EXAM_LIST = "GET_ADDITIONAL_EXAM_LIST";
export const GET_ADDITIONAL_EXAM_LIST_SUCCESS =
  "GET_ADDITIONAL_EXAM_LIST_SUCCESS";
export const GET_ADDITIONAL_EXAM_LIST_ERROR = "GET_ADDITIONAL_EXAM_LIST_ERROR";

//Serach Program
export const GET_SEARCH_PROGRAM_LIST = "GET_SEARCH_PROGRAM_LIST";
export const GET_SEARCH_PROGRAM_LIST_SUCCESS =
  "GET_SEARCH_PROGRAM_LIST_SUCCESS";
export const GET_SEARCH_PROGRAM_LIST_ERROR = "GET_SEARCH_PROGRAM_LIST_ERROR";

//Delete Program
export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_ERROR = "DELETE_PROGRAM_ERROR";

// get programs details
export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_ERROR = "GET_PROGRAMS_ERROR";

//get single program
export const GET_PROGRAM_DETAILS = "GET_PROGRAM_DETAILS";
export const GET_PROGRAM_DETAILS_SUCCESS = "GET_PROGRAM_DETAILS_SUCCESS";
export const GET_PROGRAM_DETAILS_ERROR = "GET_PROGRAM_DETAILS_ERROR";

// update program
export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_ERROR = "UPDATE_PROGRAM_ERROR";

// CONFIRM DEPARTMENT
export const CONFIRM_DEPARTMENT = "CONFIRM_DEPARTMENT";
export const CONFIRM_DEPARTMENT_SUCCESS = "CONFIRM_DEPARTMENT_SUCCESS";
export const CONFIRM_DEPARTMENT_ERROR = "CONFIRM_DEPARTMENT_ERROR";

// CONFIRM PRogram
export const CONFIRM_PROGRAM = "CONFIRM_PROGRAM";
export const CONFIRM_PROGRAM_SUCCESS = "CONFIRM_PROGRAM_SUCCESS";
export const CONFIRM_PROGRAM_ERROR = "CONFIRM_PROGRAM_ERROR";

// DELETE_ALL_PROGRAM_DEPARTMENT
export const DELETE_ALL_PROGRAM_DEPARTMENT = "DELETE_ALL_PROGRAM_DEPARTMENT";
export const DELETE_ALL_PROGRAM_DEPARTMENT_SUCCESS =
  "DELETE_ALL_PROGRAM_DEPARTMENT_SUCCESS";
export const DELETE_ALL_PROGRAM_DEPARTMENT_ERROR =
  "DELETE_ALL_PROGRAM_DEPARTMENT_ERROR";

// GET_UNIVERSITY_OVERVIEW
export const GET_UNIVERSITY_OVERVIEW = "GET_UNIVERSITY_OVERVIEW";
export const GET_UNIVERSITY_OVERVIEW_SUCCESS =
  "GET_UNIVERSITY_OVERVIEW_SUCCESS";
export const GET_UNIVERSITY_OVERVIEW_ERROR = "GET_UNIVERSITY_OVERVIEW_ERROR";
// send email save draft
export const COMPOSE_MAIL = "COMPOSE_MAIL";
export const COMPOSE_MAIL_SUCCESS = "COMPOSE_MAIL_SUCCESS";
export const COMPOSE_MAIL_ERROR = "COMPOSE_MAIL_ERROR";

// get inbox listing
export const GET_INBOX = "GET_INBOX";
export const GET_INBOX_SUCCESS = "GET_INBOX_SUCCESS";
export const GET_INBOX_ERROR = "GET_INBOX_ERROR";

// update mail favourite
export const UPDATE_MAIL_FAVOURITE = "UPDATE_MAIL_FAVOURITE";
export const UPDATE_MAIL_FAVOURITE_SUCCESS = "UPDATE_MAIL_FAVOURITE_SUCCESS";
export const UPDATE_MAIL_FAVOURITE_ERROR = "UPDATE_MAIL_FAVOURITE_ERROR";

// delete inbox email
export const DELETE_INBOX_EMAIL = "DELETE_INBOX_EMAIL";
export const DELETE_INBOX_EMAIL_SUCCESS = "DELETE_INBOX_EMAIL_SUCCESS";
export const DELETE_INBOX_EMAIL_ERROR = "DELETE_INBOX_EMAIL_ERROR";

// get unread inbox count
export const GET_UNREAD_INBOX_COUNT = "GET_UNREAD_INBOX_COUNT";
export const GET_UNREAD_INBOX_COUNT_SUCCESS = "GET_UNREAD_INBOX_COUNT_SUCCESS";
export const GET_UNREAD_INBOX_COUNT_ERROR = "GET_UNREAD_INBOX_COUNT_ERROR";

// get mail details
export const GET_INBOX_MAIL_DETAILS = "GET_INBOX_MAIL_DETAILS";
export const GET_INBOX_MAIL_DETAILS_SUCCESS = "GET_INBOX_MAIL_DETAILS_SUCCESS";
export const GET_INBOX_MAIL_DETAILS_ERROR = "GET_INBOX_MAIL_DETAILS_ERROR";

// mark inbox mail read/unread
export const UPDATE_INBOX_READ_STATUS = "UPDATE_INBOX_READ_STATUS";
export const UPDATE_INBOX_READ_STATUS_SUCCESS =
  "UPDATE_INBOX_READ_STATUS_SUCCESS";
export const UPDATE_INBOX_READ_STATUS_ERROR = "UPDATE_INBOX_READ_STATUS_ERROR";

// get timezone list
export const GET_TIMEZONES_LIST = "GET_TIMEZONES_LIST";
export const GET_TIMEZONES_LIST_SUCCESS = "GET_TIMEZONES_LIST_SUCCESS";
export const GET_TIMEZONES_LIST_ERROR = "GET_TIMEZONES_LIST_ERROR";

// create meeting
export const CREATE_MEETING = "CREATE_MEETING";
export const CREATE_MEETING_SUCCESS = "CREATE_MEETING_SUCCESS";
export const CREATE_MEETING_ERROR = "CREATE_MEETING_ERROR";

// get meeting list
export const MEETING_LIST = "MEETING_LIST";
export const MEETING_LIST_SUCCESS = "MEETING_LIST_SUCCESS";
export const MEETING_LIST_ERROR = "MEETING_LIST_ERROR";

// re send staff invite
export const RESEND_STAFF_INVITE = "RESEND_STAFF_INVITE";
export const RESEND_STAFF_INVITE_SUCCESS = "RESEND_STAFF_INVITE_SUCCESS";
export const RESEND_STAFF_INVITE_ERROR = "RESEND_STAFF_INVITE_ERROR";

// delete staff
export const UPDATE_STAFF = "UPDATE_STAFF";
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS";
export const UPDATE_STAFF_ERROR = "UPDATE_STAFF_ERROR";

export const UPDATE_APPLICATION_FAVOURITE = "UPDATE_APPLICATION_FAVOURITE";
export const UPDATE_APPLICATION_FAVOURITE_SUCCESS = "UPDATE_APPLICATION_FAVOURITE_SUCCESS";
export const UPDATE_APPLICATION_FAVOURITE_ERROR = "UPDATE_APPLICATION_FAVOURITE_ERROR";

export const UPDATE_CLIENT_FAVOURITE = "UPDATE_CLIENT_FAVOURITE";
export const UPDATE_CLIENT_FAVOURITE_SUCCESS = "UPDATE_CLIENT_FAVOURITE_SUCCESS";
export const UPDATE_CLIENT_FAVOURITE_ERROR = "UPDATE_CLIENT_FAVOURITE_ERROR";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const SHOW_HIDE_LOADING = "SHOW_HIDE_LOADING";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

//FORGOT_PASSWORD
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

//RESET_PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

//CALENDER_EVENT_LIST
export const CALENDER_EVENT_LIST = "CALENDER_EVENT_LIST";
export const CALENDER_EVENT_LIST_SUCCESS = "CALENDER_EVENT_LIST_SUCCESS";
export const CALENDER_EVENT_LIST_ERROR = "CALENDER_EVENT_LIST_ERROR";

//GET_MONTH_EVENT_LIST
export const GET_MONTH_EVENT_LIST = "GET_MONTH_EVENT_LIST";
export const GET_MONTH_EVENT_LIST_SUCCESS = "GET_MONTH_EVENT_LIST_SUCCESS";
export const GET_MONTH_EVENT_LIST_ERROR = "GET_MONTH_EVENT_LIST_ERROR";

// get office locations list
export const GET_OFFICE_LIST = "GET_OFFICE_LIST";
export const GET_OFFICE_LIST_SUCCESS = "GET_OFFICE_LIST_SUCCESS";
export const GET_OFFICE_LIST_ERROR = "GET_OFFICE_LIST_ERROR";

//GET_SECONDARY_MENU
export const GET_SECONDARY_MENU = "GET_SECONDARY_MENU";

//MEETING_DETAILS
export const MEETING_DETAILS = "MEETING_DETAILS";
export const MEETING_DETAILS_SUCCESS = "MEETING_DETAILS_SUCCESS";
export const MEETING_DETAILS_ERROR = "MEETING_DETAILS_ERROR";

//UPDATE_MEETING_STATUS
export const UPDATE_MEETING_STATUS = "UPDATE_MEETING_STATUS";
export const UPDATE_MEETING_STATUS_SUCCESS = "UPDATE_MEETING_STATUS_SUCCESS";
export const UPDATE_MEETING_STATUS_ERROR = "UPDATE_MEETING_STATUS_ERROR";

//DELETE_MEETING
export const DELETE_MEETING = "DELETE_MEETING";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_ERROR = "DELETE_MEETING_ERROR";

//GET_DASHBOARD_AGENT
export const GET_DASHBOARD_AGENT = "GET_DASHBOARD_AGENT";
export const GET_DASHBOARD_AGENT_SUCCESS = "GET_DASHBOARD_AGENT_SUCCESS";
export const GET_DASHBOARD_AGENT_ERROR = "GET_DASHBOARD_AGENT_ERROR";

// get invoices
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";

// get me
export const GET_ME = "GET_ME";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_ERROR = "GET_ME_ERROR";

// get contact list
export const GET_CONTACT_LIST = "GET_CONTACT_LIST_STATUS";
export const GET_CONTACT_LIST_SUCCESS = "GET_CONTACT_LIST_SUCCESS";
export const GET_CONTACT_LIST_ERROR = "GET_CONTACT_LIST_ERROR";

//GET_REPLY_TO_MEETING
export const GET_REPLY_TO_MEETING = "GET_REPLY_TO_MEETING";
export const GET_REPLY_TO_MEETING_SUCCESS = "GET_REPLY_TO_MEETING_SUCCESS";
export const GET_REPLY_TO_MEETING_ERROR = "GET_REPLY_TO_MEETING_ERROR";

// update viewer request
export const UPDATE_VIEWER_REQUEST = "UPDATE_VIEWER_REQUEST";
export const UPDATE_VIEWER_REQUEST_SUCCESS = "UPDATE_VIEWER_REQUEST_SUCCESS";
export const UPDATE_VIEWER_REQUEST_ERROR = "UPDATE_VIEWER_REQUEST_ERROR";

// update hire request
export const UPDATE_HIRE_REQUEST = "UPDATE_HIRE_REQUEST";
export const UPDATE_HIRE_REQUEST_SUCCESS = "UPDATE_HIRE_REQUEST_SUCCESS";
export const UPDATE_HIRE_REQUEST_ERROR = "UPDATE_HIRE_REQUEST_ERROR";

// get hire request list
export const GET_HIRE_REQUESTS = "GET_HIRE_REQUESTS";
export const GET_HIRE_REQUESTS_SUCCESS = "GET_HIRE_REQUESTS_SUCCESS";
export const GET_HIRE_REQUESTS_ERROR = "GET_HIRE_REQUESTS_ERROR";

// hire request
export const HIRE_REQUEST = "HIRE_REQUEST";
export const HIRE_REQUEST_SUCCESS = "HIRE_REQUEST_SUCCESS";
export const HIRE_REQUEST_ERROR = "HIRE_REQUEST_ERROR";

// client list
export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_ERROR = "GET_CLIENT_LIST_ERROR";

// client country list
export const GET_CLIENT_COUNTRY_LIST = "GET_CLIENT_COUNTRY_LIST";
export const GET_CLIENT_COUNTRY_LIST_SUCCESS =
  "GET_CLIENT_COUNTRY_LIST_SUCCESS";
export const GET_CLIENT_COUNTRY_LIST_ERROR = "GET_CLIENT_COUNTRY_LIST_ERROR";

// delete client
export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";

// get client details
export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const GET_CLIENT_DETAILS_SUCCESS = "GET_CLIENT_DETAILS_SUCCESS";
export const GET_CLIENT_DETAILS_ERROR = "GET_CLIENT_DETAILS_ERROR";

// get termiate reasons
export const GET_TERMINATE_REASONS = "GET_TERMINATE_REASONS";
export const GET_TERMINATE_REASONS_SUCCESS = "GET_TERMINATE_REASONS_SUCCESS";
export const GET_TERMINATE_REASONS_ERROR = "GET_TERMINATE_REASONS_ERROR";

// terminate agreement
export const TERMINATE_AGREEMENT = "TERMINATE_AGREEMENT";
export const TERMINATE_AGREEMENT_SUCCESS = "TERMINATE_AGREEMENT_SUCCESS";
export const TERMINATE_AGREEMENT_ERROR = "TERMINATE_AGREEMENT_ERROR";

// raise dispute
export const RAISE_DISPUTE = "RAISE_DISPUTE";
export const RAISE_DISPUTE_SUCCESS = "RAISE_DISPUTE_SUCCESS";
export const RAISE_DISPUTE_ERROR = "RAISE_DISPUTE_ERROR";

// match client profile
export const MATCH_CLIENT_PROFILE = "MATCH_CLIENT_PROFILE";
export const MATCH_CLIENT_PROFILE_SUCCESS = "MATCH_CLIENT_PROFILE_SUCCESS";
export const MATCH_CLIENT_PROFILE_ERROR = "MATCH_CLIENT_PROFILE_ERROR";

// get schools list
export const SCHOOL_LIST = "SCHOOL_LIST";
export const SCHOOL_LIST_SUCCESS = "SCHOOL_LIST_SUCCESS";
export const SCHOOL_LIST_ERROR = "SCHOOL_LIST_ERROR";

// add comment
export const ADD_CLIENT_COMMENT = "ADD_CLIENT_COMMENT";
export const ADD_CLIENT_COMMENT_SUCCESS = "ADD_CLIENT_COMMENT_SUCCESS";
export const ADD_CLIENT_COMMENT_ERROR = "ADD_CLIENT_COMMENT_ERROR";

// get comment
export const GET_CLIENT_COMMENT = "GET_CLIENT_COMMENT";
export const GET_CLIENT_COMMENT_SUCCESS = "GET_CLIENT_COMMENT_SUCCESS";
export const GET_CLIENT_COMMENT_ERROR = "GET_CLIENT_COMMENT_ERROR";

//GET_STUDENT_APPLICATION_STATISTICS
export const GET_STUDENT_APPLICATION_STATISTICS =
  "GET_STUDENT_APPLICATION_STATISTICS";
export const GET_STUDENT_APPLICATION_STATISTICS_SUCCESS =
  "GET_STUDENT_APPLICATION_STATISTICS_SUCCESS";
export const GET_STUDENT_APPLICATION_STATISTICS_ERROR =
  "GET_STUDENT_APPLICATION_STATISTICS_ERROR";

//GET_AGENT_INCOME_COUNT
export const GET_AGENT_INCOME_COUNT = "GET_AGENT_INCOME_COUNT";
export const GET_AGENT_INCOME_COUNT_SUCCESS = "GET_AGENT_INCOME_COUNT_SUCCESS";
export const GET_AGENT_INCOME_COUNT_ERROR = "GET_AGENT_INCOME_COUNT_ERROR";

//GET_AGENT_INCOME_COUNT
export const GET_EXPRESS_COUNTRY_LIST = "GET_EXPRESS_COUNTRY_LIST";
export const GET_EXPRESS_COUNTRY_LIST_SUCCESS = "GET_EXPRESS_COUNTRY_LIST_SUCCESS";
export const GET_EXPRESS_COUNTRY_LIST_ERROR = "GET_EXPRESS_COUNTRY_LIST_ERROR";

//GET_STUDENT_APPLICATION_COUNT
export const GET_STUDENT_APPLICATION_COUNT = "GET_STUDENT_APPLICATION_COUNT";
export const GET_STUDENT_APPLICATION_COUNT_SUCCESS =
  "GET_STUDENT_APPLICATION_COUNT_SUCCESS";
export const GET_STUDENT_APPLICATION_COUNT_ERROR =
  "GET_STUDENT_APPLICATION_COUNT_ERROR";

//GET_TOP_DESTINATION_UNIVERSITY
export const GET_TOP_DESTINATION_UNIVERSITY = "GET_TOP_DESTINATION_UNIVERSITY";
export const GET_TOP_DESTINATION_UNIVERSITY_SUCCESS =
  "GET_TOP_DESTINATION_UNIVERSITY_SUCCESS";
export const GET_TOP_DESTINATION_UNIVERSITY_ERROR =
  "GET_TOP_DESTINATION_UNIVERSITY_ERROR";

//GET_TOP_SOURCE_APPLICATION
export const GET_TOP_SOURCE_APPLICATION = "GET_TOP_SOURCE_APPLICATION";
export const GET_TOP_SOURCE_APPLICATION_SUCCESS =
  "GET_TOP_SOURCE_APPLICATION_SUCCESS";
export const GET_TOP_SOURCE_APPLICATION_ERROR =
  "GET_TOP_SOURCE_APPLICATION_ERROR";

//ONBOARDING_PROCESS
export const ONBOARDING_PROCESS = "ONBOARDING_PROCESS";
export const ONBOARDING_PROCESS_SUCCESS = "ONBOARDING_PROCESS_SUCCESS";
export const ONBOARDING_PROCESS_ERROR = "ONBOARDING_PROCESS_ERROR";

//GET_PAYMENT_COMMISSION_LIST
export const GET_PAYMENT_COMMISSION_LIST = "GET_PAYMENT_COMMISSION_LIST";
export const GET_PAYMENT_COMMISSION_LIST_SUCCESS =
  "GET_PAYMENT_COMMISSION_LIST_SUCCESS";
export const GET_PAYMENT_COMMISSION_LIST_ERROR =
  "GET_PAYMENT_COMMISSION_LIST_ERROR";

//GET_AGENT_COUNTRY_LIST
export const GET_AGENT_COUNTRY_LIST = "GET_AGENT_COUNTRY_LIST";
export const GET_AGENT_COUNTRY_LIST_SUCCESS = "GET_AGENT_COUNTRY_LIST_SUCCESS";
export const GET_AGENT_COUNTRY_LIST_ERROR = "GET_AGENT_COUNTRY_LIST_ERROR";

//GET_PAYMENT_COMMISSION_DETAIL
export const GET_PAYMENT_COMMISSION_DETAIL = "GET_PAYMENT_COMMISSION_DETAIL";
export const GET_PAYMENT_COMMISSION_DETAIL_SUCCESS =
  "GET_PAYMENT_COMMISSION_DETAIL_SUCCESS";
export const GET_PAYMENT_COMMISSION_DETAIL_ERROR =
  "GET_PAYMENT_COMMISSION_DETAIL_ERROR";

//GET_PAYMENT_OVERVIEW_MONTHLY
export const GET_PAYMENT_OVERVIEW_MONTHLY = "GET_PAYMENT_OVERVIEW_MONTHLY";
export const GET_PAYMENT_OVERVIEW_MONTHLY_SUCCESS =
  "GET_PAYMENT_OVERVIEW_MONTHLY_SUCCESS";
export const GET_PAYMENT_OVERVIEW_MONTHLY_ERROR =
  "GET_PAYMENT_OVERVIEW_MONTHLY_ERROR";

//GET_COUNTRY_BASED_COMMISSION
export const GET_COUNTRY_BASED_COMMISSION = "GET_COUNTRY_BASED_COMMISSION";
export const GET_COUNTRY_BASED_COMMISSION_SUCCESS =
  "GET_COUNTRY_BASED_COMMISSION_SUCCESS";
export const GET_COUNTRY_BASED_COMMISSION_ERROR =
  "GET_COUNTRY_BASED_COMMISSION_ERROR";

//AGENT_SEND_INVOICE
export const AGENT_SEND_INVOICE = "AGENT_SEND_INVOICE";
export const AGENT_SEND_INVOICE_SUCCESS = "AGENT_SEND_INVOICE_SUCCESS";
export const AGENT_SEND_INVOICE_ERROR = "AGENT_SEND_INVOICE_ERROR";

//GET_AGENT_TOTAL_INCOME
export const GET_AGENT_TOTAL_INCOME = "GET_AGENT_TOTAL_INCOME";
export const GET_AGENT_TOTAL_INCOME_SUCCESS = "GET_AGENT_TOTAL_INCOME_SUCCESS";
export const GET_AGENT_TOTAL_INCOME_ERROR = "GET_AGENT_TOTAL_INCOME_ERROR";

//GET_AGENT_INCOME_CURRENCY
export const GET_AGENT_INCOME_CURRENCY = "GET_AGENT_INCOME_CURRENCY";
export const GET_AGENT_INCOME_CURRENCY_SUCCESS =
  "GET_AGENT_INCOME_CURRENCY_SUCCESS";
export const GET_AGENT_INCOME_CURRENCY_ERROR =
  "GET_AGENT_INCOME_CURRENCY_ERROR";

//GET_AGENT_EARNING_MONTHLY
export const GET_AGENT_EARNING_MONTHLY = "GET_AGENT_EARNING_MONTHLY";
export const GET_AGENT_EARNING_MONTHLY_SUCCESS =
  "GET_AGENT_EARNING_MONTHLY_SUCCESS";
export const GET_AGENT_EARNING_MONTHLY_ERROR =
  "GET_AGENT_EARNING_MONTHLY_ERROR";

// delete client comment
export const DELETE_CLIENT_COMMENT = "DELETE_CLIENT_COMMENT";
export const DELETE_CLIENT_COMMENT_SUCCESS = "DELETE_CLIENT_COMMENT_SUCCESS";
export const DELETE_CLIENT_COMMENT_ERROR = "DELETE_CLIENT_COMMENT_ERROR";

//CREATE_PAYMENT_METHOD
export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
export const CREATE_PAYMENT_METHOD_SUCCESS = "CREATE_PAYMENT_METHOD_SUCCESS";
export const CREATE_PAYMENT_METHOD_ERROR = "CREATE_PAYMENT_METHOD_ERROR";

//GET_PAYMENT_LIST
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const GET_PAYMENT_LIST_SUCCESS = "GET_PAYMENT_LIST_SUCCESS";
export const GET_PAYMENT_LIST_ERROR = "GET_PAYMENT_LIST_ERROR";

//GET_PAYMENT_DETAILS
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";
export const GET_PAYMENT_DETAILS_SUCCESS = "GET_PAYMENT_DETAILS_SUCCESS";
export const GET_PAYMENT_DETAILS_ERROR = "GET_PAYMENT_DETAILS_ERROR";

//UPDATE_PAYMENT_METHOD
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR";

//DELETE_PAYMENT_METHOD
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_ERROR = "DELETE_PAYMENT_METHOD_ERROR";

// save card to stripe
export const SAVE_CARD = "SAVE_CARD";
export const SAVE_CARD_SUCCESS = "SAVE_CARD_SUCCESS";
export const SAVE_CARD_ERROR = "SAVE_CARD_ERROR";

// stripe payment
export const STRIPE_PAYMENT = "STRIPE_PAYMENT";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_ERROR = "STRIPE_PAYMENT_ERROR";

// get profile reviews
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_ERROR = "GET_REVIEWS_ERROR";

// get review comments
export const GET_REVIEW_COMMENTS = "GET_REVIEW_COMMENTS";
export const GET_REVIEW_COMMENTS_SUCCESS = "GET_REVIEW_COMMENTS_SUCCESS";
export const GET_REVIEW_COMMENTS_ERROR = "GET_REVIEW_COMMENTS_ERROR";

// reply to review
export const REPLY_TO_REVIEW = "REPLY_TO_REVIEW";
export const REPLY_TO_REVIEW_SUCCESS = "REPLY_TO_REVIEW_SUCCESS";
export const REPLY_TO_REVIEW_ERROR = "REPLY_TO_REVIEW_ERROR";

// delete review comment
export const DELETE_REVIEW_COMMENT = "DELETE_REVIEW_COMMENT";
export const DELETE_REVIEW_COMMENT_SUCCESS = "DELETE_REVIEW_COMMENT_SUCCESS";
export const DELETE_REVIEW_COMMENT_ERROR = "DELETE_REVIEW_COMMENT_ERROR";

// get review report reasons
export const GET_REVIEW_REPORT_REASONS = "GET_REVIEW_REPORT_REASONS";
export const GET_REVIEW_REPORT_REASONS_SUCCESS =
  "GET_REVIEW_REPORT_REASONS_SUCCESS";
export const GET_REVIEW_REPORT_REASONS_ERROR =
  "GET_REVIEW_REPORT_REASONS_ERROR";

// report review
export const REPORT_REVIEW = "REPORT_REVIEW";
export const REPORT_REVIEW_SUCCESS = "REPORT_REVIEW_SUCCESS";
export const REPORT_REVIEW_ERROR = "REPORT_REVIEW_ERROR";

// get chat sessions list
export const GET_CHAT_SESSIONS_LIST = "GET_CHAT_SESSIONS_LIST";
export const GET_CHAT_SESSIONS_LIST_SUCCESS = "GET_CHAT_SESSIONS_LIST_SUCCESS";
export const GET_CHAT_SESSIONS_LIST_ERROR = "GET_CHAT_SESSIONS_LIST_ERROR";

// get chat messages
export const GET_CHAT_MESSAGES_LIST = "GET_CHAT_MESSAGES_LIST";
export const GET_CHAT_MESSAGES_LIST_SUCCESS = "GET_CHAT_MESSAGES_LIST_SUCCESS";
export const GET_CHAT_MESSAGES_LIST_ERROR = "GET_CHAT_MESSAGES_LIST_ERROR";

// delete restore chat
export const DELETE_CHAT = "DELETE_CHAT";
export const DELETE_CHAT_SUCCESS = "DELETE_CHAT_SUCCESS";
export const DELETE_CHAT_ERROR = "DELETE_CHAT_ERROR";

// star unstar chat
export const ADD_STAR_CHAT = "ADD_STAR_CHAT";
export const ADD_STAR_CHAT_SUCCESS = "ADD_STAR_CHAT_SUCCESS";
export const ADD_STAR_CHAT_ERROR = "ADD_STAR_CHAT_ERROR";

// get notifications list
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";

// get notifications count
export const GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT";
export const GET_NOTIFICATIONS_COUNT_SUCCESS =
  "GET_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_NOTIFICATIONS_COUNT_ERROR = "GET_NOTIFICATIONS_COUNT_ERROR";

// mark read
export const MARK_READ = "MARK_READ";
export const MARK_READ_SUCCESS = "MARK_READ_SUCCESS";
export const MARK_READ_ERROR = "MARK_READ_ERROR";

// delete notification
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS";
export const GET_USER_PERMISSIONS_ERROR = "GET_USER_PERMISSIONS_ERROR";

// delete inbox permenant
export const DELETE_PERM = "DELETE_PERM";
export const DELETE_PERM_SUCCESS = "DELETE_PERM_SUCCESS";
export const DELETE_PERM_ERROR = "DELETE_PERM_ERROR";
// move to inbox
export const MOVE_TO_INBOX = "MOVE_TO_INBOX";
export const MOVE_TO_INBOX_SUCCESS = "MOVE_TO_INBOX_SUCCESS";
export const MOVE_TO_INBOX_ERROR = "MOVE_TO_INBOX_ERROR";

// Send Publish request
export const SEND_PUBLISH_REQUEST = "SEND_PUBLISH_REQUEST";
export const SEND_PUBLISH_REQUEST_SUCCESS = "SEND_PUBLISH_REQUEST_SUCCESS";
export const SEND_PUBLISH_REQUEST_ERROR = "SEND_PUBLISH_REQUEST_ERROR";

// Get Publish Request
export const GET_PUBLISH_REQUEST = "GET_PUBLISH_REQUEST";
export const GET_PUBLISH_REQUEST_SUCCESS = "GET_PUBLISH_REQUEST_SUCCESS";
export const GET_PUBLISH_REQUEST_ERROR = "GET_PUBLISH_REQUEST_ERROR";
