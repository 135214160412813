import { GET_ME, GET_ME_ERROR, GET_ME_SUCCESS, GET_PROFILE_ERROR, GET_USER, GET_USER_ERROR, GET_USER_PERMISSIONS, GET_USER_PERMISSIONS_ERROR, GET_USER_PERMISSIONS_SUCCESS, GET_USER_SUCCESS, LOGIN, LOGIN_ERROR, LOGIN_SUCCESS, UPDATE_PASSWORD, UPDATE_PASSWORD_ERROR, UPDATE_PASSWORD_SUCCESS } from "../constants";

export function loginAction(session, resolve, reject){
  return {
    type: LOGIN,
    payload: session,
    resolve,
    reject
  };
}

export function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    payload: data
  };
}

export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    payload: error
  };
}

export function updatePasswordAction(session, resolve, reject){
  return {
    type: UPDATE_PASSWORD,
    payload: session,
    resolve,
    reject
  };
}

export function updatePasswordSuccess(data) {
  console.log('updatepass response', data)
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: data
  };
}

export function updatePasswordError(error) {
  return {
    type: UPDATE_PASSWORD_ERROR,
    payload: error
  };
}

export function getUserAction(session, resolve, reject){
  return {
    type: GET_USER,
    payload: session,
    resolve,
    reject
  };
}

export function getUserSuccess(data) {
  return {
    type: GET_USER_SUCCESS,
    payload: data
  };
}

export function getUserError(error) {
  return {
    type: GET_USER_ERROR,
    payload: error
  };
}

export function getMeAction(session, resolve, reject){
  return {
    type: GET_ME,
    payload: session,
    resolve,
    reject
  };
}

export function getMeSuccess(data) {
  return {
    type: GET_ME_SUCCESS,
    payload: data
  };
}

export function getMeError(error) {
  return {
    type: GET_ME_ERROR,
    payload: error
  };
}

export function getUserPermissionAction(session, resolve, reject){
  return {
    type: GET_USER_PERMISSIONS,
    payload: session,
    resolve,
    reject
  };
}

export function getUserPermissionSuccess(data) {
  return {
    type: GET_USER_PERMISSIONS_SUCCESS,
    payload: data
  };
}

export function getUserPermissionError(error) {
  return {
    type: GET_USER_PERMISSIONS_ERROR,
    payload: error
  };
}