import { all } from 'redux-saga/effects';

import {
  loginWatcher,
  updatePasswordWatcher,
  getMeWatcher,
  getUserPermissionWatcher
} from "./auth";

import {
  getDashboardDataActionWatcher,
  getDashboardAgentActionWatcher,
  getStudentApplicationStatisticsActionWatcher,
  getAgentIncomeCountWatcher,
  getStudentApplicationCountWatcher,
  getExpressCountryListWatcher,
  getTopDestinationUniversityWatcher,
  getTopSourceApplicationWatcher
} from "./dashboard";

import {
  getCountryListActionWatcher,
  getProgramCountryListActionWatcher,
  forgotPasswordActionWatcher,
  resetPasswordActionWatcher,
  onBoardingProcessActionWatcher
} from "./general";

import {
  saveSideProfileActionWatcher,
  getUniversityAboutActionWatcher,
  getLoginLogsActionWatcher,
  getProfileActionWatcher,
  updateProfileActionWatcher,
  saveIntakeDatesActionWatcher,
  getIntakeDatesActionWatcher,
  saveApplicationFormActionWatcher,
  getApplicationFormActionWatcher,
  getProfileStatusWatcher,
  getReviewsWatcher,
  getReviewCommentsWatcher,
  replyToReviewWatcher,
  deleteReviewCommentWatcher,
  getReviewReportReasonsWatcher,
  reportReviewWatcher,
  sendPublishRequestWatcher,
  getPublishRequestWatcher,
  acceptMeetingRequestWatcher
} from "./profile";

import {
  createGroupActionWatcher,
  getGroupListActionWatcher,
  getRoleListActionWatcher,
  sendStaffInviteActionWatcher,
  getGroupRolesListActionWatcher,
  addRolesToGroupActionWatcher,
  getRolePermissionActionWatcher,
  setRolePermissionActionWatcher,
  getStaffListActionWatcher,
  deleteGroupActionWatcher,
  deleteRoleActionWatcher,
  getGroupInviteActionWatcher,
  getStaffInviteListActionWatcher,
  deleteStaffActionWatcher,
  deleteStaffInviteActionWatcher,
  resendStaffInviteActionWatcher,
  updateStaffActionWatcher,
  getOfficeListWatcher
} from "./staff";

import {
  getApplicationListActionWatcher,
  getDepartmentListActionWatcher,
  getProgramListActionWatcher,
  getStudyLevelListActionWatcher,
  deleteApplicationsActionWatcher,
  getApplicationStatusActionWatcher,
  getApplicationDetailsActionWatcher,
  updateApplicationStatusActionWatcher,
  getApplicationStatusWatcher,
  assignApplicationWatcher,
  getApplicationAssigneesWatcher,
  sendApplicationMessageWatcher,
  getApplicationMessageChatCountWatcher,
  getApplicationMessagesWatcher,
  getApplicationCommentsWatcher,
  addApplicationCommentWatcher,
  deleteApplicationCommentWatcher,
  requestDocumentVerificationWatcher,
  updateApplicationFavouriteWatcher,
  getInvoiceWatcher
} from "./application";

import {
  saveDepartmentActionWatcher,
  viewDepartmentActionWatcher,
  updateDepartmentActionWatcher,
  getEducationListActionWatcher,
  getRemainCampusListActionWatcher,
  createProgramActionWatcher,
  getLanguageExamListActionWatcher,
  getAdditionalExamListActionWatcher,
  getSearchProgramListActionWatcher,
  deleteProgramActionWatcher,
  getDepartmentsActionWatcher,
  getProgramsActionWatcher,
  getProgramDetailsActionWatcher,
  updateProgramActionWatcher,
  confirmDepartmentActionWatcher,
  confirmProgramActionWatcher,
  deleteAllProgramDepartmentActionWatcher,
  getUniversityOverviewActionWatcher
} from "./department"

import {
  composeMailWatcher,
  getInboxWatcher,
  updateFavouriteWatcher,
  deleteMailWatcher,
  getUnreadInboxCountWatcher,
  getInboxMailDetailsWatcher,
  updateInboxReadStatusWatcher,
  getTimeZonesListWatcher,
  createMeetingWatcher,
  meetingListWatcher,
  meetingDetailsWatcher,
  updateMeetingStatusWatcher,
  deleteMeetingWatcher,
  getContactListWatcher,
  updateViewerRequestWatcher,
  updateHireRequestWatcher,
  getHireRequestWatcher,
  hireRequestWatcher,
  deletePermActionWatcher,
  moveToInboxActionWatcher
} from "./inbox";

import {
  calendarEventListActionWatcher,
  getMonthEventListActionWatcher
} from "./calender";

import {
  getClientListActionWatcher,
  getClientCountryListActionWatcher,
  deleteClientActionWatcher,
  getClientDetailsActionWatcher,
  getTerminateReasonsActionWatcher,
  terminateAgreementActionWatcher,
  raiseDisputeActionWatcher,
  matchClientProfileActionWatcher,
  schoolListActionWatcher,
  addClientCommentActionWatcher,
  getClientCommentActionWatcher,
  updateClientFavouriteWatcher,
  deleteClientCommentActionWatcher
} from "./client";

import {
  getPaymentCommissionListWatcher,
  getAgentCountryListWatcher,
  getPaymentCommissionDetailWatcher,
  getPaymentOverviewMonthlyWatcher,
  getCountryBasedCommissionListWatcher,
  agentSendInvoiceWatcher,
  getAgentIncomeCurrencyWatcher,
  getAgentTotalIncomeWatcher,
  getAgentEarningMonthlyWatcher,
  createPaymentMethodActionWatcher,
  getPaymentListActionWatcher,
  getPaymentDetailsActionWatcher,
  updatePaymentMethodActionWatcher,
  deletePaymentMethodActionWatcher,
  saveCardWatcher
} from "./payment";

import {
  getChatSessionListWatcher,
  getChatMessagesListWatcher,
  starChatWatcher,
  deleteChatWatcher
} from "./chat";

import {
  getNotificationListWatcher,
  getNotificationsCountWatcher,
  markReadWatcher
} from "./notification";

export default function* rootSaga() {
  yield all([
    loginWatcher(),

    getDashboardDataActionWatcher(),
    getCountryListActionWatcher(),
    getProgramCountryListActionWatcher(),
    saveSideProfileActionWatcher(),
    getLoginLogsActionWatcher(),
    getUniversityAboutActionWatcher(),
    createGroupActionWatcher(),
    getGroupListActionWatcher(),
    getRoleListActionWatcher(),
    sendStaffInviteActionWatcher(),
    getGroupRolesListActionWatcher(),
    addRolesToGroupActionWatcher(),
    getRolePermissionActionWatcher(),
    setRolePermissionActionWatcher(),
    getStaffListActionWatcher(),
    deleteGroupActionWatcher(),

    sendPublishRequestWatcher(),
    getPublishRequestWatcher(),
    acceptMeetingRequestWatcher(),

    getProfileActionWatcher(),
    updateProfileActionWatcher(),

    deleteRoleActionWatcher(),

    getGroupInviteActionWatcher(),
    getStaffInviteListActionWatcher(),

    deleteStaffActionWatcher(),
    deleteStaffInviteActionWatcher(),

    saveIntakeDatesActionWatcher(),
    getIntakeDatesActionWatcher(),

    saveApplicationFormActionWatcher(),
    getApplicationFormActionWatcher(),

    getProfileStatusWatcher(),

    getApplicationListActionWatcher(),
    getDepartmentListActionWatcher(),
    getProgramListActionWatcher(),
    getStudyLevelListActionWatcher(),
    deleteApplicationsActionWatcher(),
    getApplicationStatusActionWatcher(),

    getApplicationDetailsActionWatcher(),
    updateApplicationStatusActionWatcher(),
    getApplicationStatusWatcher(),
    assignApplicationWatcher(),
    getApplicationAssigneesWatcher(),

    saveDepartmentActionWatcher(),
    viewDepartmentActionWatcher(),
    updateDepartmentActionWatcher(),
    getEducationListActionWatcher(),
    getRemainCampusListActionWatcher(),
    createProgramActionWatcher(),
    getLanguageExamListActionWatcher(),
    getAdditionalExamListActionWatcher(),
    getSearchProgramListActionWatcher(),
    deleteProgramActionWatcher(),
    getDepartmentsActionWatcher(),
    getProgramsActionWatcher(),
    getProgramDetailsActionWatcher(),
    updateProgramActionWatcher(),
    confirmDepartmentActionWatcher(),
    confirmProgramActionWatcher(),
    deleteAllProgramDepartmentActionWatcher(),
    getUniversityOverviewActionWatcher(),

    sendApplicationMessageWatcher(),
    getApplicationMessageChatCountWatcher(),
    getApplicationMessagesWatcher(),
    getApplicationCommentsWatcher(),
    addApplicationCommentWatcher(),
    deleteApplicationCommentWatcher(),
    requestDocumentVerificationWatcher(),

    composeMailWatcher(),
    getInboxWatcher(),
    updateFavouriteWatcher(),
    deleteMailWatcher(),
    getUnreadInboxCountWatcher(),
    getInboxMailDetailsWatcher(),
    updateInboxReadStatusWatcher(),
    getTimeZonesListWatcher(),
    createMeetingWatcher(),

    resendStaffInviteActionWatcher(),
    updateStaffActionWatcher(),

    updateApplicationFavouriteWatcher(),
    updateClientFavouriteWatcher(),

    updatePasswordWatcher(),
    forgotPasswordActionWatcher(),
    resetPasswordActionWatcher(),
    calendarEventListActionWatcher(),
    getMonthEventListActionWatcher(),

    getOfficeListWatcher(),
    meetingListWatcher(),
    meetingDetailsWatcher(),
    updateMeetingStatusWatcher(),
    deleteMeetingWatcher(),
    getDashboardAgentActionWatcher(),

    getInvoiceWatcher(),

    getMeWatcher(),

    getContactListWatcher(),

    updateViewerRequestWatcher(),
    updateHireRequestWatcher(),
    getHireRequestWatcher(),
    hireRequestWatcher(),

    getClientListActionWatcher(),
    getClientCountryListActionWatcher(),
    deleteClientActionWatcher(),
    getClientDetailsActionWatcher(),
    getTerminateReasonsActionWatcher(),
    terminateAgreementActionWatcher(),
    raiseDisputeActionWatcher(),
    matchClientProfileActionWatcher(),
    schoolListActionWatcher(),
    addClientCommentActionWatcher(),
    getClientCommentActionWatcher(),

    getStudentApplicationStatisticsActionWatcher(),
    getExpressCountryListWatcher(),
    getAgentIncomeCountWatcher(),
    getStudentApplicationCountWatcher(),
    getTopDestinationUniversityWatcher(),
    getTopSourceApplicationWatcher(),
    onBoardingProcessActionWatcher(),

    getPaymentCommissionListWatcher(),
    getAgentCountryListWatcher(),
    getPaymentCommissionDetailWatcher(),
    getPaymentOverviewMonthlyWatcher(),
    getCountryBasedCommissionListWatcher(),
    agentSendInvoiceWatcher(),
    getAgentIncomeCurrencyWatcher(),
    getAgentTotalIncomeWatcher(),
    getAgentEarningMonthlyWatcher(),

    deleteClientCommentActionWatcher(),
    createPaymentMethodActionWatcher(),
    getPaymentListActionWatcher(),
    getPaymentDetailsActionWatcher(),
    updatePaymentMethodActionWatcher(),
    deletePaymentMethodActionWatcher(),
    saveCardWatcher(),

    getReviewsWatcher(),
    getReviewCommentsWatcher(),
    replyToReviewWatcher(),
    deleteReviewCommentWatcher(),
    getReviewReportReasonsWatcher(),
    reportReviewWatcher(),

    getChatSessionListWatcher(),
    getChatMessagesListWatcher(),
    starChatWatcher(),
    deleteChatWatcher(),

    getNotificationListWatcher(),
    getNotificationsCountWatcher(),
    markReadWatcher(),

    getUserPermissionWatcher(),

    deletePermActionWatcher(),
    moveToInboxActionWatcher()
  ]);
}
