import {
  ADD_APPLICATION_COMMENT,
  ADD_APPLICATION_COMMENT_ERROR,
  ADD_APPLICATION_COMMENT_SUCCESS,
  ASSIGN_APPLICATION,
  ASSIGN_APPLICATION_ERROR,
  ASSIGN_APPLICATION_SUCCESS,
  DELETE_APPLICATIONS,
  DELETE_APPLICATIONS_ERROR,
  DELETE_APPLICATIONS_SUCCESS,
  DELETE_APPLICATION_COMMENT,
  DELETE_APPLICATION_COMMENT_ERROR,
  DELETE_APPLICATION_COMMENT_SUCCESS,
  GET_APPLICATION_ASSIGNEES,
  GET_APPLICATION_ASSIGNEES_ERROR,
  GET_APPLICATION_ASSIGNEES_SUCCESS,
  GET_APPLICATION_COMMENTS,
  GET_APPLICATION_COMMENTS_ERROR,
  GET_APPLICATION_COMMENTS_SUCCESS,
  GET_APPLICATION_DETAILS,
  GET_APPLICATION_DETAILS_ERROR,
  GET_APPLICATION_DETAILS_SUCCESS,
  GET_APPLICATION_LIST,
  GET_APPLICATION_LIST_ERROR,
  GET_APPLICATION_LIST_SUCCESS,
  GET_APPLICATION_MESSAGES,
  GET_APPLICATION_MESSAGES_ERROR,
  GET_APPLICATION_MESSAGES_SUCCESS,
  GET_APPLICATION_MESSAGE_CHAT_COUNT,
  GET_APPLICATION_MESSAGE_CHAT_COUNT_ERROR,
  GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS,
  GET_APPLICATION_STATUS,
  GET_APPLICATION_STATUS_ERROR,
  GET_APPLICATION_STATUS_LIST,
  GET_APPLICATION_STATUS_LIST_ERROR,
  GET_APPLICATION_STATUS_LIST_SUCCESS,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_DEPARTMENT_LIST,
  GET_DEPARTMENT_LIST_ERROR,
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_ERROR,
  GET_INVOICE_SUCCESS,
  GET_PROGRAM_LIST,
  GET_PROGRAM_LIST_ERROR,
  GET_PROGRAM_LIST_SUCCESS,
  GET_STUDY_LEVEL_ERROR,
  GET_STUDY_LEVEL_LIST,
  GET_STUDY_LEVEL_SUCCESS,
  REQUEST_APPLICATION_DOCUMENT_VERIFICATION,
  REQUEST_APPLICATION_DOCUMENT_VERIFICATION_ERROR,
  REQUEST_APPLICATION_DOCUMENT_VERIFICATION_SUCCESS,
  SEND_APPLICATION_MESSAGE,
  SEND_APPLICATION_MESSAGE_ERROR,
  SEND_APPLICATION_MESSAGE_SUCCESS,
  UPDATE_APPLICATION_FAVOURITE,
  UPDATE_APPLICATION_FAVOURITE_ERROR,
  UPDATE_APPLICATION_FAVOURITE_SUCCESS,
  UPDATE_APPLICATION_STATUS,
  UPDATE_APPLICATION_STATUS_ERROR,
  UPDATE_APPLICATION_STATUS_SUCCESS,
} from "../constants";

export function getApplicationListAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationListSuccess(data) {
  return {
    type: GET_APPLICATION_LIST_SUCCESS,
    payload: data,
  };
}

export function getApplicationListError(error) {
  return {
    type: GET_APPLICATION_LIST_ERROR,
    payload: error,
  };
}

export function getDepartmentListAction(session, resolve, reject) {
  return {
    type: GET_DEPARTMENT_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function getDepartmentListSuccess(data) {
  return {
    type: GET_DEPARTMENT_LIST_SUCCESS,
    payload: data,
  };
}

export function getDepartmentListError(error) {
  return {
    type: GET_DEPARTMENT_LIST_ERROR,
    payload: error,
  };
}

export function getProgramListAction(session, resolve, reject) {
  return {
    type: GET_PROGRAM_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function getProgramListSuccess(data) {
  return {
    type: GET_PROGRAM_LIST_SUCCESS,
    payload: data,
  };
}

export function getProgramListError(error) {
  return {
    type: GET_PROGRAM_LIST_ERROR,
    payload: error,
  };
}

export function getStudyLevelListAction(session, resolve, reject) {
  return {
    type: GET_STUDY_LEVEL_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function getStudyLevelSuccess(data) {
  return {
    type: GET_STUDY_LEVEL_SUCCESS,
    payload: data,
  };
}

export function getStudyLevelError(error) {
  return {
    type: GET_STUDY_LEVEL_ERROR,
    payload: error,
  };
}

export function deleteApplicationsAction(session, resolve, reject) {
  return {
    type: DELETE_APPLICATIONS,
    payload: session,
    resolve,
    reject,
  };
}

export function deleteApplicationsSuccess(data) {
  return {
    type: DELETE_APPLICATIONS_SUCCESS,
    payload: data,
  };
}

export function deleteApplicationsError(error) {
  return {
    type: DELETE_APPLICATIONS_ERROR,
    payload: error,
  };
}

export function getApplicationStatusListAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_STATUS_LIST,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationStatusListSuccess(data) {
  return {
    type: GET_APPLICATION_STATUS_LIST_SUCCESS,
    payload: data,
  };
}

export function getApplicationStatusListError(error) {
  return {
    type: GET_APPLICATION_STATUS_LIST_ERROR,
    payload: error,
  };
}

export function getApplicationDetailsAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_DETAILS,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationDetailsSuccess(data) {
  return {
    type: GET_APPLICATION_DETAILS_SUCCESS,
    payload: data,
  };
}

export function getApplicationDetailsError(error) {
  return {
    type: GET_APPLICATION_DETAILS_ERROR,
    payload: error,
  };
}

export function updateApplicationStatusAction(session, resolve, reject) {
  return {
    type: UPDATE_APPLICATION_STATUS,
    payload: session,
    resolve,
    reject,
  };
}

export function updateApplicationStatusSuccess(data) {
  return {
    type: UPDATE_APPLICATION_STATUS_SUCCESS,
    payload: data,
  };
}

export function updateApplicationStatusError(error) {
  return {
    type: UPDATE_APPLICATION_STATUS_ERROR,
    payload: error,
  };
}

export function getApplicationMessageChatCountAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_MESSAGE_CHAT_COUNT,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationMessageChatCountSuccess(data) {
  return {
    type: GET_APPLICATION_MESSAGE_CHAT_COUNT_SUCCESS,
    payload: data,
  };
}

export function getApplicationMessageChatCountError(error) {
  return {
    type: GET_APPLICATION_MESSAGE_CHAT_COUNT_ERROR,
    payload: error,
  };
}

export function getApplicationStatusAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_STATUS,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationStatusSuccess(data) {
  return {
    type: GET_APPLICATION_STATUS_SUCCESS,
    payload: data,
  };
}

export function getApplicationStatusError(error) {
  return {
    type: GET_APPLICATION_STATUS_ERROR,
    payload: error,
  };
}

export function assignApplicationAction(session, resolve, reject) {
  return {
    type: ASSIGN_APPLICATION,
    payload: session,
    resolve,
    reject,
  };
}

export function assignApplicationSuccess(data) {
  return {
    type: ASSIGN_APPLICATION_SUCCESS,
    payload: data,
  };
}

export function assignApplicationError(error) {
  return {
    type: ASSIGN_APPLICATION_ERROR,
    payload: error,
  };
}

export function getApplicationAssigneesAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_ASSIGNEES,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationAssigneesSuccess(data) {
  return {
    type: GET_APPLICATION_ASSIGNEES_SUCCESS,
    payload: data,
  };
}

export function getApplicationAssigneesError(error) {
  return {
    type: GET_APPLICATION_ASSIGNEES_ERROR,
    payload: error,
  };
}

export function sendApplicationMessageAction(session, resolve, reject) {
  return {
    type: SEND_APPLICATION_MESSAGE,
    payload: session,
    resolve,
    reject,
  };
}

export function sendApplicationMessageSuccess(data) {
  return {
    type: SEND_APPLICATION_MESSAGE_SUCCESS,
    payload: data,
  };
}

export function sendApplicationMessageError(error) {
  return {
    type: SEND_APPLICATION_MESSAGE_ERROR,
    payload: error,
  };
}

export function getApplicationMessagesAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_MESSAGES,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationMessagesSuccess(data) {
  return {
    type: GET_APPLICATION_MESSAGES_SUCCESS,
    payload: data,
  };
}

export function getApplicationMessagesError(error) {
  return {
    type: GET_APPLICATION_MESSAGES_ERROR,
    payload: error,
  };
}

export function getApplicationCommentsAction(session, resolve, reject) {
  return {
    type: GET_APPLICATION_COMMENTS,
    payload: session,
    resolve,
    reject,
  };
}

export function getApplicationCommentsSuccess(data) {
  return {
    type: GET_APPLICATION_COMMENTS_SUCCESS,
    payload: data,
  };
}

export function getApplicationCommentsError(error) {
  return {
    type: GET_APPLICATION_COMMENTS_ERROR,
    payload: error,
  };
}

export function addApplicationCommentAction(session, resolve, reject) {
  return {
    type: ADD_APPLICATION_COMMENT,
    payload: session,
    resolve,
    reject,
  };
}

export function addApplicationCommentSuccess(data) {
  return {
    type: ADD_APPLICATION_COMMENT_SUCCESS,
    payload: data,
  };
}

export function addApplicationCommentError(error) {
  return {
    type: ADD_APPLICATION_COMMENT_ERROR,
    payload: error,
  };
}

export function deleteApplicationCommentAction(session, resolve, reject) {
  return {
    type: DELETE_APPLICATION_COMMENT,
    payload: session,
    resolve,
    reject,
  };
}

export function deleteApplicationCommentSuccess(data) {
  return {
    type: DELETE_APPLICATION_COMMENT_SUCCESS,
    payload: data,
  };
}

export function deleteApplicationCommentError(error) {
  return {
    type: DELETE_APPLICATION_COMMENT_ERROR,
    payload: error,
  };
}

export function requestDocumentVerificationAction(session, resolve, reject) {
  return {
    type: REQUEST_APPLICATION_DOCUMENT_VERIFICATION,
    payload: session,
    resolve,
    reject,
  };
}

export function requestDocumentVerificationSuccess(data) {
  return {
    type: REQUEST_APPLICATION_DOCUMENT_VERIFICATION_SUCCESS,
    payload: data,
  };
}

export function requestDocumentVerificationError(error) {
  return {
    type: REQUEST_APPLICATION_DOCUMENT_VERIFICATION_ERROR,
    payload: error,
  };
}

export function updateApplicationFavouriteAction(session, resolve, reject) {
  return {
    type: UPDATE_APPLICATION_FAVOURITE,
    payload: session,
    resolve,
    reject,
  };
}

export function updateApplicationFavouriteSuccess(data) {
  return {
    type: UPDATE_APPLICATION_FAVOURITE_SUCCESS,
    payload: data,
  };
}

export function updateApplicationFavouriteError(error) {
  return {
    type: UPDATE_APPLICATION_FAVOURITE_ERROR,
    payload: error,
  };
}

export function getInvoiceAction(session, resolve, reject) {
  return {
    type: GET_INVOICE,
    payload: session,
    resolve,
    reject,
  };
}

export function getInvoiceSuccess(data) {
  return {
    type: GET_INVOICE_SUCCESS,
    payload: data,
  };
}

export function getInvoiceError(error) {
  return {
    type: GET_INVOICE_ERROR,
    payload: error,
  };
}
