import { put, takeLatest, all, call } from 'redux-saga/effects';
// import axios from "axios";
import axios from "../../Api/axios"
import {
  baseUrl,
  COMPOSE_MAIL_API,
  CREATE_MEETING_API,
  DELETE_INBOX_EMAIL_API,
  GET_INBOX_MAIL_DETAILS_API,
  GET_TIMEZONES_LIST_API,
  GET_UNREAD_INBOX_COUNT_API,
  INBOX_LIST_API,
  UPDATE_INBOX_READ_STATUS_API,
  UPDATE_MAIL_FAVOURITE_API,
  MEETING_LIST_API,
  MEETING_DETAILS_API,
  UPDATE_MEETING_STATUS_API,
  DELETE_MEETING_API,
  GET_CONTACT_LIST_API,
  UPDATE_VIEWER_REQUEST_API,
  UPDATE_HIRE_REQUEST_API,
  GET_HIRE_REQUESTS_API,
  HIRE_REQUEST_API,
  MOVE_TO_INBOX_API,
  DELETE_PERM_API
} from '../../Api';
import { composeMailError, composeMailSuccess, createMeetingError, createMeetingSuccess, deleteEmailError, deleteEmailSuccess, getInboxError, getInboxMailDetailsError, getInboxMailDetailsSuccess, getInboxSuccess, getTimeZonesListError, getTimeZonesListSuccess, getUnreadInboxCountError, getUnreadInboxCountSuccess, handleErrorResponse, updateFavouriteError, updateFavouriteSuccess, updateInboxReadStatusError, updateInboxReadStatusSuccess, meetingListError, meetingListSuccess, handleSuccessResponse, showHideLoadingAction, meetingDetailsSuccess, meetingDetailsError, updateMeetingStatusSuccess, updateMeetingStatusError, deleteMeetingSuccess, deleteMeetingError, getContactListError, getContactListSuccess, updateViewerRequestSuccess, updateViewerRequestError, updateHireRequestSuccess, updateHireRequestError, getHireRequestError, getHireRequestSuccess, hireRequestError, hireRequestSuccess, moveToInboxError, moveToInboxSuccess, deletePermError, deletePermSuccess } from '../actions';
import { COMPOSE_MAIL, CREATE_MEETING, DELETE_INBOX_EMAIL, GET_INBOX, GET_INBOX_MAIL_DETAILS, GET_TIMEZONES_LIST, GET_UNREAD_INBOX_COUNT, UPDATE_INBOX_READ_STATUS, UPDATE_MAIL_FAVOURITE, MEETING_LIST, MEETING_DETAILS, UPDATE_MEETING_STATUS, DELETE_MEETING, GET_CONTACT_LIST, UPDATE_VIEWER_REQUEST, UPDATE_HIRE_REQUEST, GET_HIRE_REQUESTS, HIRE_REQUEST, MOVE_TO_INBOX, DELETE_PERM } from '../constants';

function composeMailApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+COMPOSE_MAIL_API,
    data: params
  });
}

function* composeMail(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(composeMailApi, payload);

    yield put(composeMailSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(composeMailError(e))
    if (reject) reject(e)
  }
}

export function* composeMailWatcher() {
  yield takeLatest(COMPOSE_MAIL, composeMail)
}

function getInboxApi(params) {
  let url = baseUrl+INBOX_LIST_API;
  if (params.handle) {
    url += "/"+params.handle;
  }
  return axios.request({
    method: "get",
    url: url,
    params: params
  });
}

function* getInbox(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getInboxApi, payload);

    yield put(getInboxSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getInboxError(e))
    if (reject) reject(e)
  }
}

export function* getInboxWatcher() {
  yield takeLatest(GET_INBOX, getInbox)
}

function updateFavouriteApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_MAIL_FAVOURITE_API,
    data: params
  });
}

function* updateFavourite(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(updateFavouriteApi, payload);

    yield put(updateFavouriteSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateFavouriteError(e))
    if (reject) reject(e)
  }
}

export function* updateFavouriteWatcher() {
  yield takeLatest(UPDATE_MAIL_FAVOURITE, updateFavourite)
}

function deleteMailApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+DELETE_INBOX_EMAIL_API,
    data: params
  });
}

function* deleteMail(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deleteMailApi, payload);

    yield put(deleteEmailSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteEmailError(e))
    if (reject) reject(e)
  }
}

export function* deleteMailWatcher() {
  yield takeLatest(DELETE_INBOX_EMAIL, deleteMail)
}

function getUnreadInboxCountApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_UNREAD_INBOX_COUNT_API,
    params: params
  });
}

function* getUnreadInboxCount(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getUnreadInboxCountApi, payload);

    yield put(getUnreadInboxCountSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getUnreadInboxCountError(e))
    if (reject) reject(e)
  }
}

export function* getUnreadInboxCountWatcher() {
  yield takeLatest(GET_UNREAD_INBOX_COUNT, getUnreadInboxCount)
}

function getInboxMailDetailsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_INBOX_MAIL_DETAILS_API,
    params: params
  });
}

function* getInboxMailDetails(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(getInboxMailDetailsApi, payload);

    yield put(getInboxMailDetailsSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(getInboxMailDetailsError(e))
    if (reject) reject(e)
  }
}

export function* getInboxMailDetailsWatcher() {
  yield takeLatest(GET_INBOX_MAIL_DETAILS, getInboxMailDetails)
}

function updateInboxReadStatusApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_INBOX_READ_STATUS_API,
    data: params
  });
}

function* updateInboxReadStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(updateInboxReadStatusApi, payload);

    yield put(updateInboxReadStatusSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateInboxReadStatusError(e))
    if (reject) reject(e)
  }
}

export function* updateInboxReadStatusWatcher() {
  yield takeLatest(UPDATE_INBOX_READ_STATUS, updateInboxReadStatus)
}

function getTimeZonesListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_TIMEZONES_LIST_API,
    params: params
  });
}

function* getTimeZonesList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(getTimeZonesListApi, payload);
    yield put(getTimeZonesListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(getTimeZonesListError(e));
    if (reject) reject(e)
  }
}

export function* getTimeZonesListWatcher() {
  yield takeLatest(GET_TIMEZONES_LIST, getTimeZonesList)
}

function createMeetingApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+CREATE_MEETING_API,
    data: params
  });
}

function* createMeeting(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(createMeetingApi, payload);

    yield put(createMeetingSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(createMeetingError(e))
    if (reject) reject(e)
  }
}

export function* createMeetingWatcher() {
  yield takeLatest(CREATE_MEETING, createMeeting)
}

function meetingListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+MEETING_LIST_API+"/"+params.handle,
    params: params
  });
}

function* meetingList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(meetingListApi, payload);
    yield put(meetingListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
    yield put(meetingListError(e))
    if (reject) reject(e)
  }
}

export function* meetingListWatcher() {
  yield takeLatest(MEETING_LIST, meetingList)
}

function meetingDetailsApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+MEETING_DETAILS_API,
    params: params
  });
}

function* meetingDetails(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(meetingDetailsApi, payload);
    yield put(meetingDetailsSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(meetingDetailsError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* meetingDetailsWatcher() {
  yield takeLatest(MEETING_DETAILS, meetingDetails)
}

function updateMeetingStatusApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_MEETING_STATUS_API+"/"+params.status,
    data: params
  });
}

function* updateMeetingStatus(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true));
    let response = yield call(updateMeetingStatusApi, payload);
    yield put(updateMeetingStatusSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(updateMeetingStatusError(e));
    yield put(showHideLoadingAction(false));
    if (reject) reject(e)
  }
}

export function* updateMeetingStatusWatcher() {
  yield takeLatest(UPDATE_MEETING_STATUS, updateMeetingStatus)
}

function deleteMeetingApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl+DELETE_MEETING_API,
    data: params
  });
}

function* deleteMeeting(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deleteMeetingApi, payload);

    yield put(deleteMeetingSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deleteMeetingError(e))
    if (reject) reject(e)
  }
}

export function* deleteMeetingWatcher() {
  yield takeLatest(DELETE_MEETING, deleteMeeting)
}


function getContactListApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_CONTACT_LIST_API,
    params: params
  });
}

function* getContactList(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(getContactListApi, payload);

    yield put(getContactListSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(getContactListError(e))
    if (reject) reject(e)
  }
}

export function* getContactListWatcher() {
  yield takeLatest(GET_CONTACT_LIST, getContactList)
}

function updateViewerRequestApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_VIEWER_REQUEST_API,
    data: params
  });
}

function* updateViewerRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(updateViewerRequestApi, payload);

    yield put(updateViewerRequestSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(updateViewerRequestError(e))
    if (reject) reject(e)
  }
}

export function* updateViewerRequestWatcher() {
  yield takeLatest(UPDATE_VIEWER_REQUEST, updateViewerRequest)
}

function updateHireRequestApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+UPDATE_HIRE_REQUEST_API,
    data: params
  });
}

function* updateHireRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(updateHireRequestApi, payload);
    handleSuccessResponse(response.data)
    yield put(updateHireRequestSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(updateHireRequestError(e))
    if (reject) reject(e)
  }
}

export function* updateHireRequestWatcher() {
  yield takeLatest(UPDATE_HIRE_REQUEST, updateHireRequest)
}

function getHireRequestApi(params) {
  return axios.request({
    method: "get",
    url: baseUrl+GET_HIRE_REQUESTS_API,
    params: params
  });
}

function* getHireRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(getHireRequestApi, payload);
    handleSuccessResponse(response.data)
    yield put(getHireRequestSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(getHireRequestError(e))
    if (reject) reject(e)
  }
}

export function* getHireRequestWatcher() {
  yield takeLatest(GET_HIRE_REQUESTS, getHireRequest)
}

function hireRequestApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+HIRE_REQUEST_API,
    data: params
  });
}

function* hireRequest(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    yield put(showHideLoadingAction(true))
    let response = yield call(hireRequestApi, payload);
    handleSuccessResponse(response.data)
    yield put(hireRequestSuccess(response.data));
    yield put(showHideLoadingAction(false))
    if (resolve) resolve(response.data)
  } catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
    yield put(hireRequestError(e))
    if (reject) reject(e)
  }
}

export function* hireRequestWatcher() {
  yield takeLatest(HIRE_REQUEST, hireRequest)
}

function deletePermApi(params) {
  return axios.request({
    method: "delete",
    url: baseUrl+DELETE_PERM_API,
    data: params
  });
}

function* deletePerm(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(deletePermApi, payload);

    yield put(deletePermSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(deletePermError(e))
    if (reject) reject(e)
  }
}

export function* deletePermActionWatcher() {
  yield takeLatest(DELETE_PERM, deletePerm)
}

function moveToInboxApi(params) {
  return axios.request({
    method: "post",
    url: baseUrl+MOVE_TO_INBOX_API,
    data: params
  });
}

function* moveToInbox(action) {
  let {
    payload,
    resolve,
    reject
  } = action;

  try {
    let response = yield call(moveToInboxApi, payload);

    yield put(moveToInboxSuccess(response.data));
    if (resolve) resolve(response.data)
  } catch (e) {
    handleErrorResponse(e.response);
    yield put(moveToInboxError(e))
    if (reject) reject(e)
  }
}

export function* moveToInboxActionWatcher() {
  yield takeLatest(MOVE_TO_INBOX, moveToInbox)
}