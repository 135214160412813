import React, { Component, Suspense } from "react";
import { Container, Row, Col, Badge, Button, UncontrolledPopover, PopoverBody, } from "reactstrap";
import { AppHeader } from "@coreui/react";
import { toast, ToastContainer } from "react-toastify";
import PageLoading from "../../../common/PageLoading";
import DefaultHeader from "../../../containers/DefaultLayout/DefaultHeader";
import LeftInboxMenu from "../LeftInboxMenu";
import moment from "moment";
// import $ from "jquery";
import EmailContent from "./EmailContent.json";
import { HashLink as Link } from "react-router-hash-link";


import "./viewMessage.scss";
import ReplyMessage from "../ReplyMessage"
import BackArrowIcon from "../../../assets/img/icons/inbox/back_arrow_icon.svg";
import PrintIcon from "../../../assets/img/icons/inbox/print_icon.svg";
import UserPic from "../../../assets/img/icons/pref-img-2.svg";
import AvatarPic from "../../../assets/img/icons/inbox/avatar_icon.svg";
import ThreeDotIcon from "../../../assets/img/icons/inbox/three_ver_dots.svg";
import StarredIcon from "../../../assets/img/icons/inbox/starred_gray_icon.svg";
import StarredActiveIcon from "../../../assets/img/icons/inbox/star-filled.svg";
import AttachmentIcon from "../../../assets/img/icons/inbox/attachemnt_icon.svg";
import ReplyIcon from "../../../assets/img/icons/inbox/reply_icon.svg";
import DownloadAttachemnet from "../../../assets/img/icons/download_attachemnet.svg";
import { ReactComponent as HasAttachmentIcon } from "../../../assets/img/icons/has_attachment_icon.svg";
import { ReactComponent as ReplySVGIcon } from "../../../assets/img/icons/inbox/reply_icon.svg";
import { ReactComponent as ReplyAllIcon } from "../../../assets/img/icons/inbox/reply_all_icon.svg";
import { ReactComponent as ForwardIcon } from "../../../assets/img/icons/inbox/forward_icon.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getInboxMailDetailsAction,
  updateInboxReadStatusAction,
  deleteEmailAction,
  composeMailAction,
  updateFavouriteAction,
  getProfileAction,
  getMeAction,
  updateViewerRequestAction,
  updateHireRequestAction,
  getStaffListAction
} from "../../../store/actions";
import { baseUrl, DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS } from "../../../Api";
import { confirmAlert } from "react-confirm-alert";
import { MESSAGES } from "../../../constants/messages";
import { checkValidFileSize } from "../../../common/commonHelper";
import config from "../../../config.json";

import EmailTemplate from "./elements/EmailTemplate";

const baseurl = `${config.baseurl}/`;

class ViewMessage extends Component {
  constructor(props) {
    super(props);

    this.replyEditorRef = React.createRef();

    this.state = {
      editorToolbar: false,
      todayDate: moment().format("DD MMM YY"),
      //dropdownOpen: '',
      files: [],
      thumbs: [],
      replyType: "Reply",
      from_type: localStorage.getItem("from_type"),
      mail_type_id: localStorage.getItem("mail_type_id"),
      mail_type: 'Inbox',
      mail_id: localStorage.getItem("mail_id"),
      mail_from: localStorage.getItem("mail_from"),
      mail_from_email: localStorage.getItem("mail_from_email"),
      mail_subject: '',
      mailToTypeBcc: false,
      mailToTypeCc: false,
      openModal: false,

      showDetail: EmailContent.treads.length - 1,
      showReply: false,

      type: "University",
      type_id: localStorage.getItem("universityid"),
      inboxMsg: [],
      mailCount:
        localStorage.getItem("mailCount") !== null
          ? localStorage.getItem("mailCount")
          : 0,
      editorVal: 0,
      //editorState: EditorState.createEmpty(),
      editable: true,
      loading: false,
      emails: [],
      mailId: 0,
      universityId: 0,
      parentId: 0,
      ccEmails: [],
      toEmails: [],
      bccEmails: [],
      parentFlag: 0,
      existingFiles: [],
      contactList: [],
    };
  }

  onDrop = (filesDropped) => {
    // this.setState({ files });
    let filesList = filesDropped;
    let noOfFiles = filesDropped.length;
    let {
      files
    } = this.state;

    for (let file = 0; file < noOfFiles; file++) {
      console.log('files[file]', filesList[file])
      let size = checkValidFileSize(filesList[file]);
      if (!size) {

        files.push(filesList[file]);
      } else {
        toast.error(MESSAGES.VALID_IMAGE_SIZE);
        return;
      }
    }

    this.setState({
      files
    })
  };

  componentDidMount() {
    let universityId = localStorage.getItem("user_id");
    // console.log('this.props.match.params', this.props.match.params)
    let mailId = this.props.match
      ? this.props.match.params
        ? this.props.match.params.id
          ? this.props.match.params.id
          : ""
        : ""
      : "";

    let mail_type = this.props.match
      ? this.props.match.params
        ? this.props.match.params.type
          ? this.props.match.params.type
          : ""
        : ""
      : "";
    this.setState({
      universityId,
      mailId,
      mail_type
    }, () => {
      console.log('mailId', mailId)
      this.getData();
      this.updateReadStatus(mailId, 0);
      this.props.getMeAction(
        {
          id: this.state.universityId
        },
        () => { },
        () => { }
      );
    })
  }

  getData = () => {
    // this.showLoading();
    new Promise((resolve, reject) => {
      this.props.getInboxMailDetailsAction(
        {
          agent_id: this.state.universityId,
          mail_id: this.state.mailId
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            let emails = [];
            if (response.data) {
              response.data.map((val, index) => {
                let currentEmail = val;
                let parentId = 0;
                if (index == response.data.length - 1) {
                  currentEmail.content = false;
                } else {
                  currentEmail.content = true;
                }
                emails.push(currentEmail);

                if (emails.length) {
                  parentId = emails[0].id;
                  this.setState({
                    parentId
                  })
                }
              })
            }
            this.setState({
              emails
            }, () => {
              if (this.state.emails.length) {
                this.setState({
                  mail_subject: this.state.emails[this.state.emails.length - 1].subject
                })
              }
            })
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  };

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  loading = () => <PageLoading loading={true} />;

  print() {
    window.print();
  }

  showMailFullDetail(e) {
    let {
      emails
    } = this.state;

    emails[e].content = !emails[e].content;

    this.setState({
      emails
    });
  }

  handleInit = (evt, editor) => {
    this.setState({ length: editor.getContent({ format: "text" }).length });
  };

  attachemtList = [
    {
      id: 1,
      name: "Assignment_name.pdf",
      size: "45842",
    },
    {
      id: 2,
      name: "Assignment_name_2.pdf",
      size: "65481",
    },
    {
      id: 3,
      name: "Assignment_name_3.pdf",
      size: "54132",
    },
  ];

  updateReadStatus = (id, status) => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.updateInboxReadStatusAction(
        {
          agent_id: this.state.universityId,
          mail_id: id,
          status: status
        },
        (response) => {
          this.hideLoading();
          if (status) {
            toast.success(response.message)
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  deleteEmail = (id) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this email?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.showLoading();
            new Promise((resolve, reject) => {
              this.props.deleteEmailAction(
                {
                  agent_id: this.state.universityId,
                  mail_id: id
                },
                (response) => {
                  this.hideLoading();
                  toast.success(response.message)
                  resolve();
                },
                (err) => {
                  this.hideLoading();
                  reject();
                }
              )
            })
          },
        },
        {
          label: "No",
          onClick: () => {

          },
        },
      ],
    });
  }

  sendEmail = () => {
    let {
      toEmails,
      ccEmails,
      bccEmails,
      subject,
      files,
      existingFiles
    } = this.state;

    let content = this.replyEditorRef.current.getContent();

    if (!toEmails.length) {
      toast.error(MESSAGES.TO_EMAILS_REQUIRED);
      return;
    }
    if (!content) {
      toast.error(MESSAGES.EMAIL_CONTENT_REQUIRED);
      return;
    }

    this.showLoading();

    let formData = new FormData();
    formData.append('agent_id', this.state.universityId);
    toEmails.map((val, index) => {
      formData.append('to[]', val);
    });
    bccEmails.map((val, index) => {
      formData.append('bcc[]', val);
    });
    ccEmails.map((val, index) => {
      formData.append('cc[]', val);
    });
    formData.append('body', content);
    formData.append('subject', subject);

    if (files.length) {
      files.map((val, index) => {
        formData.append('attachment[]', val);
      })
    }
    if (this.state.parentId) {
      formData.append('parent_id', this.state.parentId);
    }
    formData.append('parent_flag', this.state.parentFlag);
    existingFiles.map((val, index) => {
      formData.append('exists_attachments[]', val.id);
    })

    new Promise((resolve, reject) => {
      this.props.composeMailAction(
        formData,
        (response) => {
          this.hideLoading();
          if (response.success) {
            toast.success(response.message);
            if (response.data.mail_id) {
              this.setState({
                mailId: response.data.mail_id
              })
            }
            // if (!draft) {
            // this.props.history.push("/inbox");
            // }
            this.getData();
            this.setState({ showReply: false });
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  setReplyState = (all) => {
    if (this.state.emails.length) {
      let item = this.state.emails[this.state.emails.length - 1];
      let toEmails = [],
        ccEmails = [],
        bccEmails = [],
        replyType = "Reply",
        existingFiles = [],
        subject = item.subject.includes('Re') ? (
          item.subject
        ) : ("Re: " + item.subject);

      if (item.sender_id == this.state.universityId) {
        item.email_to.map((val, index) => {
          toEmails.push(val.receiver.email)
        });
      } else {
        toEmails.push(item.email_from_details.email)
      }
      console.log('toEmails', toEmails)
      if (all) {
        item.email_cc.map((val, index) => {
          ccEmails.push(val.receiver.email)
        });
        item.email_bcc.map((val, index) => {
          bccEmails.push(val.receiver.email)
        });
        replyType = "ReplyAll";
      }

      if (item.attachments) {
        existingFiles = item.attachments.map(val => val);
      }



      this.replyEditorRef.current.setContent('');

      this.setState({
        toEmails,
        ccEmails,
        bccEmails,
        subject,
        replyType,
        existingFiles
      });
    }
  }

  setForwardState = (item) => {
    if (this.state.emails.length) {
      let item = this.state.emails[this.state.emails.length - 1]

      let toEmails = [],
        ccEmails = [],
        bccEmails = [],
        replyType = "Forward",
        subject = "",
        existingFiles = [];

      if (item.subject.includes('Fwd')) {
        subject = item.subject;
      } else {
        subject = "Fwd: " + item.subject;
      }

      if (item.attachments) {
        existingFiles = item.attachments.map(val => val);
      }

      this.replyEditorRef.current.setContent(item.body);

      this.setState({
        toEmails,
        ccEmails,
        bccEmails,
        subject,
        replyType,
        existingFiles
      });
    }
  }

  updateFavourite = (id) => {
    this.showLoading();
    new Promise((resolve, reject) => {
      this.props.updateFavouriteAction(
        {
          agent_id: this.state.universityId,
          mail_id: id
        },
        (response) => {
          this.hideLoading();
          if (response.success) {
            toast.success(response.message);
            this.getData()
          }
          resolve();
        },
        (err) => {
          this.hideLoading();
          reject();
        }
      )
    })
  }

  updateViewerRequest = (request_id, status) => {
    return new Promise((resolve, reject) => {
      this.props.updateViewerRequestAction({
        agent_id: this.state.universityId,
        request_id,
        status
      }, (response) => {
        resolve(response)
      }, (err) => {
        reject(err)
      })
    })
  }

  updateHireRequest = (data) => {
    data.agent_id = this.state.universityId;

    return new Promise((resolve, reject) => {
      this.props.updateHireRequestAction(data, (response) => {
        resolve(response)
      }, (err) => {
        reject(err)
      })
    })
  }

  getStaffList = () => {
    this.props.getStaffListAction({
      agent_id: this.state.universityId,
      limit: "all"
    }, (response) => {
      if (response.data.success) {
        this.setState({
          contactList: response.data.data.data
        })
      }
    }, (err) => {

    })
  }

  render() {
    return (
      <div>
        {/* <ToastContainer />
				<AppHeader fixed className="university-header agentHeader">
					<Suspense fallback={this.loading()}>
						<DefaultHeader onLogout={(e) => this.signOut(e)} />
					</Suspense>
				</AppHeader> */}

        <div className="foe-inbox-container">
          <div className="newContentArea">
            <LeftInboxMenu />
            <div className="inboxContainer">
              <div className="messageSection">
                <header className="header mb-0">
                  <h3 className="title">
                    <img
                      src={BackArrowIcon}
                      alt="go back"
                      className="mr-10 pointer"
                      onClick={() => {
                        if (this.state.mail_type == "Inbox") {
                          this.props.history.push("/inbox")
                        }
                        if (this.state.mail_type == "Sent") {
                          this.props.history.push("/sentInbox")
                        }
                        if (this.state.mail_type == "Deleted") {
                          this.props.history.push("/deleteInbox")
                        }
                        if (this.state.mail_type == "Starred") {
                          this.props.history.push("/starredInbox")
                        }
                      }}
                    />
                    {this.state.mail_subject}{" "}
                    <Badge color="primary">
                      {this.state.mail_type}
                    </Badge>
                  </h3>
                  <Button
                    color="link"
                    onClick={this.print}
                    className="print"
                  >
                    <img
                      src={PrintIcon}
                      alt="print"
                    />
                  </Button>
                </header>

                <div className="mailBox">
                  {this.state.emails &&
                    this.state.emails.map(
                      (item, index) => {
                        return (
                          <>
                            {/* Mail Header */}
                            <div
                              key={index}
                              className={`mailHeader ${item.content
                                ? "show"
                                : "hide"
                                }`}
                            >
                              <div className="topPart">
                                {
                                  item.sender_id ==
                                    this.state.universityId ? (
                                    <div
                                      className="leftSide"
                                      onClick={() => {
                                        this.showMailFullDetail(
                                          index
                                        );
                                      }}
                                    >
                                      <span className="avatar">
                                        <img
                                          src={
                                            this.props.universityLogo
                                              ? baseUrl + this.props.universityLogo
                                              : AvatarPic
                                          }
                                          alt=""
                                        />
                                      </span>
                                      <strong className="mr-5">
                                        Me
                                      </strong>
                                    </div>
                                  ) : (
                                    <div
                                      className="leftSide"
                                      onClick={() => {
                                        this.showMailFullDetail(
                                          index
                                        );
                                      }}
                                    >
                                      <span className="avatar">
                                        <img
                                          src={
                                            item.avatar ===
                                              "UserPic"
                                              ? UserPic
                                              : AvatarPic
                                          }
                                          alt=""
                                        />
                                      </span>
                                      <strong className="mr-5">
                                        {
                                          item.email_from_details ? (
                                            item.email_from_details.personal_details ? (
                                              item.email_from_details.personal_details.first_name + " " + item.email_from_details.personal_details.last_name
                                            ) : (
                                              item.email_from_details.first_name +
                                              " " +
                                              item.email_from_details.last_name
                                            )
                                          ) : ""
                                        }
                                      </strong>
                                      &lt;
                                      {
                                        item.email_from_details ? (
                                          item.email_from_details.email
                                        ) : ""
                                      }
                                      &gt;
                                    </div>
                                  )
                                }

                                <div className="rightSide">
                                  {
                                    item.attachment_flag === 1 ? (
                                      <img
                                        src={
                                          AttachmentIcon
                                        }
                                        alt="Attachment"
                                        className="AttachmentIcon"
                                      />
                                    ) : null
                                  }
                                  <div className="dateTime">
                                    {this
                                      .state
                                      .todayDate !==
                                      item.created_date &&
                                      item.created_date}
                                    {this
                                      .state
                                      .todayDate ===
                                      item.created_date &&
                                      item.created_date}

                                    {" (" +
                                      moment(
                                        item.created_date
                                      )
                                        .local()
                                        .startOf(
                                          "seconds"
                                        )
                                        .fromNow() +
                                      ")"}
                                  </div>

                                  <img
                                    src={
                                      (
                                        item.star &&
                                        item.star.is_star
                                      ) ? StarredActiveIcon
                                        : StarredIcon
                                    }
                                    alt="Starred"
                                    className="StarredIcon"
                                    onClick={() => this.updateFavourite(item.id)}
                                  />

                                  <Button
                                    className="ThreeDotIcon"
                                    color="link"
                                    id={
                                      "Popover-" +
                                      index
                                    }
                                  >
                                    <img
                                      src={
                                        ThreeDotIcon
                                      }
                                      alt=""
                                    />
                                  </Button>
                                  <UncontrolledPopover
                                    trigger="hover"
                                    placement="bottom"
                                    target={
                                      "Popover-" +
                                      index
                                    }
                                    className="emailExtraOptions"
                                  >
                                    <PopoverBody>
                                      <ul>
                                        <li
                                          onClick={
                                            () => this.updateReadStatus(item.id, 1)
                                          }
                                        >
                                          Mark as unread
                                        </li>
                                        {
                                          item.attachment_flag ? (
                                            <li
                                              onClick={
                                                () => {
                                                  window.open(
                                                    baseUrl +
                                                    DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS +
                                                    `?agent_id=${this.state.universityId}&mail_id=${item.id}`,
                                                    "_blank"
                                                  )
                                                }
                                              }
                                            >
                                              Download all attachment
                                            </li>
                                          ) : null
                                        }
                                        <li
                                          onClick={() => {
                                            this.deleteEmail(item.id)
                                          }}
                                        >
                                          Delete
                                        </li>
                                        {/* <li>Create Meeting</li> */}
                                      </ul>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </div>
                              </div>
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: item.body,
                                }}
                              >
                                {/* Mail Data Comes here */}
                              </div>
                            </div>
                            {/* Mail Body */}
                            <div
                              className={`mailBody ${!item.content ? "show" : "hide"}`}
                            >
                              <div key={index} className={`mailHeader`}
                              >
                                <div className="topPart">
                                  <div className="leftSide"

                                  >
                                    <span className="avatar">
                                      <img
                                        src={item.avatar === "UserPic" ? UserPic : AvatarPic}
                                        alt=""
                                      />
                                    </span>
                                    <div>
                                      {
                                        item.sender_id ==
                                          this.state.universityId ? (
                                          <>
                                            <strong className="mr-5"
                                              onClick={() => {
                                                this.showMailFullDetail(
                                                  index
                                                );
                                              }}
                                            >
                                              Me
                                            </strong>

                                            <div>
                                              <Button
                                                className="MailSummary"
                                                color="link"
                                                id={"MailSummary-" + index}
                                              >
                                                {"To: "}
                                                {
                                                  item.email_to &&
                                                    item.email_to.length ?
                                                    (
                                                      item.email_to.map((val, index) => {
                                                        let nameString = "";
                                                        if (
                                                          val.receiver.personal_details
                                                        ) {
                                                          nameString = val.receiver.personal_details.first_name
                                                            + " " +
                                                            val.receiver.personal_details.last_name
                                                        } else {
                                                          nameString = val.receiver.first_name
                                                            + " " +
                                                            val.receiver.last_name
                                                        }

                                                        if (index > 0) {
                                                          nameString = ", " + nameString;
                                                        }

                                                        return nameString;
                                                      })
                                                    ) : ""
                                                }
                                              </Button>
                                              <UncontrolledPopover
                                                trigger="legacy"
                                                placement="bottom-start"
                                                target={"MailSummary-" + index}
                                                className="MailSummaryData"
                                              >
                                                <PopoverBody>
                                                  <ul>

                                                    <li>
                                                      <span>
                                                        from:
                                                      </span>
                                                      <b>
                                                        {
                                                          item.email_from_details ? (
                                                            item.email_from_details.first_name
                                                            + " " +
                                                            item.email_from_details.last_name
                                                          ) : ""
                                                        }
                                                      </b>
                                                      &lt;{
                                                        item.email_from_details ? (
                                                          item.email_from_details.email
                                                        ) : ""
                                                      }&gt;
                                                    </li>

                                                    <li>
                                                      <span>
                                                        reply-to:
                                                      </span>
                                                      <b>
                                                        {
                                                          item.email_to &&
                                                            item.email_to.length ?
                                                            (
                                                              item.email_to.slice(0, 1).map((val) => {
                                                                let nameString = "";
                                                                if (
                                                                  val.receiver.personal_details
                                                                ) {
                                                                  nameString = val.receiver.personal_details.first_name
                                                                    + " " +
                                                                    val.receiver.personal_details.last_name
                                                                } else {
                                                                  nameString = val.receiver.first_name
                                                                    + " " +
                                                                    val.receiver.last_name
                                                                }

                                                                if (index > 0) {
                                                                  nameString = ", " + nameString;
                                                                }

                                                                return nameString;
                                                              })
                                                            ) : ""
                                                        }
                                                      </b>
                                                      &lt;
                                                      {
                                                        item.email_to &&
                                                          item.email_to.length ?
                                                          (
                                                            item.email_to.slice(0, 1).map((val) => {
                                                              let nameString = val.receiver.email

                                                              if (index > 0) {
                                                                nameString = ", " + nameString;
                                                              }

                                                              return nameString;
                                                            })
                                                          ) : ""
                                                      }
                                                      &gt;
                                                    </li>

                                                    <li>
                                                      <span>
                                                        to:
                                                      </span>
                                                      <b>
                                                        {
                                                          item.email_to &&
                                                            item.email_to.length ?
                                                            (
                                                              item.email_to.map((val) => {
                                                                let nameString = "";
                                                                if (
                                                                  val.receiver.personal_details
                                                                ) {
                                                                  nameString = val.receiver.personal_details.first_name
                                                                    + " " +
                                                                    val.receiver.personal_details.last_name
                                                                } else {
                                                                  nameString = val.receiver.first_name
                                                                    + " " +
                                                                    val.receiver.last_name
                                                                }

                                                                nameString = <>
                                                                  &lt;
                                                                  {
                                                                    val.receiver.email
                                                                  }
                                                                  &gt;
                                                                </>;

                                                                if (index > 0) {
                                                                  nameString = ", " + nameString;
                                                                }

                                                                return nameString;
                                                              })
                                                            ) : ""
                                                        }
                                                      </b>
                                                    </li>

                                                    <li>
                                                      <span>
                                                        date:
                                                      </span>
                                                      {moment(moment.utc(item.created_date)).local().format('DD MMM YY HH:mm A')}
                                                    </li>

                                                    <li>
                                                      <span>
                                                        subject:
                                                      </span>
                                                      {this.state.mail_subject}
                                                    </li>
                                                  </ul>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <strong className="mr-5"
                                              onClick={() => {
                                                this.showMailFullDetail(
                                                  index
                                                );
                                              }}
                                            >
                                              {
                                                item.email_from_details ? (
                                                  item.email_from_details.personal_details ? (
                                                    item.email_from_details.personal_details.first_name + " " + item.email_from_details.personal_details.last_name
                                                  ) : (
                                                    item.email_from_details.first_name +
                                                    " " +
                                                    item.email_from_details.last_name
                                                  )
                                                ) : ""
                                              }
                                            </strong>
                                            &lt;
                                            {
                                              item.email_from_details ? (
                                                item.email_from_details.email
                                              ) : ""
                                            }
                                            &gt;

                                            <div>
                                              <Button
                                                className="MailSummary"
                                                color="link"
                                                id={"MailSummary-" + index}
                                              >
                                                to me
                                              </Button>
                                              <UncontrolledPopover
                                                trigger="legacy"
                                                placement="bottom-start"
                                                target={"MailSummary-" + index}
                                                className="MailSummaryData"
                                              >
                                                <PopoverBody>
                                                  <ul>

                                                    <li>
                                                      <span>
                                                        from:
                                                      </span>
                                                      <b>
                                                        {
                                                          item.email_from_details ? (
                                                            item.email_from_details.personal_details ? (
                                                              item.email_from_details.personal_details.first_name + " " + item.email_from_details.personal_details.last_name
                                                            ) : (
                                                              item.email_from_details.first_name +
                                                              " " +
                                                              item.email_from_details.last_name
                                                            )
                                                          ) : ""
                                                        }
                                                      </b>
                                                      &lt;
                                                      {
                                                        item.email_from_details ? (
                                                          item.email_from_details.email
                                                        ) : ""
                                                      }
                                                      &gt;
                                                    </li>

                                                    <li>
                                                      <span>
                                                        reply-to:
                                                      </span>
                                                      <b>
                                                        {
                                                          item.email_to &&
                                                            item.email_to.length ?
                                                            (
                                                              item.email_to.map((val, index) => {
                                                                let nameString = "";
                                                                if (
                                                                  val.receiver.personal_details
                                                                ) {
                                                                  nameString = val.receiver.personal_details.first_name
                                                                    + " " +
                                                                    val.receiver.personal_details.last_name
                                                                } else {
                                                                  nameString = val.receiver.first_name
                                                                    + " " +
                                                                    val.receiver.last_name
                                                                }

                                                                nameString += '<' + val.receiver.email + '>'

                                                                if (index > 0) {
                                                                  nameString = ", " + nameString;
                                                                }

                                                                return nameString;
                                                              })
                                                            ) : ""
                                                        }
                                                      </b>
                                                    </li>

                                                    <li>
                                                      <span>
                                                        to:
                                                      </span>
                                                      <b>
                                                        {
                                                          item.email_to &&
                                                            item.email_to.length ?
                                                            (
                                                              item.email_to.map((val, index) => {
                                                                let nameString = "";
                                                                if (
                                                                  val.receiver.personal_details
                                                                ) {
                                                                  nameString = val.receiver.personal_details.first_name
                                                                    + " " +
                                                                    val.receiver.personal_details.last_name
                                                                } else {
                                                                  nameString = val.receiver.first_name
                                                                    + " " +
                                                                    val.receiver.last_name
                                                                }

                                                                nameString += '<' + val.receiver.email + '>';

                                                                if (index > 0) {
                                                                  nameString = ", " + nameString;
                                                                }

                                                                return nameString;
                                                              })
                                                            ) : ""
                                                        }
                                                      </b>
                                                    </li>

                                                    <li>
                                                      <span>
                                                        date:
                                                      </span>
                                                      {moment(moment.utc(item.created_date)).local().format('DD MMM YY HH:mm A')}
                                                    </li>

                                                    <li>
                                                      <span>
                                                        subject:
                                                      </span>
                                                      {this.state.mail_subject}
                                                    </li>

                                                  </ul>
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </div>
                                          </>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className="rightSide">
                                    {item.attachment_flag ? (
                                      <img
                                        src={
                                          AttachmentIcon
                                        }
                                        alt="Attachment"
                                        className="AttachmentIcon"
                                      />
                                    ) : null
                                    }

                                    <img
                                      src={ReplyIcon}
                                      alt="Reply"
                                      className="ReplyIcon mr-10 ml-5"
                                      onClick={() => {
                                        this.setState({
                                          showReply: true,
                                          parentFlag: 0
                                        });
                                        this.setReplyState(item)
                                      }}
                                    />

                                    <img
                                      src={
                                        (
                                          item.star &&
                                          item.star.is_star
                                        ) ? StarredActiveIcon
                                          : StarredIcon
                                      }
                                      alt="Starred"
                                      className="StarredIcon"
                                      onClick={() => this.updateFavourite(item.id)}
                                    />

                                    <Button
                                      className="ThreeDotIcon"
                                      color="link"
                                      id={"ThreeDot-" + index}
                                    >
                                      <img
                                        src={ThreeDotIcon}
                                        alt=""
                                      />
                                    </Button>
                                    <UncontrolledPopover
                                      trigger="hover"
                                      placement="bottom"
                                      target={"ThreeDot-" + index}
                                      className="emailExtraOptions"
                                    >
                                      <PopoverBody>
                                        <ul>
                                          <li
                                            onClick={
                                              () => this.updateReadStatus(item.id, 1)
                                            }
                                          >
                                            Mark as unread
                                          </li>
                                          {
                                            item.attachment_flag ? (
                                              <li
                                                onClick={
                                                  () => {
                                                    window.open(
                                                      baseUrl +
                                                      DOWNLOAD_ALL_INBOX_MAIL_ATTACHMENTS +
                                                      `?agent_id=${this.state.universityId}&mail_id=${item.id}`,
                                                      "_blank"
                                                    )
                                                  }
                                                }
                                              >
                                                Download all attachment
                                              </li>
                                            ) : null
                                          }
                                          <li
                                            onClick={() => {
                                              this.deleteEmail(item.id)
                                            }}
                                          >
                                            Delete
                                          </li>
                                          {/* <li>Create Meeting</li> */}
                                        </ul>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </div>
                                </div>
                                <div className="content">
                                  <div className="dateTime">
                                    {/* {this
																						.state
																						.todayDate !==
																						item.created_date ?
																						item.created_date : ""
																					}
																					{this
																						.state
																						.todayDate ===
																						item.created_date ?
																						item.created_date : ""
																					} */}
                                    {
                                      moment(moment.utc(item.created_date)).local().format("DD MMM YY HH:mm A")
                                    }

                                    {" (" +
                                      moment(moment.utc(item.created_date))
                                        .local()
                                        .startOf(
                                          "seconds"
                                        )
                                        .fromNow() +
                                      ")"}
                                  </div>
                                </div>
                              </div>

                              {
                                item.request_mail_type ? (
                                  <div className="mailContent">
                                    {
                                      EmailTemplate.call(this, item)
                                    }
                                  </div>
                                ) : (
                                  <div className="mailContent"
                                    dangerouslySetInnerHTML={{ __html: item.body, }}
                                  >
                                    {/* Mail Data Comes here */}
                                  </div>
                                )
                              }


                              {
                                item.attachment_flag ? (
                                  <div className="attachement">
                                    {
                                      item.attachments.length ? (
                                        <>
                                          <div className="detail">
                                            <HasAttachmentIcon
                                              className="HasAttachmentIcon"
                                            />
                                            {item.attachments.length}{" "}
                                            Attachment
                                          </div>
                                        </>
                                      ) : null
                                    }
                                    {
                                      item.attachments.length ? (
                                        <div className="fileList">
                                          {item.attachments.map(
                                            (file, index) => (
                                              <div
                                                className="box"
                                                key={index}
                                              >
                                                <div className="fileName">
                                                  <u>
                                                    {file.original_filename}
                                                  </u>
                                                  <div className="fileSize">

                                                  </div>
                                                </div>

                                                <img
                                                  src={
                                                    DownloadAttachemnet
                                                  }
                                                  alt="Download"
                                                  className="DownloadAttachemnet"
                                                  onClick={() => {
                                                    window.open(baseurl + file.attachment_path, "_blank")
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : null
                                    }
                                  </div>
                                ) : null
                              }
                            </div>
                          </>
                        );
                      }
                    )}
                  {/* Reply */}
                  {ReplyMessage.call(this)}
                </div>


                <footer>
                  <Button color="secondary" outline
                    onClick={() => {
                      this.setState({
                        showReply: true,
                        parentFlag: 0
                      });
                      this.setReplyState(false);
                    }}
                  >
                    <ReplySVGIcon width="14px" height="10px" /> Reply
                  </Button>
                  <Button color="secondary" outline
                    onClick={() => {
                      this.setState({
                        showReply: true,
                        parentFlag: 0
                      });
                      this.setReplyState(true);
                    }}
                  >
                    <ReplyAllIcon width="14px" height="10px" /> Reply All
                  </Button>
                  <Button color="secondary" outline
                    onClick={() => {
                      this.setState({
                        showReply: true,
                        parentFlag: 1
                      })
                      this.setForwardState()
                    }}
                  >
                    <ForwardIcon width="14px" height="10px" /> Forward
                  </Button>
                </footer>
              </div>
            </div>
          </div>
          {/* <Container>
            <Row className="row-eq-height">
              <Col xs="12" md="2" className="LeftInboxMenu">
              </Col>
              <Col
                xs="12"
                md="10"
                className="p-0 viewMessageSection"
              >
                <Row>
                  <Col xs="12">

                  </Col>
                </Row>
              </Col>
            </Row>
          </Container> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profileReducer.profile,
    universityLogo: state.profileReducer.universityLogo
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInboxMailDetailsAction,
      updateInboxReadStatusAction,
      deleteEmailAction,
      composeMailAction,
      updateFavouriteAction,
      getProfileAction,
      getMeAction,
      updateViewerRequestAction,
      updateHireRequestAction,
      getStaffListAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewMessage);
