import { put, takeLatest, call } from 'redux-saga/effects';
import {
	CALENDER_EVENT_LIST,
	GET_MONTH_EVENT_LIST
  } from "../constants";
import {
	baseUrl,
	CALENDER_EVENT_LIST_API,
	GET_MONTH_EVENT_LIST_API
} from "../../Api";
// import axios from "axios";
import axios from "../../Api/axios"
import {
	calendarEventListSuccess,
	calendarEventListError,
	getMonthEventListSuccess,
	getMonthEventListError
} from '../actions/calender';
import { handleErrorResponse, handleSuccessResponse, showHideLoadingAction } from '../actions';

function calendarEventListActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + CALENDER_EVENT_LIST_API,
	  params: params
	});
}

function* calendarEventListAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(calendarEventListActionApi, payload);
		yield put(calendarEventListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false));
    handleErrorResponse(e.response);
		yield put(calendarEventListError(e));
		if (reject) reject(e)
	}
}

export function* calendarEventListActionWatcher() {
	yield takeLatest(CALENDER_EVENT_LIST, calendarEventListAction)
}

function getMonthEventListActionApi(params) {
	return axios.request({
	  method: "get",
	  url: baseUrl + GET_MONTH_EVENT_LIST_API,
	  params: params
	});
}

function* getMonthEventListAction(action) {
	let {
		payload,
		resolve,
		reject
	} = action;
	try {
    yield put(showHideLoadingAction(true));
		let response = yield call(getMonthEventListActionApi, payload);
		yield put(getMonthEventListSuccess(response.data));
    yield put(showHideLoadingAction(false));
    handleSuccessResponse(response.data);
		if (resolve) resolve(response.data)
	} catch (e) {
    yield put(showHideLoadingAction(false))
    handleErrorResponse(e.response);
		yield put(getMonthEventListError(e))
		if (reject) reject(e)
	}
}

export function* getMonthEventListActionWatcher() {
	yield takeLatest(GET_MONTH_EVENT_LIST, getMonthEventListAction)
}
