import {
  combineReducers
} from "redux";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import generalReducer from "./general";
import profileReducer from "./profile";
import staffReducer from "./staff";
import applicationReducer from "./application";
import departmentReducer from "./department";
import inboxReducer from "./inbox";

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  generalReducer,
  profileReducer,
  staffReducer,
  applicationReducer,
  departmentReducer,
  inboxReducer
});

export default rootReducer;